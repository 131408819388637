import { blue, green, red } from '@mui/material/colors';
import CheckFilled from '~/assets/images/CheckFilled.svg';
import pending from '~/assets/images/pending.svg';
import CloseFilled from '~/assets/images/CloseFilled.svg';
import { Box, Typography } from '@mui/material';
import { ACCOUNT_STATUS } from '~/config/constants';

export const ShowStatus = ({ status }: { status: string }) => (
  <Box
    className='status'
    borderRadius='4px'
    display='flex'
    alignItems='center'
    justifyContent='center'
    width='113px'
    fontWeight={600}
    fontSize='14px'
    height='40px'
    padding={'8px'}
    bgcolor={
      {
        APPROVED: green[100],
        PENDING: blue[100],
        REJECTED: red[100],
      }[status]
    }
    color={
      {
        APPROVED: green[700],
        PENDING: blue[900],
        REJECTED: red[700],
      }[status]
    }
  >
    <img
      src={
        status === ACCOUNT_STATUS.APPROVED
          ? CheckFilled
          : status === ACCOUNT_STATUS.PENDING
          ? pending
          : status === ACCOUNT_STATUS.REJECTED
          ? CloseFilled
          : ''
      }
      alt={status}
      style={{ height: '20px', marginRight: '5px' }}
    />
    <Typography variant='subtitle2'>{status}</Typography>
  </Box>
);
