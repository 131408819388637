import APPLICATION_URLS from '~/shell/Routing/config/appsConfig';
import AppBaseLayout, {
  AppBaseLayoutProps,
} from '~/shell/components/Layout/components/AppLayout';
import CreditActionButton from './components/CreditAction';
import CreditHistory from './components/CreditHistory';

export const CREDIT_STATUS = {
  CREDIT_IN: 'CREDIT_IN',
  CREDIT_OUT: 'CREDIT_OUT',
};

const SUB_ROUTES_FOR_CREDIT_MAP: {
  path: string;
  element: typeof AppBaseLayout;
  AppBaseLayoutProps: AppBaseLayoutProps;
}[] = [
  {
    path: APPLICATION_URLS.CREDIT_IN,
    element: AppBaseLayout,
    AppBaseLayoutProps: {
      headerProps: {
        title: 'Credit In',
        actionButtons: (
          <CreditActionButton credit_type={CREDIT_STATUS.CREDIT_IN} />
        ),
      },
      contentProps: {
        content: <CreditHistory />,
      },
    },
  },
  {
    path: APPLICATION_URLS.CREDIT_OUT,
    element: AppBaseLayout,
    AppBaseLayoutProps: {
      headerProps: {
        title: 'Credit Out',
        actionButtons: (
          <CreditActionButton credit_type={CREDIT_STATUS.CREDIT_OUT} />
        ),
      },
      contentProps: {
        content: <CreditHistory />,
      },
    },
  },
];
export { SUB_ROUTES_FOR_CREDIT_MAP };
