import React from 'react';
import { Box, Grid, Button, TextField } from '@mui/material';
import { ShowStatus } from '~/components/Status/ShowStatus';
import viewDoc from '~/assets/images/viewDoc.svg';
import { FormattedMessage } from '~app/Integrations/Localization';
import messages from '../messages';
import ViewKycDocModal from './ViewKycDoc';
import useToggle from '~app/hooks/useToggle';

// eslint-disable-next-line arrow-body-style
const KycForm = ({ data, onApprovRejectRecord }: any) => {
  const { setValue, value } = useToggle();
  return (
    <Box mt='32px'>
      <Grid
        rowSpacing={4}
        container
        display={'flex'}
        justifyContent={'space-between'}
      >
        {data?.data.map((document: any) => (
          <Grid key={document.id} item display='flex' alignItems='center'>
            <TextField
              label={`${document.document_name} (${document.document_side})`}
              variant='outlined'
              size='small'
              disabled
              value={document.document_name + document.latest_version.extension}
              sx={{
                width: '300px',
              }}
            />
            <Button
              variant='outlined'
              sx={{
                textTransform: 'none',
                color: '#768098',
                fontWeight: 'bold',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: '8px',
                height: '40px',
                mx: 2,
              }}
              onClick={() => setValue(document)}
            >
              <img src={viewDoc} alt='viewDoc' />
              <FormattedMessage {...messages.view} />
            </Button>

            {document?.latest_version.status.status_name === 'Pending' ? (
              <Button
                onClick={() => onApprovRejectRecord(document)}
                sx={{ height: '40px', width: '113px' }}
                variant='contained'
                disabled={false}
              >
                <FormattedMessage {...messages.actions} />
              </Button>
            ) : (
              <ShowStatus
                status={document?.latest_version.status.status_name.toUpperCase()}
              />
            )}
          </Grid>
        ))}
      </Grid>
      <ViewKycDocModal setValue={setValue} value={value} />
    </Box>
  );
};

export default KycForm;
