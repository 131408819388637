import Button from 'v2s-ui-core/app/components/Button';
import Box from 'v2s-ui-core/app/components/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { IconButton, Tooltip } from '@mui/material';
import { Data, Status } from '~/modules/Dashboard/types';
import { statusIcons, statusColors } from '~/modules/Dashboard/constants';
import { useDashboardData } from '~/modules/Dashboard/hooks/useDashboardData';

const Dashboard = () => {
  const { data, isLoading } = useDashboardData();

  if (isLoading) return <p>Loading...</p>;

  const datakey = data?.data
    ? (Object.keys(data.data) as Array<keyof Data>)
    : undefined;

  return (
    <Grid container spacing={3}>
      {datakey?.map((transactionType) => (
        <Grid item xs={12} md={6} key={transactionType}>
          <Box
            minHeight={289}
            display={'flex'}
            flexDirection={'column'}
            justifyContent={'center'}
            alignItems={'center'}
            gap={'31px'}
            boxShadow={'0px 4px 16px 0px rgba(0, 0, 0, 0.08)'}
            marginBottom={'24px'}
            sx={{ backgroundColor: '#FFFFFF', border: '1px solid #C8E6C9' }}
          >
            <Box
              display={'flex'}
              flexDirection={'row'}
              justifyContent={'space-between'}
              alignItems={'center'}
              width={'100%'}
              paddingX={'36px'}
            >
              <Typography fontSize={24} sx={{ color: '#020A13' }}>
                Summary for{' '}
                {transactionType.charAt(0).toUpperCase() +
                  transactionType.slice(1)}
              </Typography>
              <Button variant='text' sx={{ color: '#08345D' }}>
                View All
              </Button>
            </Box>

            <Grid container justifyContent='center' spacing={2}>
              {Object.keys(data?.data[transactionType])
                .filter(
                  (status) => status !== 'CANCELLED' && status !== 'PROCESSING',
                )
                .map((status) => {
                  const statusKey = status as Status;

                  return (
                    <Grid item key={statusKey}>
                      <Box
                        width={170}
                        height={157}
                        border={'1px solid #C8E6C9'}
                        borderRadius={
                          statusKey === 'REJECTED'
                            ? '0 10px 10px 0'
                            : statusKey === 'PENDING'
                            ? '10px 0 0 10px'
                            : '0'
                        }
                        p={2}
                        position='relative'
                        display='flex'
                        flexDirection='column'
                        justifyContent='center'
                        alignItems='center'
                      >
                        <Tooltip
                          title={statusKey}
                          disableFocusListener
                          disableTouchListener
                        >
                          <IconButton
                            sx={{ position: 'absolute', top: 0, right: 0 }}
                          >
                            <img src={statusIcons[statusKey]} alt={statusKey} />
                          </IconButton>
                        </Tooltip>
                        <Typography
                          fontSize={39}
                          sx={{
                            color: statusColors[statusKey],
                            textAlign: 'center',
                          }}
                        >
                          {data?.data[transactionType][statusKey]}
                        </Typography>
                        <Typography
                          fontSize={16}
                          sx={{
                            color: statusColors[statusKey],
                            textAlign: 'center',
                            marginTop: '16px',
                          }}
                        >
                          {statusKey.charAt(0).toUpperCase() +
                            statusKey.slice(1).toLowerCase()}
                        </Typography>
                      </Box>
                    </Grid>
                  );
                })}
            </Grid>
          </Box>
        </Grid>
      ))}
    </Grid>
  );
};

export default Dashboard;
