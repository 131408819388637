import { defineMessages } from '~app/Integrations/Localization';

export default defineMessages({
  deposit: {
    id: 'transactions.deposit',
    defaultMessage: 'Deposit',
  },
  withdrawal: {
    id: 'transactions.withdrawal',
    defaultMessage: 'Withdrawal',
  },
  transfer: {
    id: 'transactions.transfer',
    defaultMessage: 'Transfer',
  },
});
