import { ColumnDef } from 'v2s-ui-core/app/components/DataGridCore';
import { TransactionHistory } from '../types';
import { formatDate } from '~/utils/FormateDate';

export const WalletHistoryColumn = (): ColumnDef<TransactionHistory>[] => [
  {
    accessorKey: 'id',
    header: 'Transaction ID',
    enableColumnFilter: true,
  },
  {
    accessorKey: 'user_transaction.payment_method.transaction_type',
    header: 'Transaction Type',
    enableColumnFilter: true,
  },
  {
    accessorKey: 'user_transaction.account_type',
    header: 'Account Type',
    enableColumnFilter: true,
  },
  {
    accessorKey: 'user_transaction.trading_currency',
    header: 'Trading Currency',
    enableColumnFilter: true,
  },
  {
    accessorKey: 'user_transaction.trading_amount',
    header: 'Trading Amount',
    enableColumnFilter: true,
  },
  {
    accessorKey: 'user_transaction.local_currency',
    header: 'Local Currency',
    enableColumnFilter: true,
  },
  {
    accessorKey: 'user_transaction.local_amount',
    header: 'Local Amount',
    enableColumnFilter: true,
  },
  {
    accessorKey: 'balance',
    header: 'Balance After Transaction',
    enableColumnFilter: true,
  },
  {
    accessorKey: 'user_transaction.status',
    header: 'Transaction Status',
    enableColumnFilter: true,
  },
  {
    accessorKey: 'user_transaction.requested_date',
    header: 'Requested Date',
    enableColumnFilter: true,
  },
  {
    accessorKey: 'user_transaction.payment_method.name',
    header: 'Payment Method',
    enableColumnFilter: true,
  },
  {
    accessorKey: 'user_transaction.payment_reference',
    header: 'Payment Reference',
    enableColumnFilter: true,
  },
  {
    header: 'Created At',
    enableColumnFilter: true,
    accessorKey: 'user_transaction.created_at',
  },

  {
    header: 'Updated At',
    enableColumnFilter: true,
    accessorKey: 'user_transaction.payment_method.updated_at',
  },
  {
    header: 'Created By',
    enableColumnFilter: true,
    accessorKey: 'user_transaction.created_by.email',
  },
  {
    header: 'Updated By',
    enableColumnFilter: true,
    accessorKey: 'user_transaction.updated_by.email',
  },
];
