import messages from './messages';

// status from api
export const WITHDRAWALS_STATUS = {
  PENDING: 'PENDING',
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED',
};

export const WITHDRAWALS_STATUS_CONFIG = {
  [WITHDRAWALS_STATUS.PENDING]: {
    label: messages.pending,
    color: 'secondary',
  },
  [WITHDRAWALS_STATUS.APPROVED]: {
    label: messages.approved,
    color: 'success',
  },
  [WITHDRAWALS_STATUS.REJECTED]: { label: messages.rejected, color: 'error' },
};

// export const WITHDRAWAL_TRASCATION_TYPE = {
//   DEPOSIT: 'DEPOSIT',
// };
