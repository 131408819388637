import React from 'react';
import { Box, Tabs, Tab } from '@mui/material';
import { TRANSACTION_TABS } from './constants';
import { FormattedMessage } from '~app/Integrations/Localization';

const TransactionTabs = () => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box>
      <Tabs
        value={value}
        onChange={handleChange}
        sx={{
          borderBottom: 1,
          borderColor: 'divider',
          marginX: '20px',
        }}
      >
        {TRANSACTION_TABS.map((tab, index) => (
          <Tab key={index} label={<FormattedMessage {...tab.label} />} />
        ))}
      </Tabs>
      <Box sx={{ padding: 2 }}>{TRANSACTION_TABS[value]?.component}</Box>
    </Box>
  );
};

export default TransactionTabs;
