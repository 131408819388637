import React from 'react';
import { Box, Button } from '@mui/material';
import SkeletonWrapper from './SkeletonWrapper';
import { FormattedMessage } from '~app/Integrations/Localization';
import messages from '../messages';
const ApproveRejectBtn = ({
  account,
  onApproveRecord,
  onRejectRecord,
  isLoading,
}: {
  account: any;
  onApproveRecord?: Function;
  onRejectRecord?: Function;
  isLoading: boolean;
}) => (
  <Box mt={'32px'}>
    {account?.status !== 'APPROVED' && account?.status !== 'REJECTED' && (
      <SkeletonWrapper isLoading={isLoading}>
        <Button
          variant='contained'
          sx={{
            marginRight: '10px',
          }}
          onClick={() => onApproveRecord?.(account)}
        >
          <FormattedMessage {...messages.approve} />
        </Button>
        <Button
          onClick={() => onRejectRecord?.(account)}
          color={'secondary'}
          variant='outlined'
        >
          <FormattedMessage {...messages.reject} />
        </Button>
      </SkeletonWrapper>
    )}
  </Box>
);

export default ApproveRejectBtn;
