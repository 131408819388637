import { get, post } from 'v2s-ui-core/app/Integrations/ApiService';
import { API_URLS } from './config';

const ApiService = {
  changePassword: async <T>(payload: any) => {
    try {
      const response = await post<T>(API_URLS.CHANGE_PASSWORD, payload);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
};
export default ApiService;
