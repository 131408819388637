import { useFormik } from 'formik';
import { useLocaleContext } from 'v2s-ui-core/app/Integrations/Localization';
import { getApproveRejectSchema } from '../services/mutations/constants';
import { ApproveRejectRequestsProps } from '../types';

export const useApproveRejectRequests = ({
  onConfirmApproveReject,
  recordForApproveReject,
}: {
  onConfirmApproveReject: ApproveRejectRequestsProps['onConfirmApproveReject'];
  recordForApproveReject: ApproveRejectRequestsProps['recordForApproveReject'];
}) => {
  const {
    messageFormatter: { formatMessage },
  } = useLocaleContext();

  const formik = useFormik({
    initialValues: {
      remark: '',
    },

    onSubmit: (values, { resetForm }) => {
      onConfirmApproveReject({
        remark: values.remark,
        status:
          recordForApproveReject.action === 'APPROVED'
            ? 'APPROVED'
            : 'REJECTED',
        id: recordForApproveReject?.record?.id,
      });
      resetForm({ values: { remark: '' } });
    },

    validationSchema: getApproveRejectSchema(formatMessage),
    enableReinitialize: true,
    validateOnBlur: false,
    validateOnChange: false,
    validateOnMount: false,
  });

  return formik;
};
