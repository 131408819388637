import { defineMessages } from 'v2s-ui-core/app/Integrations/Localization';

export default defineMessages({
  twoFAOff: {
    id: 'userMenu.twoFAOff',
    defaultMessage: '2FA is turned off',
  },
  myProfile: {
    id: 'userMenu.myProfile',
    defaultMessage: 'My Profile',
  },
  changePassword: {
    id: 'userMenu.changePassword',
    defaultMessage: 'Change Password',
  },
  logout: {
    id: 'userMenu.logout',
    defaultMessage: 'Logout',
  },
});
