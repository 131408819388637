import BankAccounts from '~/assets/images/Bank Accounts.svg';
import InternalTransfersIcon from '~/assets/images/ChangeLeverage.svg';
import ClientsInOutIcon from '~/assets/images/ClientsInOut.svg';
import CRMUserManagement from '~/assets/images/CRMUserManagement.svg';
import DepositsIcon from '~/assets/images/Deposits.svg';
import EmailingIcon from '~/assets/images/Emailing.svg';
import IBManagementIcon from '~/assets/images/IBManagement.svg';
import NotificationsIcon from '~/assets/images/Notifications.svg';
import ReportsIcon from '~/assets/images/Reports.svg';
import RequestsIcon from '~/assets/images/Requests.svg';
import TradingSettingsIcon from '~/assets/images/TradingSettings.svg';
import WithdrawalIcon from '~/assets/images/Withdrawal.svg';
import UserManagement from '~/assets/images/UserManagement.svg';
import RolesAndPermissions from '~/assets/images/RolesAndPermissions.svg';
import ResetPasswordIcon from '~/assets/images/ResetPassword.svg';
import NewTradingAccountIcon from '~/assets/images/NewTradingAccount.svg';
import Traders from '~/assets/images/Traders.svg';
import APPLICATION_URLS from '~/shell/Routing/config/appsConfig';
import Dashboard from '~/assets/images/Dashboard.svg';
import { SIDEBAR_ITEM } from './types';
import { FormattedMessage } from 'v2s-ui-core/app/Integrations/Localization';
import messages from './messages';

export const DRAWER_WIDTH = 240;

export const SIDEBAR_MENUS: SIDEBAR_ITEM[] = [
  {
    menuName: <FormattedMessage {...messages.dashboard} />,
    altText: 'Dashboard',
    icon: Dashboard,
    id: 'traders',
    path: APPLICATION_URLS.DASHBOARD,
  },
  {
    menuName: <FormattedMessage {...messages.traders} />,
    altText: 'Traders',
    icon: Traders,
    id: 'traders',
    path: APPLICATION_URLS.TRADERS,
  },
  {
    menuName: <FormattedMessage {...messages.bankAccounts} />,
    altText: 'Bank Accounts',
    icon: BankAccounts,
    id: 'bankAccounts',
    path: APPLICATION_URLS.BANK_ACCOUNTS,
  },

  {
    menuName: <FormattedMessage {...messages.deposits} />,
    altText: 'Deposits',
    icon: DepositsIcon,
    id: 'traders',
    path: APPLICATION_URLS.DEPOSITS,
  },
  {
    menuName: <FormattedMessage {...messages.withdrawal} />,
    altText: 'Withdrawal',
    icon: WithdrawalIcon,
    id: 'Withdrawal',
    path: APPLICATION_URLS.WITHDRAWAL,
  },
  {
    menuName: <FormattedMessage {...messages.internalTransfers} />,
    altText: 'Internal Transfers',
    icon: InternalTransfersIcon,
    id: 'Internal Transfers',
    path: APPLICATION_URLS.INTERNAL_TRANSFERS,
  },
  {
    menuName: <FormattedMessage {...messages.clientInOut} />,
    altText: 'Client In/Out',
    icon: ClientsInOutIcon,
    id: 'Client In/Out',
    path: APPLICATION_URLS.CLIENT_IN_OUT,
  },
  {
    menuName: <FormattedMessage {...messages.reports} />,
    altText: 'Reports',
    icon: ReportsIcon,
    id: 'Reports',
    path: APPLICATION_URLS.REPORTS,
  },
  {
    menuName: <FormattedMessage {...messages.requests} />,
    altText: 'Requests',
    icon: RequestsIcon,
    id: 'Requests',
    path: APPLICATION_URLS.REQUESTS,
    submenus: [
      {
        icon: NewTradingAccountIcon,
        id: 'New Trading Account',
        menuName: 'New Trading Account',
        altText: 'New TradingAccount',
        path: APPLICATION_URLS.NEW_TRADING_ACCOUNT,
      },
      {
        icon: InternalTransfersIcon,
        id: 'Leverage Change',
        menuName: 'Leverage Change',
        altText: 'Leverage Change',
        path: APPLICATION_URLS.LEVERAGE_CHANGE,
      },
      {
        icon: ResetPasswordIcon,
        id: 'passwordChange',
        menuName: 'Password Change',
        altText: 'Password Change',
        path: APPLICATION_URLS.PASSWORD_CHANGE,
      },
    ],
  },
  {
    menuName: <FormattedMessage {...messages.emailing} />,
    altText: 'Emailing',
    icon: EmailingIcon,
    id: 'Emailing',
    path: APPLICATION_URLS.EMAILING,
  },
  {
    menuName: <FormattedMessage {...messages.notifications} />,
    altText: 'Notifications',
    icon: NotificationsIcon,
    id: 'Notifications',
    path: APPLICATION_URLS.NOTIFICATIONS,
  },
  {
    menuName: <FormattedMessage {...messages.tradingSettings} />,
    altText: 'Trading Settings',
    icon: TradingSettingsIcon,
    id: 'tradingSettings',
    path: APPLICATION_URLS.TRADING_SETTINGS,
  },
  {
    menuName: <FormattedMessage {...messages.ibManagement} />,
    altText: 'IB Management',
    icon: IBManagementIcon,
    id: 'ibManagement',
    path: APPLICATION_URLS.IB_MANAGEMENT,
  },
  {
    menuName: <FormattedMessage {...messages.crmUserManagement} />,
    altText: 'CRM User Management',
    icon: CRMUserManagement,
    id: 'crmUserManagement',
    submenus: [
      {
        icon: UserManagement,
        id: 'userManagement',
        menuName: <FormattedMessage {...messages.userManagement} />,
        altText: 'User Management',
        path: APPLICATION_URLS.USER_MANAGEMENT,
      },
      {
        icon: RolesAndPermissions,
        id: 'rolesAndPermissions',
        menuName: <FormattedMessage {...messages.rolesAndPermissions} />,
        altText: 'Roles & Permissions',
        path: APPLICATION_URLS.ROLES_AND_PERMISSIONS,
      },
    ],
  },
];
