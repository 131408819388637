import AsyncRenderer from 'v2s-ui-core/app/components/AsyncRenderer';
import { Navigate, Route, Routes } from 'react-router-dom';

import { PublicLayout } from '../../components/Layout';
import APPLICATION_URLS from '../config/appsConfig';
import { AUTH_ROUTES, DEFAULT_ROUTES } from '../config/routesConfig';

const DefaultRoutes = () => (
  <PublicLayout>
    <AsyncRenderer fallback={<span>Loading ...</span>}>
      <Routes>
        {DEFAULT_ROUTES.concat(AUTH_ROUTES).map((_route) => (
          <Route
            path={_route.path}
            element={_route.element}
            key={_route.path}
            index={_route.index}
          />
        ))}
        <Route
          path='*'
          element={<Navigate to={APPLICATION_URLS.LANDING_PAGE} />}
        />
      </Routes>
    </AsyncRenderer>
  </PublicLayout>
);

export default DefaultRoutes;
