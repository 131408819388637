import { useState } from "react";

export const useLayout = () => {
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);

    return {
        isSidebarOpen,
        setIsSidebarOpen
    }
};
