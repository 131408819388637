import CardContent from '@mui/material/CardContent';
import Box from 'v2s-ui-core/app/components/Box';
import ConfirmationDialog from '../../components/ConfirmationDialog';
import UserManagementTable from '../../components/UserManagementTable';

const UserManagements = () => (
  <Box>
    <CardContent>
      <UserManagementTable />
    </CardContent>
    <ConfirmationDialog />
  </Box>
);

export default UserManagements;
