import messages from './messages';

// status from api
export const INTERNAL_TRANSFERS_STATUS = {
  PENDING: 'Pending',
  APPROVED: 'Approved',
  REJECTED: 'Rejected',
};

export const INTERNAL_TRANSFERS_STATUS_CONFIG = {
  [INTERNAL_TRANSFERS_STATUS.PENDING]: {
    label: messages.pending,
    color: 'secondary',
  },
  [INTERNAL_TRANSFERS_STATUS.APPROVED]: {
    label: messages.approved,
    color: 'success',
  },
  [INTERNAL_TRANSFERS_STATUS.REJECTED]: {
    label: messages.rejected,
    color: 'error',
  },
};
