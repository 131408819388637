import BankAccountsTable from './components/BankAccountsTable';
import AppBaseLayout from '~/shell/components/Layout/components/AppLayout';

const BankAccounts = () => (
  <AppBaseLayout
    contentProps={{
      content: <BankAccountsTable />,
    }}
    headerProps={{
      title: 'Bank Accounts',
      actionButtons: <></>,
    }}
  />
);

export default BankAccounts;
