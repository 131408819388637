import messages from './messages';

// status from api
export const KYC_STATUS = {
  PENDING: 'Pending',
  APPROVED: 'approve',
  REJECTED: 'reject',
};

export const KYC_STATUS_MESSAGE = {
  PENDING: 'PENDING',
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED',
};

export const KYC_STATUS_CONFIG = {
  [KYC_STATUS_MESSAGE.PENDING]: {
    label: messages.pending,
    color: 'secondary',
  },
  [KYC_STATUS_MESSAGE.APPROVED]: {
    label: messages.approved,
    color: 'success',
  },
  [KYC_STATUS_MESSAGE.REJECTED]: { label: messages.rejected, color: 'error' },
};
