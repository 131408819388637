import AppBaseLayout from '~/shell/components/Layout/components/AppLayout';
import TradersTable from './components/TradersTable';
import Button from 'v2s-ui-core/app/components/Button';
const Traders = () => (
  <AppBaseLayout
    contentProps={{
      content: <TradersTable />,
    }}
    headerProps={{
      title: 'All Traders',
      actionButtons: (
        <>
          <Button size='large' variant='contained' type='submit'>
            Assign
          </Button>
          <Button
            size='large'
            variant='outlined'
            type='submit'
            sx={{
              background: 'white',
            }}
          >
            Unassign
          </Button>
        </>
      ),
    }}
  />
);

export default Traders;
