import WithdrawalTable from './components/WithdrawalTable';
import AppBaseLayout from '~/shell/components/Layout/components/AppLayout';
import { QUERY_KEYS } from './services/queries/constants';

const Withdrawal = () => (
  <AppBaseLayout
    contentProps={{
      content: <WithdrawalTable />,
    }}
    headerProps={{
      title: 'Withdrawals',
      actionButtons: <></>,
    }}
    QUERY_KEYS={QUERY_KEYS.FETCH_WITHDRAWALS}
    ShowRefresh
  />
);

export default Withdrawal;
