import { ColumnDef } from 'v2s-ui-core/app/components/DataGridCore';
import { TraderRes } from '../../types';
import { Link } from 'react-router-dom';
import APPLICATION_URLS from '~/shell/Routing/config/appsConfig';

export const getTradersTableColumns = (): ColumnDef<TraderRes>[] => [
  {
    header: 'Registration Date',
    enableColumnFilter: false,
    cell: ({ row }) =>
      row.original.user?.registration_date &&
      new Date(row?.original?.user.registration_date).toLocaleDateString(),
  },
  {
    header: 'Name',
    enableColumnFilter: false,
    cell: ({ row }) => row?.original?.user?.full_name,
  },
  {
    header: 'Email',
    enableColumnFilter: false,
    cell: ({ row }) => (
      <Link state={row.original?.user.id} to={APPLICATION_URLS?.USER_PROFILE}>
        {row.original.user?.email}
      </Link>
    ),
  },
  {
    header: 'Country',
    enableColumnFilter: false,
    cell: ({ row }) => row?.original.user?.country,
  },
  {
    header: 'Phone',
    enableColumnFilter: false,
    cell: ({ row }) => row?.original?.user?.phone_number,
  },
  {
    header: 'IB',
    enableColumnFilter: false,
    cell: ({ row }) => row?.original?.user?.user_type.type,
  },
  {
    header: 'KYC Status',
    enableColumnFilter: false,
    cell: ({ row }) => row?.original?.kyc_status,
  },
  {
    header: 'Referral',
    enableColumnFilter: false,
    cell: ({ row }) =>
      row?.original?.user?.referral_user &&
      row?.original?.user?.referral_user?.full_name,
  },
];
