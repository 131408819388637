import { defineMessages } from 'v2s-ui-core/app/Integrations/Localization';

export default defineMessages({
  approveModalTitle: {
    id: 'kyc.approveModalTitle',
    defaultMessage: 'Approve Kyc?',
  },
  rejectModalTitle: {
    id: 'kyc.rejectModalTitle',
    defaultMessage: 'Reject Kyc?',
  },
  kycModalTitle: {
    id: 'kyc.kyc_Status',
    defaultMessage: 'Approve/Reject Kyc ',
  },

  approved: {
    id: 'kyc.approved',
    defaultMessage: 'Approved',
  },
  rejected: {
    id: 'kyc.rejected',
    defaultMessage: 'Rejected',
  },
  pending: {
    id: 'kyc.pending',
    defaultMessage: 'Pending',
  },
  addRemark: {
    id: 'kyc.addRemark',
    defaultMessage: 'Add Remark',
  },
  approve: {
    id: 'kyc.approve',
    defaultMessage: 'Approve',
  },
  reject: {
    id: 'kyc.reject',
    defaultMessage: 'Reject',
  },
  cancel: {
    id: 'kyc.cancel',
    defaultMessage: 'Cancel',
  },
  remarkRequired: {
    id: 'kyc.remarkRequired',
    defaultMessage: 'Remark is required',
  },
  clickToView: {
    id: 'kyc.clickToView',
    defaultMessage: 'click here to view it.',
  },
  clickToClose: {
    id: 'kyc.clickToClose',
    defaultMessage: 'click here to close it.',
  },
  approveSuccessMessage: {
    id: 'kyc.approveSuccessMessage',
    defaultMessage: 'Kyc approved successfully!',
  },
  rejectSuccessMessage: {
    id: 'kyc.rejectSuccessMessage',
    defaultMessage: 'Kyc rejected successfully!',
  },
  approveFailedMessage: {
    id: 'kyc.approveFailedMessage',
    defaultMessage: 'Kyc approval failed!',
  },
  rejectFailedMessage: {
    id: 'kyc.rejectFailedMessage',
    defaultMessage: 'Kyc rejection failed!',
  },
  viewKyc: {
    id: 'kyc.viewKyc',
    defaultMessage: 'Kyc ',
  },
  document_name: {
    id: 'kyc.document_name',
    defaultMessage: 'Document Name',
  },
  actions: {
    id: 'kyc.actions',
    defaultMessage: 'Actions',
  },

  view: {
    id: 'kyc.view',
    defaultMessage: 'View',
  },
  close: {
    id: 'kyc.close',
    defaultMessage: 'Close',
  },
  kycDetails: {
    id: 'kyc.kycDetails',
    defaultMessage: 'Kyc Details',
  },
  documentDetails: {
    id: 'kyc.documentDetails',
    defaultMessage: 'Document Details',
  },
  document_type: {
    id: 'kyc.documentType',
    defaultMessage: 'Document Type',
  },
  expire_at: {
    id: 'kyc.expireAt',
    defaultMessage: 'Expire At',
  },
});

export const ExpiryMessage = defineMessages({
  approveSuccessMessage: {
    id: 'kyc.approveSuccessMessage',
    defaultMessage: 'Expiry date added successfully!',
  },
  rejectSuccessMessage: {
    id: 'kyc.rejectSuccessMessage',
    defaultMessage: 'Expiry date added successfully!',
  },
  approveFailedMessage: {
    id: 'kyc.approveFailedMessage',
    defaultMessage: 'Expiry date addition failed!',
  },
  rejectFailedMessage: {
    id: 'kyc.rejectFailedMessage',
    defaultMessage: 'Kyc rejection failed!',
  },
});
