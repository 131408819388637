import { ColumnDef } from 'v2s-ui-core/app/components/DataGridCore';
import ActionButtons from '~/components/Actions/ActionButtons';
import { ACCOUNT_STATUS, ACCOUNT_STATUS_CONFIG } from '../../constants';
import TruncatedHeader from '~/components/TruncatedHeader';
import { AccountStatus } from '../../types';
export const LeverageChangeTableColumns = (props: {
  onApproveRecord?: Function;
  onRejectRecord?: Function;
  handleViewLeverage?: Function;
}): ColumnDef<AccountStatus>[] => [
  {
    accessorKey: 'id',
    header: 'Request ID',
    enableColumnFilter: true,
  },
  {
    accessorKey: 'trading_account.user.full_name',
    header: 'User Name',
    enableColumnFilter: true,
  },
  {
    accessorKey: 'trading_account.user.email',
    header: 'User Email',
    enableColumnFilter: true,
  },
  {
    accessorFn: (row) => row.trading_account?.leverage,
    header: 'Current Leverage',
    enableColumnFilter: true,
  },
  {
    accessorKey: 'value',
    id: 'requested_leverage',
    header: () => <TruncatedHeader maxLength={14} text='Requested Leverage' />,
    enableColumnFilter: true,
  },
  {
    accessorKey: 'trading_account.id',
    header: 'Trading Account ID',
    enableColumnFilter: true,
  },
  {
    accessorKey: 'trading_account.login',
    id: 'trading_account_login',
    header: () => (
      <TruncatedHeader maxLength={14} text='Trading Account Login' />
    ),
    enableColumnFilter: true,
  },
  {
    accessorKey: 'trading_account.account_type',
    header: 'Account Type',
    enableColumnFilter: true,
  },
  {
    accessorKey: 'trading_group',
    header: 'Trading Group',
    enableColumnFilter: true,
  },
  {
    accessorKey: 'status',
    header: 'Request Status',
    enableColumnFilter: true,
  },
  {
    accessorKey: 'remark',
    header: 'Remark',
    enableColumnFilter: true,
  },
  {
    accessorKey: 'created_at',
    header: 'Created At',
    enableColumnFilter: true,
  },
  {
    accessorKey: 'updated_at',
    header: 'Updated At',
    enableColumnFilter: true,
  },
  {
    accessorKey: 'created_by.email',
    header: 'Created By',
    enableColumnFilter: true,
  },
  {
    accessorKey: 'updated_by',
    header: 'Updated By',
    enableColumnFilter: true,
  },
  {
    accessorKey: 'actions',
    header: 'Actions',
    enableColumnFilter: false,

    cell: ({ cell }) => {
      const { row } = cell;
      const status = row.original.status;

      return (
        <>
          <ActionButtons
            status={status}
            onApproveRecord={props?.onApproveRecord}
            onRejectRecord={props?.onRejectRecord}
            rowData={row.original}
            ACCOUNT_STATUS={ACCOUNT_STATUS}
            ACCOUNT_STATUS_CONFIG={ACCOUNT_STATUS_CONFIG}
            handleViewLeverage={props?.handleViewLeverage}
            showLeverageHistory
          />
        </>
      );
    },
  },
];
