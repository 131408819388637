import {
  Dialog,
  DialogTitle,
  Typography,
  DialogContent,
  DialogContentText,
  Grid,
  TextField,
  Button,
  Box,
} from '@mui/material';
import { blue } from '@mui/material/colors';
import { FormattedMessage } from '~app/Integrations/Localization';
import messages from '../messages';
import { CloseButton } from '~/components/Actions/CloseButton';
import { useIsMobile } from '~/hooks/useIsMobile';
const ViewDocModal = ({
  account,
  toggle,
  setValue,
  value,
}: {
  account: any;
  toggle: () => void;
  setValue: (boolean: boolean) => void;
  value: boolean;
}) => {
  const isMobile = useIsMobile();
  return (
    <Dialog
      open={value}
      PaperProps={{
        component: 'form',
        sx: {
          width: isMobile ? '100vw' : '698px',
          maxWidth: '698px',
          margin: isMobile ? 1 : 0,
        },
      }}
    >
      <DialogTitle
        sx={{
          backgroundColor: blue[50],
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography>
          <FormattedMessage {...messages.bank_account} />
        </Typography>

        <CloseButton onClose={() => setValue(false)} />
      </DialogTitle>
      <DialogContent sx={{ marginTop: '16px' }}>
        <DialogContentText
          sx={{ borderBottom: '1px solid #e0e0e0', paddingBottom: '5px' }}
        >
          <Typography>
            <FormattedMessage {...messages.account_details} />
          </Typography>
        </DialogContentText>
        <Grid container spacing={2} mt={'16px'}>
          <Grid item xs={12} sm={6}>
            <TextField
              sx={{
                width: '292px',
              }}
              size='small'
              label={<FormattedMessage {...messages.document_name} />}
              disabled
              value={
                (account &&
                  account?.user_document?.document_name +
                    (account?.user_document?.latest_version?.extension
                      ? `${account?.user_document?.latest_version.extension}`
                      : '')) ||
                ''
              }
            />

            <TextField
              sx={{
                width: '292px',
              }}
              size='small'
              autoComplete='off'
              label={<FormattedMessage {...messages.bank_account_number} />}
              margin='normal'
              name='bank_account_number'
              disabled
              value={(account && account?.bank_account_number) || ''}
            />

            <TextField
              sx={{
                width: '292px',
              }}
              size='small'
              autoComplete='off'
              label={<FormattedMessage {...messages.bank_ifsc} />}
              margin='normal'
              name='bank_ifsc'
              disabled
            />
          </Grid>

          <Grid item xs={2} sm={2}>
            <img
              alt='document'
              width={'310px'}
              height={'160px'}
              src={
                (account &&
                  account?.user_document?.latest_version
                    ?.original_doc_location) ||
                ''
              }
            />
          </Grid>
        </Grid>
        <Box display={'flex'} justifyContent={'flex-end'} mt={'24px'}>
          <Button
            onClick={() => setValue(false)}
            sx={{
              width: '100px',
            }}
            variant='outlined'
          >
            <FormattedMessage {...messages.close} />
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default ViewDocModal;
