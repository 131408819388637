import { FC, useMemo } from 'react';
import DataGridWithQuery from 'v2s-ui-core/app/components/DataGridWithQuery';
import { API_URLS } from '../../api/config';
import { QUERY_KEYS } from '../../services/queries/constants';
import { PasswordChangeTableColumns } from './PasswordChangeTableColumns';
import { useApproveRejectActions } from 'src/hooks/ApproveRejectCustomHook/useApproveRejectActions';
import { ACCOUNT_STATUS } from '../../constants';
import { ApproveRejectPasswordChange } from './ApproveRejectPasswordChange/index';
import { ColumnDef } from 'v2s-ui-core/app/components/DataGridCore';
import { passwordChangeMessages } from '../../messages';

const PasswordChangeTable: FC<{}> = () => {
  const {
    recordForApproveReject,
    onApproveRecord,
    onRejectRecord,
    onCancel,
    onConfirmApproveReject,
  } = useApproveRejectActions({
    QUERY_KEYS: QUERY_KEYS.PASSWORD_CHANGE_REQUESTS,
    API_URLS: API_URLS.APPROVE_REJECT_PASSWORD_CHANGE,
    STATUS: ACCOUNT_STATUS,
    messages: passwordChangeMessages,
  });

  // const handleConfirmApproveReject = () => {
  //   const payload = {
  //     status:
  //       recordForApproveReject?.action === 'APPROVED' ? 'APPROVED' : 'REJECTED',
  //     change_type: 'PASSWORD',
  //     id: recordForApproveReject?.record.id,
  //   };
  //   onConfirmApproveReject(payload);
  // };

  const memoizedColumns = useMemo(
    () =>
      PasswordChangeTableColumns({
        onApproveRecord,
        onRejectRecord,
      }),
    [onApproveRecord, onRejectRecord],
  );

  return (
    <>
      <DataGridWithQuery
        queryProps={{
          resource: API_URLS.PASSWORD_CHANGE_REQUESTS,
          queryKeyProp: [QUERY_KEYS.PASSWORD_CHANGE_REQUESTS],
          select: (data: any) => data.data,
          refetchInterval: 5000,
        }}
        columns={memoizedColumns as ColumnDef<unknown>[]}
      />
      {recordForApproveReject && (
        <ApproveRejectPasswordChange
          onCancel={onCancel}
          onConfirmApproveReject={onConfirmApproveReject}
          recordForApproveReject={recordForApproveReject}
        />
      )}
    </>
  );
};

export default PasswordChangeTable;
