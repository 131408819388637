import React from 'react';
import Box from '~app/components/Box';
import { Button, Grid, TextField } from '@mui/material';
import { useUserProfileData } from '../hooks/useUserProfileData';
import { FinancialDetails } from '../types';
import { FormattedMessage } from 'v2s-ui-core/app/Integrations/Localization';
import messages from '../messages';
const FinancialInformation = () => {
  const { data } = useUserProfileData();

  const financialDetails: FinancialDetails | undefined =
    data?.data?.financial_details;

  return (
    <Box padding={'10px'} display={'flex'} flexDirection={'column'} gap={'32x'}>
      <Grid container spacing={0} columnSpacing={2}>
        <Grid item xs={12} sm={4}>
          <TextField
            size='small'
            autoComplete='off'
            label={<FormattedMessage {...messages.trading_purpose} />}
            margin='normal'
            name='intended_purpose'
            disabled
            value={financialDetails?.trading_purpose?.name || ''}
          />
        </Grid>
      </Grid>
      <Grid container spacing={0} columnSpacing={2}>
        <Grid item xs={12} sm={4}>
          <TextField
            size='small'
            autoComplete='off'
            label={<FormattedMessage {...messages.education_level} />}
            margin='normal'
            name='education_level'
            disabled
            value={financialDetails?.education_level?.name || ''}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            size='small'
            autoComplete='off'
            label={<FormattedMessage {...messages.employment_status} />}
            margin='normal'
            name='employment_status'
            disabled
            value={financialDetails?.employment_status?.name || ''}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            size='small'
            autoComplete='off'
            label={<FormattedMessage {...messages.estimated_net_worth} />}
            margin='normal'
            fullWidth
            name='estimated_net_worth'
            disabled
            value={financialDetails?.estimated_net_worth?.range || ''}
          />
        </Grid>
      </Grid>
      <Grid container spacing={0} columnSpacing={2}>
        <Grid item xs={12} sm={4}>
          <TextField
            size='small'
            autoComplete='off'
            label={<FormattedMessage {...messages.estimated_annual_income} />}
            margin='normal'
            name='estimated_annual_income'
            disabled
            value={financialDetails?.estimated_annual_income?.range || ''}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            size='small'
            autoComplete='off'
            label={
              <FormattedMessage {...messages.estimated_investment_amount} />
            }
            margin='normal'
            name='estimated_investment_amount'
            disabled
            value={financialDetails?.estimated_investment_amount?.range || ''}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            size='small'
            autoComplete='off'
            label={<FormattedMessage {...messages.source_of_funds} />}
            margin='normal'
            name='source_of_funds'
            fullWidth
            disabled
            value={financialDetails?.source_of_funds?.name || ''}
          />
        </Grid>
      </Grid>
      <Grid container spacing={0} columnSpacing={2}>
        <Grid item xs={12} sm={4}>
          <TextField
            size='small'
            autoComplete='off'
            label={<FormattedMessage {...messages.source_of_wealth} />}
            margin='normal'
            name='source_of_wealth'
            disabled
            value={financialDetails?.source_of_wealth?.name || ''}
          />
        </Grid>
        <Grid item xs={12} sm={8}>
          <TextField
            size='small'
            autoComplete='off'
            fullWidth
            label={<FormattedMessage {...messages.expected_origin_of_funds} />}
            margin='normal'
            name='expected_origin_of_funds'
            disabled
          />
        </Grid>
      </Grid>
      <Box display={'flex'} marginTop={'32px'} justifyContent={'flex-end'}>
        <Button disabled variant='contained' sx={{ width: '113px' }}>
          Save
        </Button>
      </Box>
    </Box>
  );
};

export default FinancialInformation;
