import { useMemo } from 'react';
import ApiServices from '../../api';
import { QUERY_KEYS } from './constants';
import { QueryDetails } from './types';

export const useQueryFactory = <TData, TError = Error>() => {
  const { FETCH_GENEALOGY_HISTORY, FETCH_GENEALOGY } = QUERY_KEYS;

  const queryDetails = useMemo<QueryDetails<TData, TError>>(
    () => ({
      [FETCH_GENEALOGY_HISTORY]: (params?: any) => ({
        queryKey: [FETCH_GENEALOGY_HISTORY],
        queryFn: () => ApiServices.getGenealogyList<TData>(params),
      }),
      [FETCH_GENEALOGY]: (id: number, params?: any) => ({
        queryKey: [FETCH_GENEALOGY],
        queryFn: () => ApiServices.getGenealogyHistory<TData>(id, params),
      }),
    }),
    [],
  );
  return queryDetails;
};
