import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import { blue } from '@mui/material/colors';
import { FormattedMessage } from 'v2s-ui-core/app/Integrations/Localization';
import Button from 'v2s-ui-core/app/components/Button';
import { CloseButton } from '~/components/Actions/CloseButton';
import { passwordChangeMessages } from '~/modules/Requests/messages';
import { ACCOUNT_STATUS } from '~/modules/Requests/constants';
import { useApproveRejectRequests } from '~/modules/Requests/hooks/useApproveRejectRequests';
import Grid from 'v2s-ui-core/app/components/Grid';
import TextField from 'v2s-ui-core/app/components/Input/TextField';
import { ApproveRejectRequestsProps } from '../../../types';
import { useIsMobile } from '~/hooks/useIsMobile';
export const ApproveRejectPasswordChange = ({
  onCancel,
  recordForApproveReject,
  onConfirmApproveReject,
}: //props
ApproveRejectRequestsProps) => {
  const { handleChange, handleSubmit, errors } = useApproveRejectRequests({
    onConfirmApproveReject,
    recordForApproveReject,
  });

  const isMobile = useIsMobile();

  return (
    <Dialog
      open={true}
      onClose={onCancel}
      PaperProps={{
        component: 'form',
        sx: {
          width: isMobile ? '100vw' : '636px',
          margin: isMobile ? 1 : 0,
        },
      }}
    >
      <DialogTitle sx={{ backgroundColor: blue[50] }}>
        {recordForApproveReject.action === ACCOUNT_STATUS.APPROVED ? (
          <FormattedMessage {...passwordChangeMessages.approveModalTitle} />
        ) : (
          <FormattedMessage {...passwordChangeMessages.rejectModalTitle} />
        )}
        <CloseButton onClose={onCancel} />
      </DialogTitle>
      <DialogContent sx={{ padding: 1 }}>
        <DialogContentText ml={2} mt={2}>
          {recordForApproveReject.action === ACCOUNT_STATUS.APPROVED ? (
            <FormattedMessage
              {...passwordChangeMessages.approvePasswordModalHelpText}
            />
          ) : (
            <FormattedMessage
              {...passwordChangeMessages.rejectPasswordModalHelpText}
            />
          )}
        </DialogContentText>
        <Grid container spacing={2} p={2}>
          <Grid item xs={12}>
            <TextField
              name={'remark'}
              variant='outlined'
              onChange={handleChange}
              label={<FormattedMessage {...passwordChangeMessages.addRemark} />}
              // @ts-ignore
              size='small'
              required
              type='text'
              error={Boolean(errors.remark)}
              // @ts-ignore
              helperText={errors.remark}
              // @ts-ignore
            />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions sx={{ padding: 3 }}>
        <Button variant='contained' onClick={() => handleSubmit()}>
          {recordForApproveReject.action === ACCOUNT_STATUS.APPROVED ? (
            <FormattedMessage {...passwordChangeMessages.approve} />
          ) : (
            <FormattedMessage {...passwordChangeMessages.reject} />
          )}
        </Button>
        <Button onClick={onCancel} variant='outlined'>
          <FormattedMessage {...passwordChangeMessages.cancel} />
        </Button>
      </DialogActions>
    </Dialog>
  );
};
