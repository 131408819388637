import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import { blue } from '@mui/material/colors';
import { FormattedMessage } from 'v2s-ui-core/app/Integrations/Localization';
import Grid from 'v2s-ui-core/app/components/Grid';
import TextField from 'v2s-ui-core/app/components/Input/TextField';
import Button from 'v2s-ui-core/app/components/Button';
import { ApproveRejectWithdrawalProps } from '~/modules/Withdrawal/types';
import messages from '~/modules/Withdrawal/messages';
import { WITHDRAWALS_STATUS } from '~/modules/Withdrawal/constants';
import { useApproveRejectWithdrawalAccount } from '../../../hooks/useApproveRejectWithdrawalAccount';
import { CloseButton } from '~/components/Actions/CloseButton';
import AsyncDropdown from '~app/components/AsyncDropdown';
import { API_URLS } from '../../../api/config';
import { QUERY_KEYS } from '../../../services/queries/constants';
import { useIsMobile } from '~/hooks/useIsMobile';

const ApproveRejectWithdrawal = ({
  onCancel,
  recordForApproveReject,
  onConfirmApproveReject,
}: //props
ApproveRejectWithdrawalProps) => {
  const {
    formik: { handleChange, handleSubmit, errors, values },
    paymentMethod,
    setPaymentMethod,
  } = useApproveRejectWithdrawalAccount({
    onConfirmApproveReject,
    recordForApproveReject,
  });

  const isMobile = useIsMobile();

  return (
    <Dialog
      open={true}
      onClose={onCancel}
      PaperProps={{
        component: 'form',
        sx: {
          width: isMobile ? '100vw' : '600px',
        },
      }}
    >
      <DialogTitle sx={{ backgroundColor: blue[50] }}>
        {recordForApproveReject.action === WITHDRAWALS_STATUS.APPROVED ? (
          <FormattedMessage {...messages.approveModalTitle} />
        ) : (
          <FormattedMessage {...messages.rejectModalTitle} />
        )}
        <CloseButton onClose={onCancel} />
      </DialogTitle>
      <DialogContent sx={{ padding: 1 }}>
        <DialogContentText ml={2} mt={2}>
          {recordForApproveReject.action === WITHDRAWALS_STATUS.APPROVED ? (
            <FormattedMessage {...messages.approveModalHelpText} />
          ) : (
            <FormattedMessage {...messages.rejectModalHelpText} />
          )}
        </DialogContentText>

        {/* Form Fields */}
        <Grid container spacing={2} p={isMobile ? '8px' : '16px'} pt={2}>
          {recordForApproveReject.action === WITHDRAWALS_STATUS.APPROVED && (
            <>
              <Grid item width={'360px'}>
                <AsyncDropdown
                  InputProps={{
                    label: (
                      <FormattedMessage {...messages.choosePaymentMethod} />
                    ),
                    error: Boolean(errors.paymentMethod),
                    helperText: errors.paymentMethod as any,
                  }}
                  queryProps={{
                    resource: API_URLS.FETCH_PAYMENT_METHODS,
                    queryKeyProp: [QUERY_KEYS.FETCH_PAYMENT_METHODS],
                    select: (data: any) => data.data,
                  }}
                  value={paymentMethod && (paymentMethod as any)}
                  onChange={(e, value) => setPaymentMethod(value)}
                  getOptionLabel={(option) => option?.name || ''}
                  getOptionKey={(option) => option.id}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name={'local_amount'}
                  onChange={handleChange}
                  value={values?.local_amount}
                  variant='outlined'
                  label={<FormattedMessage {...messages.localAmount} />}
                  size='small'
                />
              </Grid>
            </>
          )}
          <Grid item width={'294px'} xs={12}>
            <TextField
              name={'remark'}
              variant='outlined'
              onChange={handleChange}
              label={<FormattedMessage {...messages.addRemark} />}
              // @ts-ignore
              size='small'
              required
              type='text'
              error={Boolean(errors.remark)}
              // @ts-ignore
              helperText={errors.remark}
              // @ts-ignore
            />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions sx={{ padding: 2 }}>
        <Button variant='contained' onClick={() => handleSubmit()}>
          {recordForApproveReject.action === WITHDRAWALS_STATUS.APPROVED ? (
            <FormattedMessage {...messages.approve} />
          ) : (
            <FormattedMessage {...messages.reject} />
          )}
        </Button>
        <Button onClick={onCancel} variant='outlined'>
          <FormattedMessage {...messages.cancel} />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ApproveRejectWithdrawal;
