import { ColumnDef } from 'v2s-ui-core/app/components/DataGridCore';
import { TradingAccountDataType } from '../types';

export const TradingAccountTableColumn: ColumnDef<TradingAccountDataType>[] = [
  {
    header: 'User',
    enableColumnFilter: true,
    cell: ({ row }) =>
      row.original.user.id + '  ' + row.original.user.full_name,
  },
  {
    header: 'Trading Account',
    enableColumnFilter: true,
    accessorKey: 'trading_account',
  },
  {
    header: 'Account Type',
    enableColumnFilter: true,
    accessorKey: 'account_type.type',
  },
  {
    header: 'Trading Group',
    enableColumnFilter: true,
    accessorKey: 'trading_group.name',
  },
  {
    accessorKey: 'leverage',
    header: 'Leverage',
    enableColumnFilter: true,
  },
  {
    accessorKey: 'currency',
    header: 'Currency',
    enableColumnFilter: true,
  },
  {
    accessorKey: 'balance',
    header: 'Balance',
    enableColumnFilter: true,
  },
  {
    accessorKey: 'credit',
    header: 'Credit',
    enableColumnFilter: true,
  },
  {
    header: 'Balance (Previous Day)',
    enableColumnFilter: true,
    accessorKey: 'balance_prevday',
  },
  {
    header: 'Balance (Previous Month)',
    enableColumnFilter: true,
    accessorKey: 'balance_prevmonth',
  },
  {
    header: 'Equity (Previous Day)',
    enableColumnFilter: true,
    accessorKey: 'equity_prevday',
  },
  {
    header: 'Equity (Previous Month)',
    enableColumnFilter: true,
    accessorKey: 'equity_prevmonth',
  },
  {
    header: 'Trading Password',
    enableColumnFilter: true,
    accessorKey: 'trading_password',
  },
  {
    header: 'MQID',
    enableColumnFilter: true,
    accessorKey: 'mqid',
  },
  {
    header: 'Last Access IP',
    enableColumnFilter: true,
    accessorKey: 'last_access_ip',
  },
  {
    header: 'Last Access At',
    enableColumnFilter: true,
    accessorKey: 'last_access_at',
  },
  {
    accessorKey: 'status',
    header: 'Status',
    enableColumnFilter: true,
  },
  {
    header: 'CRM Status',
    enableColumnFilter: true,
    accessorKey: 'crm_status',
  },
  {
    header: 'Created At',
    enableColumnFilter: true,
    accessorKey: 'created_at',
  },
  {
    header: 'Updated At',
    enableColumnFilter: true,
    accessorKey: 'updated_at',
  },
  {
    header: 'Deleted At',
    enableColumnFilter: true,
    accessorKey: 'deleted_at',
  },
  {
    header: 'Created By',
    enableColumnFilter: true,
    accessorKey: 'created_by',
  },
  {
    header: 'Updated By',
    enableColumnFilter: true,
    accessorKey: 'updated_by',
  },
  {
    header: 'Deleted By',
    enableColumnFilter: true,
    accessorKey: 'deleted_by',
  },
];
