import { ColumnDef } from 'v2s-ui-core/app/components/DataGridCore';
import ActionButtons from '~/components/Actions/ActionButtons';
import { ACCOUNT_STATUS, ACCOUNT_STATUS_CONFIG } from '../../constants';
import { AccountStatus } from '../../types';
import TruncatedHeader from '~/components/TruncatedHeader';

export const PasswordChangeTableColumns = (props: {
  onApproveRecord?: Function;
  onRejectRecord?: Function;
}): ColumnDef<AccountStatus>[] => [
  {
    accessorKey: 'id',
    header: 'Request ID',
    enableColumnFilter: true,
  },
  {
    // accessorFn: (row) => row.trading_account.user?.full_name,
    accessorKey: 'trading_account.user.full_name',
    header: 'User Name',
    enableColumnFilter: true,
  },
  {
    accessorKey: 'trading_account.user.email',
    header: 'User Email',
    enableColumnFilter: true,
  },
  {
    accessorKey: 'trading_account.id',
    header: 'Trading Account ID',
    enableColumnFilter: true,
  },
  {
    accessorKey: 'trading_account.login',
    enableColumnFilter: true,
    header: () => (
      <TruncatedHeader text='Trading Account Login' maxLength={14} useTooltip />
    ),
  },
  {
    accessorKey: 'trading_account.account_type.type',
    header: 'Account Type',
    enableColumnFilter: true,
  },
  {
    accessorKey: 'trading_account.password',
    header: 'Current Password',
    enableColumnFilter: true,
  },
  {
    accessorKey: 'value',
    enableColumnFilter: true,
    header: () => (
      <TruncatedHeader text='Requested Password' maxLength={14} useTooltip />
    ),
  },
  {
    accessorKey: 'status',
    header: 'Status',
    enableColumnFilter: true,
  },
  {
    accessorKey: 'remark',
    header: 'Remark',
    enableColumnFilter: true,
  },
  {
    accessorKey: 'created_by',
    header: 'Created By',
    enableColumnFilter: true,
  },
  {
    accessorKey: 'updated_by',
    header: 'Updated By',
    enableColumnFilter: true,
  },
  {
    accessorKey: 'created_at',
    header: 'Created At',
    enableColumnFilter: true,
  },
  {
    accessorKey: 'updated_at',
    header: 'Updated At',
    enableColumnFilter: true,
  },
  {
    accessorKey: 'actions',
    header: 'Actions',
    enableColumnFilter: false,
    cell: ({ cell }) => {
      const { row } = cell;
      const status = row.original.status;

      return (
        <ActionButtons
          status={status}
          onApproveRecord={props?.onApproveRecord}
          onRejectRecord={props?.onRejectRecord}
          rowData={row.original}
          showViewButton={false}
          ACCOUNT_STATUS={ACCOUNT_STATUS}
          ACCOUNT_STATUS_CONFIG={ACCOUNT_STATUS_CONFIG}
        />
      );
    },
  },
];
