import {
  Dialog,
  DialogTitle,
  Typography,
  DialogContent,
  DialogContentText,
  Grid,
  TextField,
  Button,
  Box,
} from '@mui/material';
import { blue } from '@mui/material/colors';
import { FormattedMessage } from '~app/Integrations/Localization';
import messages from '../messages';
import { CloseButton } from '~/components/Actions/CloseButton';
import { isDocumentImage, isDocumentPdf } from '~/utils/index';
import { useIsMobile } from '~/hooks/useIsMobile';

const ViewKycDocModal = ({
  setValue,
  value,
}: {
  setValue: (boolean: boolean) => void;
  value: any;
}) => {
  const { latest_version: documentData = {} } = value || {};
  const {
    original_doc_location: documentUrl = '',
    content_type: contentType = '',
    extension = '',
  } = documentData;

  const isMobile = useIsMobile();

  return (
    <Dialog
      open={value}
      PaperProps={{
        component: 'form',
        sx: {
          maxWidth: '698px',
          width: isMobile ? '100vw' : '698px',
          margin: isMobile ? 1 : 0,
        },
      }}
    >
      <DialogTitle
        sx={{
          backgroundColor: blue[50],
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography>
          <FormattedMessage {...messages.kycDetails} />
        </Typography>
        <CloseButton onClose={() => setValue(false)} />
      </DialogTitle>
      <DialogContent sx={{ marginTop: '16px' }}>
        <DialogContentText
          sx={{ borderBottom: '1px solid #e0e0e0', paddingBottom: '5px' }}
        >
          <Typography>
            <FormattedMessage {...messages.documentDetails} />
          </Typography>
        </DialogContentText>
        <Grid container spacing={2} mt={'16px'}>
          <Grid item xs={12} sm={6}>
            <TextField
              sx={{
                width: '292px',
              }}
              size='small'
              label={<FormattedMessage {...messages.document_name} />}
              disabled
              value={value?.document_name || ''}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              sx={{
                width: '292px',
              }}
              size='small'
              label={<FormattedMessage {...messages.document_type} />}
              disabled
              value={value?.document_type || ''}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              sx={{
                width: '292px',
              }}
              size='small'
              label={<FormattedMessage {...messages.expire_at} />}
              disabled
              value={value?.expire_at || 'Na'}
            />
          </Grid>

          <Grid
            item
            xs={12}
            display='flex'
            alignItems='center'
            justifyContent='center'
          >
            {isDocumentPdf(contentType, extension) ? (
              <iframe
                src={documentUrl}
                width='100%'
                height='400px'
                style={{
                  border: '1px solid #e0e0e0',
                }}
                title='Document Preview'
              />
            ) : isDocumentImage(contentType) ? (
              <img
                alt='Document'
                src={documentUrl}
                style={{
                  maxWidth: '100%',
                  maxHeight: '400px',
                  border: '1px solid #e0e0e0',
                }}
              />
            ) : (
              <Typography color='error'>Na</Typography>
            )}
          </Grid>
        </Grid>
        <Box display={'flex'} justifyContent={'flex-end'} mt={'24px'}>
          <Button
            onClick={() => setValue(false)}
            sx={{
              width: '100px',
            }}
            variant='outlined'
          >
            <FormattedMessage {...messages.close} />
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default ViewKycDocModal;
