import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useCrudContext } from 'v2s-ui-core/app/containers/CrudProvider';
import { FormattedMessage } from 'v2s-ui-core/app/Integrations/Localization';
import messages from '../../messages';

export default function ConfirmationDialog() {
  const { showConfirmationDialog, hideConfirmationDialog, onConfirmDelete } =
    useCrudContext();

  return (
    <React.Fragment>
      <Dialog
        open={showConfirmationDialog}
        onClose={hideConfirmationDialog}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title' variant='h6'>
          <FormattedMessage {...messages.deleteUser} />
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description' variant='body2'>
            <FormattedMessage {...messages.deleteUserConfirmationText} />
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ padding: 3 }}>
          <Button color='error' variant='contained' onClick={onConfirmDelete}>
            <FormattedMessage {...messages.yes} />
          </Button>
          <Button variant='outlined' onClick={hideConfirmationDialog} autoFocus>
            <FormattedMessage {...messages.cancel} />
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
