import { useFormik } from 'formik';
import { useLocaleContext } from 'v2s-ui-core/app/Integrations/Localization';
import { getApproveRejectSchema } from './../services/mutations/constants';
import { ApproveRejectWithdrawalProps } from './../types';
import React from 'react';

export const useApproveRejectWithdrawalAccount = ({
  onConfirmApproveReject,
  recordForApproveReject,
}: {
  onConfirmApproveReject: ApproveRejectWithdrawalProps['onConfirmApproveReject'];
  recordForApproveReject: ApproveRejectWithdrawalProps[`recordForApproveReject`];
}) => {
  const {
    messageFormatter: { formatMessage },
  } = useLocaleContext();

  const [paymentMethod, setPaymentMethod] = React.useState<any>();

  const formik = useFormik({
    initialValues: {
      remark: '',
      paymentMethod: paymentMethod,
      local_amount: recordForApproveReject?.record?.local_amount,
    },

    onSubmit: (values, { resetForm }) => {
      onConfirmApproveReject({
        remark: values.remark,
        ...(recordForApproveReject?.action === 'APPROVED' && {
          local_amount: values.local_amount,
          payment_method: paymentMethod ? paymentMethod.id : null,
        }),
        status:
          recordForApproveReject.action === 'APPROVED'
            ? 'APPROVED'
            : 'REJECTED',
        id: recordForApproveReject?.record?.id,
      });
      resetForm({
        values: { remark: '', paymentMethod: '', local_amount: '' },
      });
    },

    validationSchema: getApproveRejectSchema(
      formatMessage,
      recordForApproveReject.action,
    ),
    enableReinitialize: true,
    validateOnBlur: false,
    validateOnChange: false,
    validateOnMount: false,
  });

  return { formik, paymentMethod, setPaymentMethod };
};
