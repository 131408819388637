import BankAccountsTable from './components/BankAccountsTable';
import AppBaseLayout from '~/shell/components/Layout/components/AppLayout';
import { QUERY_KEYS } from './services/queries/constants';

const BankAccounts = () => (
  <AppBaseLayout
    contentProps={{
      content: <BankAccountsTable />,
    }}
    headerProps={{
      title: 'Bank Accounts',
      actionButtons: <></>,
    }}
    QUERY_KEYS={QUERY_KEYS.FETCH_BANK_ACCOUNTS}
    ShowRefresh
  />
);

export default BankAccounts;
