import Box from '@mui/material/Box';

import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import { blue, green } from '@mui/material/colors';
import Button from 'v2s-ui-core/app/components/Button';
import { DialogContent, Typography } from '@mui/material';
import success from '~/assets/images/success.svg';

interface SuccessModalProps {
  open: boolean;
  onClose: () => void;
  message: string;
}

const SuccessModal: React.FC<SuccessModalProps> = ({
  open,
  onClose,
  message,
  // eslint-disable-next-line arrow-body-style
}) => {
  return (
    <Dialog
      PaperProps={{
        component: 'form',
        sx: {
          minWidth: '500px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          py: '15px',
        },
      }}
      open={open}
      onClose={onClose}
    >
      <DialogTitle
        fontSize={28}
        fontWeight={600}
        sx={{ color: green[700], textAlign: 'center' }}
      >
        <Box display='flex' justifyContent='center'>
          <img src={success} alt='success' />
        </Box>
      </DialogTitle>
      <DialogContent>
        <Typography variant='h6' textAlign='center'>
          {message}
        </Typography>
      </DialogContent>
      <DialogActions
        sx={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Button variant='contained' onClick={onClose}>
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SuccessModal;
