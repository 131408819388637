import { useFormik } from 'formik';
import {
  USER_MANAGEMENT_FORM_KEYS,
  getUserManagementFormSchema,
} from './constants';
import { isEmpty } from 'v2s-ui-core/app/utils';
import {
  useCrudContext,
  CRUD_ACTIONS_TYPES,
} from 'v2s-ui-core/app/containers/CrudProvider';
import { useLocaleContext } from 'v2s-ui-core/app/Integrations/Localization';
import { useShowToastNotifications } from 'v2s-ui-core/app/containers/ToastNotifications/Provider';
import messages from '../../messages';
import { API_ERROR_TYPES } from '~/constants';
import { useQueryClient } from 'v2s-ui-core/app/Integrations/react-query';
import { QUERY_KEYS } from '../../services/queries/constants';

const useUserManagementForm = ({ onCancel }: { onCancel: Function }) => {
  const { recordToUpdate, CrudService } = useCrudContext();
  const { showToastNotification } = useShowToastNotifications();

  const queryClient = useQueryClient();

  const {
    messageFormatter: { formatMessage },
  } = useLocaleContext();

  const isEditMode = !isEmpty(recordToUpdate);
  const formik = useFormik({
    initialValues: {},
    onSubmit: (values, { resetForm }) => {
      handleRegister({
        ...values,
        role: (values as any)?.[USER_MANAGEMENT_FORM_KEYS.ROLE].id,
      });
      // resetForm({ values: {} });
    },
    validationSchema: getUserManagementFormSchema(isEditMode, formatMessage),
    enableReinitialize: true,
    validateOnBlur: false,
    validateOnChange: false,
    validateOnMount: false,
  });
  const onSuccess = () => {
    showToastNotification(
      formatMessage(
        isEditMode ? messages.updateUserSuccess : messages.addUserSuccess,
      ),
      'success',
    );

    queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.USER_MANAGEMENT] });

    onCancel();
  };

  const onError = (error: any) => {
    if (error.data && error.data?.type === API_ERROR_TYPES.SERVER_ERROR) {
      showToastNotification(formatMessage(messages.serverError), 'error');
    }
    if (error.data && error.data?.type === API_ERROR_TYPES.VALIDATION_ERROR) {
      showToastNotification(error.data.errors[0].detail, 'error');
    }
  };

  const handleRegister = (values: Record<string, unknown>) => {
    const __values = { ...values };
    if (isEditMode) {
      __values.id = recordToUpdate.id;
    }
    CrudService.mutate(
      {
        actionType: isEmpty(recordToUpdate)
          ? CRUD_ACTIONS_TYPES.ADD
          : CRUD_ACTIONS_TYPES.UPDATE,
        payload: __values,
      },
      { onSuccess, onError },
    );
  };

  return {
    ...formik,
    isEditMode,
  };
};

export default useUserManagementForm;
