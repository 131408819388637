import { get } from 'v2s-ui-core/app/Integrations/ApiService';
import { API_URLS } from './config';

const ApiServices = {
  getSummary: async <T>() => {
    try {
      const response = await get<T>(API_URLS.FETCH_DASHBOARD_SUMMARY);

      return response.data;
    } catch (error) {
      throw error;
    }
  },
  // GX_INJECT_API_SERVICE
};

export default ApiServices;
