import { ColumnDef } from 'v2s-ui-core/app/components/DataGridCore';

export const getCreditHistoryTableColumns =
  (props: {}): ColumnDef<unknown>[] => [
    {
      accessorKey: 'id',
      header: 'ID',
      enableColumnFilter: true,
    },
    {
      accessorKey: 'amount',
      header: 'Amount',
      enableColumnFilter: true,
    },
    {
      accessorKey: 'credit_type',
      header: 'Credit Type',
      enableColumnFilter: true,
    },
    {
      accessorKey: 'created_at',
      header: 'Created At',
      enableColumnFilter: true,
    },
    {
      accessorKey: 'created_by',
      header: 'Created By',
      enableColumnFilter: true,
    },
  ];
