/* eslint-disable arrow-body-style */
import { Box } from '@mui/material';
import DataGridWithQuery from 'v2s-ui-core/app/components/DataGridWithQuery';
import { TradingAccountTableColumn } from './componets/TradingAccountTableColumn';

// import TradingAccountTabs from './TradingAccountTabs';
import { API_URLS } from './api/config';
import { useLocation } from 'react-router-dom';
import { ColumnDef } from '~app/components/DataGridCore';

const TradingAccount = () => {
  const location = useLocation();

  return (
    <Box>
      <DataGridWithQuery
        columns={TradingAccountTableColumn as ColumnDef<unknown>[]}
        queryProps={{
          resource: API_URLS.FETCH_TRADING_ACCOUNT(location.state),
          queryKeyProp: ['fetchTradingAccounts'],
          select: (data: any) => data?.data,
          refetchInterval: 5000,
        }}
      />
      {/* <TradingAccountTabs /> */}
    </Box>
  );
};

export default TradingAccount;
