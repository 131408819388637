import RoutingProvider from '../../Routing';
import EntryPoint from '../EntryPoint';
import ServiceInterceptor from '../EntryPoint/ServiceInterceptor';
import UnauthorizedInterceptor from '../EntryPoint/UnauthorizedInterceptorWithStorage';

const Application: React.FC = () => (
  <EntryPoint>
    <ServiceInterceptor>
      <UnauthorizedInterceptor>
        <RoutingProvider />
      </UnauthorizedInterceptor>
    </ServiceInterceptor>
  </EntryPoint>
);
export default Application;
