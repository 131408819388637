import { useFormik } from 'formik';
import { useLocaleContext } from 'v2s-ui-core/app/Integrations/Localization';
import { CreditProps } from '.././types';
import { getCreditSchema } from '../services/mutations/constants';
export const useCreditForm = ({
  onConfirmCredit,
  creditRecord,
}: {
  onConfirmCredit: CreditProps['onConfirmApproveReject'];
  creditRecord: CreditProps['recordForApproveReject'];
}) => {
  const {
    messageFormatter: { formatMessage },
  } = useLocaleContext();

  const formik = useFormik({
    initialValues: {
      remark: '',
      amount: 0,
      trading_account_id: null as any,
      user_id: '', // Add user_id
    },
    onSubmit: (values, { resetForm }) => {
      onConfirmCredit({
        remark: values.remark,
        user_id: values?.trading_account_id?.user?.id,
        trading_account_id: values.trading_account_id?.id,
        amount: Number(values.amount),
        credit_type: creditRecord?.action === 'CREDIT_IN' ? 1 : 2,
      });
      resetForm();
    },
    validationSchema: getCreditSchema(formatMessage),
    enableReinitialize: true,
  });

  return { formik };
};
