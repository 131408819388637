import { FC, useMemo } from 'react';
import React from 'react';
import DataGridWithQuery from 'v2s-ui-core/app/components/DataGridWithQuery';
import { getTradersTableColumns } from './TradersTableColumn';
import { API_URLS } from '../../api/config';
import { QUERY_KEYS } from '../../services/queries/constants';
import { ColumnDef } from 'v2s-ui-core/app/components/DataGridCore';

const TradersTable: FC<{}> = () => {
  const memoizedColumns = useMemo(() => getTradersTableColumns(), []);

  return (
    <DataGridWithQuery
      queryProps={{
        resource: API_URLS.FETCH_TRADERS,
        queryKeyProp: [QUERY_KEYS.FETCH_TRADERS],
        select: (data: any) => data?.data,
        refetchInterval: 5000,
      }}
      columns={memoizedColumns as ColumnDef<unknown>[]}
    />
  );
};

export default TradersTable;
