import * as yup from 'yup';
import { getOldPasswordSchema, getPasswordSchema } from '~/schema';
// we don't have status id
export const ACCOUNT_STATUS = {
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED',
  PENDING: 'PENDING',
} as const;

export const MODES = {
  VIEW: 'view',
  ADD: 'add',
  EDIT: 'edit',
  DELETE: 'delete',
  CREATE: 'create',
  UPDATE: 'update',
  UPLOAD: 'upload',
} as const;

export const COUNTRIES = {
  INDIA: 'India',
  //add as needed
};

export const FILTERS = {
  SEARCH: 'value',
  START_DATE: 'start_date',
  END_DATE: 'end_date',
};

export const INPUT_FIELDS_LENGTHS = {
  FIRST_NAME: {
    MIN: 0,
    MAX: 255,
  },
  LAST_NAME: {
    MIN: 0,
    MAX: 255,
  },
  EMAIL: {
    MIN: 0,
    MAX: 255,
  },
  PHONE_NUMBER: {
    MIN: 0,
    MAX: 16,
  },
  REFERRAL: {
    MIN: 0,
    MAX: 255,
  },
  REMARK: {
    MIN: 0,
    MAX: 255,
  },
  PASSWORD: {
    MIN: 8,
    MAX: 20,
  },
  ADDRESS: {
    MIN: 8,
    MAX: 50,
  },
} as const;

export const INPUT_FIELD_REGEX = {
  EMAIL: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
  PASSWORD: /^(?=.*[a-z])(?=.*\d)[a-zA-Z\d]{8,20}$/,
  PHONE_NUMBER: /^\+?\d{1,4}\s?\d{1,14}$/,
} as const;

export const CHANGE_PASSWORD_FORM_VALUES = {
  new_password: '',
  confirm_password: '',
  old_password: '',
} as const;

export const getChangePasswordSchema = (formatMessage: any) =>
  yup.object().shape({
    old_password: getOldPasswordSchema(formatMessage),
    new_password: getPasswordSchema(formatMessage),
    confirm_password: yup
      .string()
      .oneOf([yup.ref('new_password'), null], 'Passwords must match')
      .required('Confirm password is required'),
  });
