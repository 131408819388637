import { FC, useMemo } from 'react';
import DataGridWithQuery from 'v2s-ui-core/app/components/DataGridWithQuery';
import { ColumnDef } from 'v2s-ui-core/app/components/DataGridCore';
import { WithdrawalTableColumns } from '~/modules/Withdrawal/components/WithdrawalTable/WithdrawalTableColumns';
import { useApproveRejectActions } from 'src/hooks/ApproveRejectCustomHook/useApproveRejectActions';
import ApproveRejectWithdrawal from '~/modules/Withdrawal/components/WithdrawalTable/ApproveRejectWithdrawal';
import { WITHDRAWALS_STATUS } from '~/modules/Withdrawal/constants';
import messages from '~/modules/Withdrawal/messages';
import { useLocation } from 'react-router-dom';
import SuccessModal from '~/components/SuccessModal';
import { TRANSACTION_TYPE } from '../constants';
import TableFilters from '~/components/TableFilters/index';
import useFilters from '~app/hooks/useFilters';
import { FILTERS } from '~/config/constants';
import Box from '~app/components/Box';
const WithdrawalTable: FC<{}> = () => {
  const location = useLocation();
  const {
    recordForApproveReject,
    onApproveRecord,
    onRejectRecord,
    onCancel,
    onConfirmApproveReject,
    onSuccessClose,
    successMessage,
    showSuccessModal,
  } = useApproveRejectActions({
    QUERY_KEYS: 'fetchUserWithdrawals',
    API_URLS: (id: string | number) => `crm/user/transactions/${id}`,
    STATUS: WITHDRAWALS_STATUS,
    user: true,
    messages: messages,
  });

  const { filters, applyFilters } = useFilters({
    filterTypes: FILTERS,
    namespace: 'fetchUserWithdrawals',
  });

  const memoizedColumns = useMemo(
    () =>
      WithdrawalTableColumns({
        onApproveRecord,
        onRejectRecord,
      }),
    [onApproveRecord, onRejectRecord],
  );

  return (
    <>
      <Box className='tableContainer' width={'100%'}>
        <Box display='flex' mb={'26px'}>
          <TableFilters filters={filters} applyFilters={applyFilters} />
        </Box>
      </Box>
      <Box className='ScrollableTableContainer'>
        <DataGridWithQuery
          queryProps={{
            resource: `crm/users/${location.state}/transactions`,
            queryKeyProp: ['fetchUserWithdrawals'],
            select: (data: any) => data.data,
          }}
          filters={{
            transaction_type: TRANSACTION_TYPE.WITHDRAWAL,
            ...filters,
          }}
          columns={memoizedColumns as ColumnDef<unknown>[]}
        />
      </Box>
      {recordForApproveReject && (
        <ApproveRejectWithdrawal
          onCancel={onCancel}
          recordForApproveReject={recordForApproveReject}
          onConfirmApproveReject={onConfirmApproveReject}
        />
      )}
      <SuccessModal
        open={showSuccessModal}
        onClose={onSuccessClose}
        message={successMessage}
      />
    </>
  );
};

export default WithdrawalTable;
