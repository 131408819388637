import { defineMessages } from '~app/Integrations/Localization';

export default defineMessages({
  walletDetails: {
    id: 'wallet.wallet',
    defaultMessage: 'Wallet Details',
  },
  walletHistory: {
    id: 'wallet.history',
    defaultMessage: 'Wallet History',
  },
  user_id: {
    id: 'wallet.user_id',
    defaultMessage: 'User id',
  },
  wallet_id: {
    id: 'wallet.wallet_id',
    defaultMessage: 'Wallet id',
  },
  email: {
    id: 'wallet.email',
    defaultMessage: 'Email',
  },
  balance: {
    id: 'wallet.balance',
    defaultMessage: 'Balance',
  },
  created_at: {
    id: 'wallet.created_at',
    defaultMessage: 'Created at',
  },
  updated_at: {
    id: 'wallet.updated_at',
    defaultMessage: 'Last updated on',
  },
});
