import { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import { blue } from '@mui/material/colors';
import { BANK_ACCOUNT_STATUS } from '~/modules/BankAccounts/constants';
import messages from '../../../../messages';
import { FormattedMessage } from 'v2s-ui-core/app/Integrations/Localization';
import Grid from 'v2s-ui-core/app/components/Grid';
import TextField from 'v2s-ui-core/app/components/Input/TextField';
import Button from 'v2s-ui-core/app/components/Button';
import { useApproveRejectBankAccount } from '../../../../hooks/useApproveRejectBankAccount';
import Link from '@mui/material/Link';
import { ApproveRejectBankAccountProps } from '../../../../types';
import { CloseButton } from '~/components/Actions/CloseButton';
import { useIsMobile } from '~/hooks/useIsMobile';

const ApproveRejectBankAccount = ({
  onCancel,
  recordForApproveReject,
  onConfirmApproveReject,
}: ApproveRejectBankAccountProps) => {
  const { handleChange, handleSubmit, values, errors } =
    useApproveRejectBankAccount({
      onConfirmApproveReject,
      recordForApproveReject,
    });
  const [showDoc, setShowDoc] = useState<boolean>(false);
  const isMobile = useIsMobile();

  return (
    <Dialog
      open={true}
      onClose={onCancel}
      PaperProps={{
        component: 'form',
        sx: {
          width: isMobile ? '100vw' : '636px',
          margin: isMobile ? 1 : 0,
        },
      }}
      fullScreen={false}
    >
      <DialogTitle sx={{ backgroundColor: blue[50] }}>
        {recordForApproveReject.action === BANK_ACCOUNT_STATUS.APPROVED ? (
          <FormattedMessage {...messages.approveModalTitle} />
        ) : (
          <FormattedMessage {...messages.rejectModalTitle} />
        )}
        <CloseButton onClose={onCancel} />
      </DialogTitle>

      <DialogContent sx={{ width: '100%', padding: isMobile ? 2 : 1 }}>
        <DialogContentText ml={2} mt={2}>
          {recordForApproveReject.action === BANK_ACCOUNT_STATUS.APPROVED ? (
            <FormattedMessage {...messages.approveModalHelpText} />
          ) : (
            <FormattedMessage {...messages.rejectModalHelpText} />
          )}
          {/*Show bank docs*/}
          <Link
            sx={{ cursor: 'pointer' }}
            onClick={() => setShowDoc(!showDoc)}
            underline='hover'
          >
            {showDoc ? (
              <FormattedMessage {...messages.clickToClose} />
            ) : (
              <FormattedMessage {...messages.clickToView} />
            )}
          </Link>
          {showDoc && (
            <img
              alt='View Bank Account Document'
              src={
                recordForApproveReject.record.user_document.latest_version
                  .original_doc_location
              }
              width='100%'
              height='400px'
            />
          )}
        </DialogContentText>

        {/* Form Fields */}
        <Grid container spacing={2} p={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              name={'bankAccountNumber'}
              variant='outlined'
              label={<FormattedMessage {...messages.bankAccountNumber} />}
              // @ts-ignore
              value={recordForApproveReject?.record?.bank_account_number}
              fullWidth
              size='small'
              disabled
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              name={'remark'}
              variant='outlined'
              label={<FormattedMessage {...messages.addRemark} />}
              onChange={handleChange}
              // @ts-ignore
              value={values.remark}
              fullWidth
              size='small'
              required
              type='text'
              // @ts-ignore
              error={Boolean(errors.remark)}
              // @ts-ignore
              helperText={errors.remark}
            />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions sx={{ padding: isMobile ? 1 : 0, mb: 2, mr: 3 }}>
        <Button variant='contained' onClick={() => handleSubmit()}>
          {recordForApproveReject.action === BANK_ACCOUNT_STATUS.APPROVED ? (
            <FormattedMessage {...messages.approve} />
          ) : (
            <FormattedMessage {...messages.reject} />
          )}
        </Button>
        <Button variant='outlined' onClick={onCancel}>
          <FormattedMessage {...messages.cancel} />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ApproveRejectBankAccount;
