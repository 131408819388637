import { useMediaQuery } from '@mui/material';
import Box from 'v2s-ui-core/app/components/Box';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import SearchFilter from '../SearchFilter';

const FILTERS = {
  START_DATE: 'start_date',
  END_DATE: 'end_date',
};

const formatDate = (date: any) =>
  date ? dayjs(date).format('YYYY-MM-DD') : '';

const TableFilters = ({
  applyFilters,
  filters,
  moreFiltersNode = <></>,
  includeDateFilters = true,
  includeSearchFilter = true,
}: {
  includeDateFilters?: boolean;
  includeSearchFilter?: boolean;
  applyFilters: (args: { type: string; values: any }) => void;
  filters: Record<string, any>;
  moreFiltersNode?: React.ReactNode;
}) => {
  const isMobile = useMediaQuery('(max-width: 600px)');
  return (
    <div>
      <Box
        display='flex'
        flexDirection={isMobile ? 'column' : 'row'}
        gap={isMobile ? '16px' : '60px'}
      >
        {includeSearchFilter && (
          <SearchFilter applyFilters={applyFilters} value={filters.SEARCH} />
        )}
        {includeDateFilters && (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Box
              display='flex'
              flexDirection={isMobile ? 'row' : 'row'}
              gap={isMobile ? '16px' : '32px'}
            >
              <DatePicker
                label='Start Date'
                value={filters.start_date ? dayjs(filters.start_date) : null}
                onChange={(newValue) => {
                  applyFilters({
                    type: FILTERS.START_DATE,
                    values: formatDate(newValue),
                  });
                }}
                format='YYYY-MM-DD'
                openTo='year'
                views={['year', 'month', 'day']}
                sx={{
                  width: isMobile ? '100%' : '210px',
                }}
                slotProps={{
                  textField: {
                    size: 'small',
                    InputLabelProps: {
                      style: {
                        color: 'gray',
                      },
                    },
                  },
                }}
              />

              <DatePicker
                label='End Date'
                value={filters.end_date ? dayjs(filters.end_date) : null}
                onChange={(newValue) => {
                  if (newValue) {
                    applyFilters({
                      type: FILTERS.END_DATE,
                      values: formatDate(newValue),
                    });
                  }
                }}
                openTo='year'
                format='YYYY-MM-DD'
                views={['year', 'month', 'day']}
                sx={{
                  width: isMobile ? '100%' : '210px',
                }}
                slotProps={{
                  textField: {
                    size: 'small',

                    InputLabelProps: {
                      style: {
                        color: 'gray',
                      },
                    },
                  },
                }}
              />
            </Box>
          </LocalizationProvider>
        )}
        {moreFiltersNode}
      </Box>
    </div>
  );
};

export default TableFilters;
