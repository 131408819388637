/* eslint-disable arrow-body-style */
import React from 'react';
import { Box, Typography, Grid, TextField } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { useFetchData } from '~/hooks/fetchData/useFetchData';
import { WalltetDetailsResponse } from '../types';
import { QUERY_KEYS } from '../constants';
import { FormattedMessage } from '~app/Integrations/Localization';
import messages from '../messages';
const WalletDetails = () => {
  const location = useLocation();

  const { data } = useFetchData<WalltetDetailsResponse>({
    url: `crm/users/${location.state}/wallet/history`,
    queryKey: QUERY_KEYS.FETCH_WALLET_DETAILS,
  });

  return (
    <Box>
      <Typography fontSize={'20px'}>
        <FormattedMessage {...messages.walletDetails} />
      </Typography>
      <Grid container columnSpacing={12} rowSpacing={'24px'} mt={'16px'}>
        <Grid item xs={12} sm={4}>
          <TextField
            label={<FormattedMessage {...messages.user_id} />}
            value={data?.data?.wallet?.user.id || ''}
            disabled
            size='small'
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            label={<FormattedMessage {...messages.wallet_id} />}
            value={data?.data?.wallet.id || ''}
            disabled
            size='small'
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            label={<FormattedMessage {...messages.email} />}
            value={data?.data?.wallet.user.email || ''}
            disabled
            size='small'
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            label={<FormattedMessage {...messages.balance} />}
            value={data?.data?.wallet.balance || ''}
            disabled
            size='small'
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            label={<FormattedMessage {...messages.created_at} />}
            value={
              (data?.data?.wallet.created_at &&
                new Date(data?.data?.wallet.created_at).toLocaleDateString()) ||
              ''
            }
            disabled
            size='small'
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            label={<FormattedMessage {...messages.updated_at} />}
            value={
              (data?.data?.wallet.updated_at &&
                new Date(data?.data?.wallet.updated_at).toLocaleDateString()) ||
              ''
            }
            disabled
            size='small'
            fullWidth
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default WalletDetails;
