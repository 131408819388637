import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import { blue } from '@mui/material/colors';
import { FormattedMessage } from 'v2s-ui-core/app/Integrations/Localization';
import Button from 'v2s-ui-core/app/components/Button';
import { ApproveRejectTradingProps } from '../../../types';
// import messages from '../../../messages';
import { tradingAccountMessages } from '~/modules/Requests/messages';
import { ACCOUNT_STATUS } from '~/modules/Requests/constants';
import { CloseButton } from '~/components/Actions/CloseButton';

const ApproveRejectTradingAccount = ({
  onCancel,
  recordForApproveReject,
  onConfirmApproveReject,
}: //props
ApproveRejectTradingProps) => (
  <Dialog
    open={true}
    onClose={onCancel}
    PaperProps={{
      component: 'form',
      sx: {
        minWidth: '636px',
      },
    }}
  >
    <DialogTitle sx={{ backgroundColor: blue[50] }}>
      {recordForApproveReject.action === ACCOUNT_STATUS.APPROVED ? (
        <FormattedMessage {...tradingAccountMessages.approveModalTitle} />
      ) : (
        <FormattedMessage {...tradingAccountMessages.rejectModalTitle} />
      )}
      <CloseButton onClose={onCancel} />
    </DialogTitle>
    <DialogContent sx={{ width: '636px', padding: 1 }}>
      <DialogContentText ml={2} mt={2}>
        {recordForApproveReject.action === ACCOUNT_STATUS.APPROVED ? (
          <FormattedMessage {...tradingAccountMessages.approveModalHelpText} />
        ) : (
          <FormattedMessage {...tradingAccountMessages.rejectModalHelpText} />
        )}
      </DialogContentText>
    </DialogContent>

    <DialogActions sx={{ padding: 3 }}>
      <Button variant='contained' onClick={() => onConfirmApproveReject()}>
        {recordForApproveReject.action === ACCOUNT_STATUS.APPROVED ? (
          <FormattedMessage {...tradingAccountMessages.approve} />
        ) : (
          <FormattedMessage {...tradingAccountMessages.reject} />
        )}
      </Button>
      <Button onClick={onCancel} variant='outlined'>
        <FormattedMessage {...tradingAccountMessages.cancel} />
      </Button>
    </DialogActions>
  </Dialog>
);

export default ApproveRejectTradingAccount;
