import SearchIcon from '@mui/icons-material/Search';
import { Box, InputAdornment, OutlinedInput } from '@mui/material';
import React from 'react';
import { debounce } from 'v2s-ui-core/app/utils';

interface ApplyFiltersProps {
  applyFilters: (args: { type: typeof FILTERS.SEARCH; values: any }) => void;
  value: any;
}

const FILTERS = {
  SEARCH: 'value', // key for search filter to send to API
};

const SearchFilter: React.FC<ApplyFiltersProps> = ({ applyFilters, value }) => {
  const handleSearch = debounce(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      applyFilters({
        type: FILTERS.SEARCH,
        values: event.target.value,
      });
    },
    500,
  );

  return (
    <Box className='searchInput'>
      <OutlinedInput
        value={value}
        placeholder='Search'
        size='small'
        onChange={handleSearch}
        sx={{
          width: '310px',
        }}
        startAdornment={
          <InputAdornment position='start'>
            <SearchIcon />
          </InputAdornment>
        }
      />
    </Box>
  );
};

export default SearchFilter;
