import * as yup from 'yup';
import { tradingAccountMessages } from '../../messages';

export const QUERY_KEYS = {
  APPROVE_REJECT_TRADING_ACCOUNT: 'fetchNewTradingAccounts',
  FETCH_NEW_TRADING_ACCOUNTS: 'fetchNewTradingAccounts',
  FETCH_LEVERAGE_REQUESTS: 'fetchLeverageRequests',
  PASSWORD_CHANGE_REQUESTS: 'passwordChangeRequests',
  // GX_INJECT_MUTATION_KEY
} as const;

export const getApproveRejectSchema = (formatMessage: Function) =>
  yup.object().shape({
    remark: yup
      .string()
      .required(formatMessage(tradingAccountMessages.remarkRequired)),
  });
