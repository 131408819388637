import { useQuery } from 'v2s-ui-core/app/Integrations/react-query';
import { QUERY_KEYS } from '../services/queries/constants'; // Adjust import path as needed
import { useQueryFactory } from '../services/queries/useQueryFactory'; // Adjust import path as needed // import useQueryFactory from '../services/queries/useQueryFactory'; // Adjust import path as needed
// import { DashboardResponse } from '../services/queries/types';
import { DashboardResponse } from '~/modules/Dashboard/types';

export function useDashboardData() {
  const queryDetails = useQueryFactory();

  const { data, isLoading, isError } = useQuery<DashboardResponse>(
    (queryDetails as any)[QUERY_KEYS.FETCH_DASHBOARD](),
  );

  return { data, isLoading, isError };
}
