import { ColumnDef } from 'v2s-ui-core/app/components/DataGridCore';

export const getNetworkPerformanceTableColumns =
  (props: {}): ColumnDef<unknown>[] => [
    {
      accessorKey: 'login',
      header: 'Login',
      enableColumnFilter: true,
    },
    {
      accessorKey: 'side',
      header: 'Side',
      enableColumnFilter: true,
    },
    {
      accessorKey: 'deal_id',
      header: 'Deal ID',
      enableColumnFilter: true,
    },
    {
      accessorKey: 'position_id',
      header: 'Position ID',
      enableColumnFilter: true,
    },
    {
      accessorKey: 'symbol',
      header: 'Symbol',
      enableColumnFilter: true,
    },
    {
      accessorKey: 'deal_time',
      header: 'Deal Time',
      enableColumnFilter: true,
    },
    {
      accessorKey: 'profit',
      header: 'Profit',
      enableColumnFilter: true,
    },
    {
      accessorKey: 'volume',
      header: 'Volume',
      enableColumnFilter: true,
    },
    {
      accessorKey: 'commission',
      header: 'Commission',
      enableColumnFilter: true,
    },
  ];
