import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material';
import { blue } from '@mui/material/colors';
import {
  FormattedMessage,
  useLocaleContext,
} from 'v2s-ui-core/app/Integrations/Localization';
import useChangePassword from '~/hooks/useChangePassword';
import PasswordInputField from '~/modules/Auth/components/PasswordInputField';
import messages from './messages';

const ChangePasswordModal = ({
  closeChangePasswordModal,
  openChangePasswordModal,
}: {
  closeChangePasswordModal: any;
  openChangePasswordModal: boolean;
}) => {
  const { formik, isPending } = useChangePassword({
    closeChangePasswordModal,
  });

  const {
    messageFormatter: { formatMessage },
  } = useLocaleContext();

  return (
    <>
      <Dialog
        open={openChangePasswordModal}
        PaperProps={{
          component: 'form',
          sx: {
            minWidth: { md: '444px', xs: '356x' },
            maxHeight: { md: 'auto', xs: 'auto' },
          },
        }}
      >
        <DialogTitle
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            height: '48px',
            bgcolor: blue[50],
            p: 2,
          }}
        >
          <Typography
            textAlign={'center'}
            variant='h6'
            sx={{ display: 'flex', alignItems: 'center', height: '100%' }}
          >
            <FormattedMessage {...messages.changePassword} />
          </Typography>
          <IconButton onClick={closeChangePasswordModal}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent>
          <Box pt={2}>
            <Box
              className='formContainer'
              display='flex'
              width={{ md: '80%', sx: '96%' }}
              flexDirection='column'
            >
              <>
                <Box display={'flex'} gap={'34px'}>
                  <PasswordInputField
                    name={'old_password'}
                    variant='outlined'
                    required
                    label={formatMessage(messages.oldPassword)}
                    onChange={formik.handleChange}
                    fullWidth
                    size='small'
                    error={!!formik.errors.old_password}
                    helperText={
                      typeof formik.errors.old_password === 'string'
                        ? formik.errors.old_password
                        : undefined
                    }
                  />
                </Box>
                <Box display={'flex'} gap={'34px'} my={4}>
                  <PasswordInputField
                    name={'new_password'}
                    variant='outlined'
                    required
                    label={formatMessage(messages.newPassword)}
                    onChange={formik.handleChange}
                    fullWidth
                    size='small'
                    error={!!formik.errors.new_password}
                    helperText={
                      typeof formik.errors.new_password === 'string'
                        ? formik.errors.new_password
                        : undefined
                    }
                  />
                </Box>
                <Box display={'flex'} gap={'34px'}>
                  <PasswordInputField
                    name={'confirm_password'}
                    variant='outlined'
                    required
                    label={formatMessage(messages.retypePassword)}
                    onChange={formik.handleChange}
                    fullWidth
                    size='small'
                    error={!!formik.errors.confirm_password}
                    helperText={
                      typeof formik.errors.confirm_password === 'string'
                        ? formik.errors.confirm_password
                        : undefined
                    }
                  />
                </Box>
              </>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Box
            display={{ md: 'flex', xs: 'grid' }}
            justifyContent={'center'}
            p={1}
            pr={{ xs: 7, md: 0 }}
            mr={{ xs: 7, md: 0 }}
          >
            <Button
              variant='contained'
              size='large'
              onClick={() => formik.handleSubmit()}
              disabled={isPending}
            >
              {isPending ? (
                <CircularProgress size={24} color='inherit' />
              ) : (
                <FormattedMessage {...messages.changePasswordBtn} />
              )}
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ChangePasswordModal;
