import React, { useMemo } from 'react';
import DataGridWithQuery from 'v2s-ui-core/app/components/DataGridWithQuery';
import Box from '~app/components/Box';
import { getNetworkPerformanceTableColumns } from './NetworkPerformanceTableColumn';

const NetworkPerformance = ({
  selectedStatement,
}: {
  selectedStatement: string;
}) => {
  const memoizedColumns = useMemo(
    () => getNetworkPerformanceTableColumns({}),
    [],
  );

  return (
    <>
      <Box className='ScrollableTableContainer'>
        <DataGridWithQuery
          queryProps={{
            resource: '/statement',
            queryKeyProp: ['network'],
          }}
          columns={memoizedColumns}
          pageCount={10}
          filters={{
            id: selectedStatement,
          }}
          key={selectedStatement}
        />
      </Box>
    </>
  );
};

export default NetworkPerformance;
