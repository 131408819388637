import { useQuery } from 'v2s-ui-core/app/Integrations/react-query';
import { useQueryFactory } from '../services/queries/useQueryFactory';

const useGetGenealogyHistory = <TData, TError = Error>(
  id: number,
  params?: any,
) => {
  const queryDetails = useQueryFactory<TData, TError>();

  const queryResult = useQuery({
    enabled: !!id,
    ...queryDetails.fetchGenealogy(id, params),
  });

  return queryResult;
};

export default useGetGenealogyHistory;
