export const API_URLS = {
  FETCH_NEW_TRADING_ACCOUNTS: 'crm/trading-accounts',
  FETCH_LEVERAGE_REQUESTS: 'crm/trading-accounts/change-requests',
  PASSWORD_CHANGE_REQUESTS: 'crm/trading-accounts/change-requests',
  FETCH_LEVERAGE_CHANGE_HISTORY: 'crm/trading-accounts/leverage-change-history',

  APPROVE_REJECT_TRADING_ACCOUNT: (id: string | number) =>
    `/crm/trading-accounts/${id}`,

  APPROVE_REJECT_LEVERAGE_CHANGE: (id: string | number) =>
    `/crm/trading-accounts/change-requests/${id}`,

  APPROVE_REJECT_PASSWORD_CHANGE: (id: string | number) =>
    `/crm/trading-accounts/change-requests/${id}`,
  // GX_INJECT_API_URL
};

export const QUERY_KEYS = {
  FETCH_LEVERAGE_CHANGE_HISTORY: 'fetchLeverageHistory',
};
