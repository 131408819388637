import { ColumnDef } from 'v2s-ui-core/app/components/DataGridCore';
import { TransactionHistory } from '../types';

export const WalletHistoryColumn = (): ColumnDef<TransactionHistory>[] => [
  {
    accessorFn: (row) => row.balance,
    header: 'Balance',
    enableColumnFilter: false,
  },
  {
    accessorFn: (row) =>
      row.created_at && new Date(row.created_at).toLocaleDateString(),
    header: 'Created at',
    enableColumnFilter: false,
  },
  {
    accessorFn: (row) => row.id,
    header: 'Wallet ID',
    enableColumnFilter: false,
  },

  {
    accessorFn: (row) => row.user_transaction,
    header: 'Transaction ID',
    enableColumnFilter: false,
  },
];
