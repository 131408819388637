import { defineMessages } from 'v2s-ui-core/app/Integrations/Localization';

export default defineMessages({
  dashboard: {
    id: 'sideMenu.dashboard',
    defaultMessage: 'Dashboard',
  },
  bankAccounts: {
    id: 'sideMenu.bankAccounts',
    defaultMessage: 'Bank Accounts',
  },
  deposits: {
    id: 'sideMenu.deposits',
    defaultMessage: 'Deposits',
  },
  traders: {
    id: 'sideMenu.traders',
    defaultMessage: 'Traders',
  },
  withdrawal: {
    id: 'sideMenu.withdrawal',
    defaultMessage: 'Withdrawal',
  },
  internalTransfers: {
    id: 'sideMenu.internalTransfers',
    defaultMessage: 'Transfers',
  },
  Credit: {
    id: 'sideMenu.Credit',
    defaultMessage: 'Credit',
  },
  creditIn: {
    id: 'sideMenu.creditIn',
    defaultMessage: 'Credit In',
  },
  creditOut: {
    id: 'sideMenu.creditOut',
    defaultMessage: 'Credit Out',
  },
  reports: {
    id: 'sideMenu.reports',
    defaultMessage: 'Reports',
  },
  requests: {
    id: 'sideMenu.requests',
    defaultMessage: 'Requests',
  },
  emailing: {
    id: 'sideMenu.emailing',
    defaultMessage: 'Emailing',
  },
  notifications: {
    id: 'sideMenu.notifications',
    defaultMessage: 'Notifications',
  },
  tradingSettings: {
    id: 'sideMenu.tradingSettings',
    defaultMessage: 'Trading Settings',
  },
  ibManagement: {
    id: 'sideMenu.ibManagement',
    defaultMessage: 'IB Management',
  },
  crmUserManagement: {
    id: 'sideMenu.crmUserManagement',
    defaultMessage: 'CRM User Management',
  },
  userManagement: {
    id: 'sideMenu.userManagement',
    defaultMessage: 'User Management',
  },
  rolesAndPermissions: {
    id: 'sideMenu.rolesAndPermissions',
    defaultMessage: 'Roles & Permissions',
  },
});
