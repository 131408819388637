import { ColumnDef } from 'v2s-ui-core/app/components/DataGridCore';
import Typography from '@mui/material/Typography';

export const getStatementTableColumns = (props: {
  setSelectedStatement: (value: string) => void;
}): ColumnDef<unknown>[] => [
  {
    accessorKey: 'name',
    header: 'Name',
    enableColumnFilter: true,
  },
  {
    accessorKey: 'client_name',
    header: 'Client Login',
    enableColumnFilter: true,
    cell: ({ row }: any) => (
      <Typography
        onClick={() => props.setSelectedStatement(row.original.client_name)}
        style={{
          cursor: 'pointer',
          color: 'blue',
          textDecoration: 'underline',
        }}
      >
        {row.original.client_name}
      </Typography>
    ),
  },
  {
    accessorKey: 'commission',
    header: 'Commission',
    enableColumnFilter: true,
  },
  {
    accessorKey: 'lots_open',
    header: 'Lots Open',
    enableColumnFilter: true,
  },
  {
    accessorKey: 'rebate',
    header: 'Rebate',
    enableColumnFilter: true,
  },
  {
    accessorKey: 'profit_share',
    header: 'Profit Share',
    enableColumnFilter: true,
  },
];
