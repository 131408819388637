import { FC, useMemo } from 'react';
import DataGridWithQuery from 'v2s-ui-core/app/components/DataGridWithQuery';
import { useCrudContext } from 'v2s-ui-core/app/containers/CrudProvider';
import { API_URLS } from '../../api/config';
import { QUERY_KEYS } from '../../services/queries/constants';
import { getUserManagementTableColumns } from './UserManagementTableColumns';
import Box from '~app/components/Box';
import TableFilters from '../../../../components/TableFilters';
import useFilters from '~app/hooks/useFilters';
import { FILTERS } from '~/config/constants';

const UserManagementTable: FC<{}> = () => {
  const { onDeleteRecord, onUpdateRecord } = useCrudContext();
  const { filters, applyFilters } = useFilters({
    filterTypes: FILTERS,
    namespace: QUERY_KEYS.USER_MANAGEMENT,
  });

  const memoizedColumns = useMemo(
    () =>
      getUserManagementTableColumns({
        onDeleteRecord,
        onUpdateRecord,

        onViewRecord: () => {},
      }),
    [onDeleteRecord, onUpdateRecord],
  );

  return (
    <Box>
      <Box className='tableContainer' width={'100%'}>
        <Box display='flex' mb={'32px'}>
          <TableFilters filters={filters} applyFilters={applyFilters} />
        </Box>
      </Box>
      <Box className='ScrollableTableContainer'>
        <DataGridWithQuery
          queryProps={{
            resource: API_URLS.USER_MANAGEMENT,
            queryKeyProp: [QUERY_KEYS.USER_MANAGEMENT],
            select: (data: any) => data.data,
          }}
          columns={memoizedColumns}
          filters={filters}
        />
      </Box>
    </Box>
  );
};

export default UserManagementTable;
