import UserManagements from './containers/UserManagements';

const UserManagement = () => (
  <div
    style={{
      textAlign: 'center',
    }}
  >
    <UserManagements />
  </div>
);

export default UserManagement;
