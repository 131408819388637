import { useFormik } from 'formik';
import { useLocaleContext } from 'v2s-ui-core/app/Integrations/Localization';
import { getApproveRejectSchema } from '../services/mutations/constants';
import { ApproveRejectDepositProps } from '../types';
export const useApproveRejectDepositAccount = ({
  onConfirmApproveReject,
  recordForApproveReject,
}: {
  onConfirmApproveReject: ApproveRejectDepositProps['onConfirmApproveReject'];
  recordForApproveReject: ApproveRejectDepositProps['recordForApproveReject'];
}) => {
  const {
    messageFormatter: { formatMessage },
  } = useLocaleContext();

  const formik = useFormik({
    initialValues: {
      remark: '',
      trading_amount: '',
      trading_currency: recordForApproveReject?.record.currency,
      user: recordForApproveReject?.record.user,
      id: recordForApproveReject?.record.id,
      status: '',
    },

    onSubmit: (values, { resetForm }) => {
      onConfirmApproveReject({
        remark: values.remark,
        ...(recordForApproveReject?.action === 'APPROVED' && {
          trading_amount: values.trading_amount,
          trading_currency: values.trading_currency,
        }),
        user: recordForApproveReject?.record.user,
        id: recordForApproveReject?.record.id,
        status:
          recordForApproveReject?.action === 'APPROVED'
            ? 'APPROVED'
            : 'REJECTED',
      });
      resetForm({
        values: {
          remark: '',
          trading_amount: '',
          trading_currency: '',
          user: '',
          id: '',
          status: '',
        },
      });
    },

    validationSchema: getApproveRejectSchema(
      formatMessage,
      recordForApproveReject?.action,
    ),
    enableReinitialize: true,
    validateOnBlur: false,
    validateOnChange: false,
    validateOnMount: false,
  });

  return formik;
};
