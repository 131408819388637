export const API_URLS = {
  FETCH_KYC: '/crm/traders',
  APPROVE_REJECT_KYC: (id: string | number, status: string) =>
    `user-documents/${id}/${status}`,

  SET_EXPIRY_DATE: (id: string | number) =>
    `user-documents/${id}/set-expire-date`,
  // GX_INJECT_API_URL
};

export const QUERY_KEYS = {
  FETCH_KYC: 'kycDocuments',
  APPROVE_REJECT_KYC: 'approveRejectKyc',
  // GX_INJECT_QUERY_KEY
};
