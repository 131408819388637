import { ColumnDef } from 'v2s-ui-core/app/components/DataGridCore';
import ActionButtons from '~/components/Actions/ActionButtons';
import { ACCOUNT_STATUS, ACCOUNT_STATUS_CONFIG } from '../../constants';
import { AccountStatus } from '../../types';

export const PasswordChangeTableColumns = (props: {
  onApproveRecord?: Function;
  onRejectRecord?: Function;
}): ColumnDef<AccountStatus>[] => [
  {
    accessorFn: (row) => row.trading_account.user?.email,
    header: 'Email',
    enableColumnFilter: false,
  },
  {
    accessorFn: (row) => row.trading_account.account_type,
    header: 'Account Type',
    enableColumnFilter: false,
  },
  {
    accessorKey: 'requested_date_time',
    header: 'Requested At',
    enableColumnFilter: false,
  },
  {
    accessorKey: 'status',
    header: 'Status',
    enableColumnFilter: false,
  },
  {
    accessorKey: 'actions',
    header: 'Actions',
    enableColumnFilter: false,
    cell: ({ cell }) => {
      const { row } = cell;
      const status = row.original.status;

      return (
        <ActionButtons
          status={status}
          onApproveRecord={props?.onApproveRecord}
          onRejectRecord={props?.onRejectRecord}
          rowData={row.original}
          showViewButton={false}
          ACCOUNT_STATUS={ACCOUNT_STATUS}
          ACCOUNT_STATUS_CONFIG={ACCOUNT_STATUS_CONFIG}
        />
      );
    },
  },
];
