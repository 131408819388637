/* eslint-disable arrow-body-style */
import { Box } from '@mui/material';
import DataGridWithQuery from 'v2s-ui-core/app/components/DataGridWithQuery';
import { TradingAccountTableColumn } from './componets/TradingAccountTableColumn';
import { API_URLS } from './api/config';
import { useLocation } from 'react-router-dom';
import { ColumnDef } from '~app/components/DataGridCore';
import TableFilters from '../../../../components/TableFilters';
import useFilters from '~app/hooks/useFilters';
import { FILTERS } from '~/config/constants';

const TradingAccount = () => {
  const location = useLocation();

  const { filters, applyFilters } = useFilters({
    filterTypes: FILTERS,
    namespace: 'fetchTradingAccounts',
  });

  return (
    <Box>
      <Box className='tableContainer' width={'100%'}>
        <Box display='flex' mb={'26px'}>
          <TableFilters filters={filters} applyFilters={applyFilters} />
        </Box>
      </Box>
      <Box className='ScrollableTableContainer'>
        <DataGridWithQuery
          columns={TradingAccountTableColumn as ColumnDef<unknown>[]}
          queryProps={{
            resource: API_URLS.FETCH_TRADING_ACCOUNT(location.state),
            queryKeyProp: ['fetchTradingAccounts'],
            select: (data: any) => data?.data,
            refetchInterval: 5000,
          }}
          filters={filters}
        />
      </Box>
    </Box>
  );
};

export default TradingAccount;
