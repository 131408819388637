import { FC, useMemo, useState } from 'react';
import DataGridWithQuery from 'v2s-ui-core/app/components/DataGridWithQuery';
import { API_URLS } from '../../api/config';
import { QUERY_KEYS } from '../../services/queries/constants';
import { getBankAccountsTableColumns } from './BankAccountsTableColumns';
import ApproveRejectBankAccount from './components/ApproveRejectBankAccount';
import { useApproveRejectActions } from 'src/hooks/ApproveRejectCustomHook/useApproveRejectActions';
import { BANK_ACCOUNT_STATUS } from '../../constants';
import ViewBankAccountForm from './components/ApproveRejectBankAccount/ViewBankAccount/ViewBankAccountForm';
import messages from '../../messages';
import SuccessModal from '~/components/SuccessModal';
import Box from '~app/components/Box';
import TableFilters from '../../../../components/TableFilters';
import useFilters from '~app/hooks/useFilters';
import { FILTERS } from '~/config/constants';

const BankAccountsTable: FC<{}> = () => {
  const [isOpenViewBankAccountForm, setIsOpenViewBankAccountForm] =
    useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);

  const { filters, applyFilters } = useFilters({
    filterTypes: FILTERS,
    namespace: QUERY_KEYS.FETCH_BANK_ACCOUNTS,
  });

  const {
    recordForApproveReject,
    onApproveRecord,
    onRejectRecord,
    onCancel,
    onConfirmApproveReject,
    onSuccessClose,
    successMessage,
    showSuccessModal,
  } = useApproveRejectActions({
    QUERY_KEYS: QUERY_KEYS.FETCH_BANK_ACCOUNTS,
    API_URLS: API_URLS.APPROVE_REJECT_BANK_ACCOUNT,
    STATUS: BANK_ACCOUNT_STATUS,
    user: false,
    messages: messages,
  });

  const memoizedColumns = useMemo(
    () =>
      getBankAccountsTableColumns({
        onViewRecord: (record: object) => {
          setSelectedRecord(record);
          setIsOpenViewBankAccountForm(true);
        },
        onApproveRecord,
        onRejectRecord,
      }),
    [onApproveRecord, onRejectRecord],
  );

  return (
    <>
      <Box className='tableContainer' width={'100%'}>
        <Box display='flex' mb={'26px'}>
          <TableFilters filters={filters} applyFilters={applyFilters} />
        </Box>
      </Box>
      <Box className='ScrollableTableContainer'>
        <DataGridWithQuery
          queryProps={{
            resource: API_URLS.FETCH_BANK_ACCOUNTS,
            queryKeyProp: [QUERY_KEYS.FETCH_BANK_ACCOUNTS],
            select: (data: any) => data?.data,
          }}
          columns={memoizedColumns}
          pageCount={10}
          filters={filters}
        />
      </Box>
      {isOpenViewBankAccountForm && (
        <ViewBankAccountForm
          open={isOpenViewBankAccountForm}
          onClose={() => setIsOpenViewBankAccountForm(false)}
          record={selectedRecord}
        />
      )}
      {recordForApproveReject && (
        <ApproveRejectBankAccount
          onCancel={onCancel}
          recordForApproveReject={recordForApproveReject}
          onConfirmApproveReject={onConfirmApproveReject}
        />
      )}
      <SuccessModal
        open={showSuccessModal}
        onClose={onSuccessClose}
        message={successMessage}
      />
    </>
  );
};

export default BankAccountsTable;
