import { useMemo } from 'react';
import { useQuery } from 'v2s-ui-core/app/Integrations/react-query';
import { get } from 'v2s-ui-core/app/Integrations/ApiService';

const ApiServices = {
  fetchData: async <T>(url: string) => {
    try {
      const response = await get<T>(url);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
};

export const useFetchData = <TData, TError = Error>(props: {
  url: string;
  queryKey: string;
}) => {
  const { url, queryKey } = props;

  const queryDetails = useMemo(
    () => ({
      queryKey: [queryKey],
      queryFn: () => ApiServices.fetchData<TData>(url),
    }),
    [queryKey, url],
  );

  const { data, isLoading, error } = useQuery<TData, TError>(queryDetails);

  return {
    data,
    isLoading,
    error,
  };
};
