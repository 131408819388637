import { useFormik } from 'formik';
import { useLocaleContext } from 'v2s-ui-core/app/Integrations/Localization';
import { getApproveRejectSchema } from '~/modules/BankAccounts/services/mutations/constants';
import { ApproveRejectKycProps } from '../types';
import { useState } from 'react';

export const useApproveRejectKyc = ({
  onConfirmApproveReject,
  recordForApproveReject,
}: {
  onConfirmApproveReject: ApproveRejectKycProps['onConfirmApproveReject'];
  recordForApproveReject: ApproveRejectKycProps['recordForApproveReject'];
}) => {
  const {
    messageFormatter: { formatMessage },
  } = useLocaleContext();

  const [status, setStatus] = useState('');

  const formik = useFormik({
    initialValues: { remark: '' },
    onSubmit: (values, { resetForm }) => {
      onConfirmApproveReject({
        remark: values.remark,
        id: recordForApproveReject?.record.id,
        status,
      });
      resetForm({ values: { remark: '' } });
    },
    validationSchema: getApproveRejectSchema(formatMessage),
    enableReinitialize: true,
    validateOnBlur: false,
    validateOnChange: false,
    validateOnMount: false,
  });

  return { formik, setStatus };
};
