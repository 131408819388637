import * as yup from 'yup';
import messages from '../../messages';

export const USER_MANAGEMENT_FORM_KEYS = {
  FIRST_NAME: 'first_name',
  LAST_NAME: 'last_name',
  EMAIL: 'email',
  PASSWORD1: 'password1',
  PASSWORD2: 'password2',
  ROLE: 'role',
  IS_2FA: 'is_2fa',
} as const;

export const USER_MANAGEMENT_FORM_VALUES = {};

export const getUserManagementFormSchema = (
  isUpdate: boolean,
  formatMessage: Function,
) =>
  yup.object().shape({
    [USER_MANAGEMENT_FORM_KEYS.FIRST_NAME]: yup
      .string()
      .required(formatMessage(messages.firstNameRequired)),

    [USER_MANAGEMENT_FORM_KEYS.LAST_NAME]: yup
      .string()
      .required(formatMessage(messages.lastNameRequired)),

    [USER_MANAGEMENT_FORM_KEYS.EMAIL]: yup
      .string()
      .email(formatMessage(messages.invalidEmail))
      .required(formatMessage(messages.emailRequired)),

    [USER_MANAGEMENT_FORM_KEYS.PASSWORD1]: isUpdate
      ? yup.string().notRequired()
      : yup.string().required(formatMessage(messages.passwordRequired)),

    [USER_MANAGEMENT_FORM_KEYS.PASSWORD2]: isUpdate
      ? yup.string().notRequired()
      : yup
          .string()
          .when(USER_MANAGEMENT_FORM_KEYS.PASSWORD1, (password1, schema) => {
            if (password1) {
              return schema
                .required(formatMessage(messages.passwordRequired1))
                .oneOf(
                  [yup.ref(USER_MANAGEMENT_FORM_KEYS.PASSWORD1)],
                  formatMessage(messages.passwordMatch),
                );
            }
            return schema.notRequired();
          }),

    [USER_MANAGEMENT_FORM_KEYS.ROLE]: yup
      .object()
      .required(formatMessage(messages.roleRequired)),
  });
