import messages from './messages';
import Deposit from './Deposit/index';
import Withdrawal from './Withdrawal/index';
import TransfersTable from './Transfer';

export const TRANSACTION_TYPE = {
  DEPOSIT: 'DEPOSIT',
  WITHDRAWAL: 'WITHDRAWAL',
  TRANSFER: 'TRANSFER',
};

export const TRANSACTION_TABS = [
  {
    id: 1,
    label: messages.deposit,
    component: <Deposit />,
    key: TRANSACTION_TYPE.DEPOSIT,
  },
  {
    id: 2,
    label: messages.withdrawal,
    component: <Withdrawal />,
    key: TRANSACTION_TYPE.WITHDRAWAL,
  },
  {
    id: 3,
    label: messages.transfer,
    component: <TransfersTable />,
    key: TRANSACTION_TYPE.TRANSFER,
  },
];
