import React from 'react';
import { Box, Tabs, Tab } from '@mui/material';
import { PROFILE_TABS } from './constants';
import { FormattedMessage } from '~app/Integrations/Localization';

const ProfileTabs = () => {
  const [selectedProfileTab, setSelectedProfileTab] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedProfileTab(newValue);
  };

  return (
    <Box>
      <Tabs
        value={selectedProfileTab}
        onChange={handleChange}
        sx={{
          borderBottom: 1,
          borderColor: 'divider',
          marginX: '20px',
        }}
      >
        {PROFILE_TABS.map((tab, index) => (
          <Tab key={index} label={<FormattedMessage {...tab.label} />} />
        ))}
      </Tabs>
      <Box sx={{ padding: 2 }}>
        {PROFILE_TABS[selectedProfileTab]?.component}
      </Box>
    </Box>
  );
};

export default ProfileTabs;
