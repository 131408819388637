import messages from './messages';

// status from api
export const BANK_ACCOUNT_STATUS = {
  PENDING: 'PENDING',
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED',
};

export const BANK_ACCOUNT_STATUS_CONFIG = {
  [BANK_ACCOUNT_STATUS.PENDING]: {
    label: messages.pending,
    color: 'secondary',
  },
  [BANK_ACCOUNT_STATUS.APPROVED]: {
    label: messages.approved,
    color: 'success',
  },
  [BANK_ACCOUNT_STATUS.REJECTED]: { label: messages.rejected, color: 'error' },
};
