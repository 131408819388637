import { styled } from '@mui/material';
import Box from 'v2s-ui-core/app/components/Box';

export const StyledPublicLayout = styled(Box)`
  height: 100%;
`;

export const StyledProtectedLayout = styled(Box)`
  height: 100%;
  .content {
    height: calc(100% - 64px);
    .content-body {
      background-color: #d9ebfc;
      margin-top: 64px;
      padding: 24px;
      height: 100%;
      overflow: auto;
      @media (max-width: 600px) {
        padding: 10px;
      }
    }
  }
`;
