import { FC, useMemo, useState } from 'react';
import DataGridWithQuery from 'v2s-ui-core/app/components/DataGridWithQuery';
import { API_URLS } from '../../api/config';
import { QUERY_KEYS } from '../../services/queries/constants';
import { LeverageChangeTableColumns } from './LeverageChangeTableColumns';
import { ColumnDef } from 'v2s-ui-core/app/components/DataGridCore';
import ApproveRejectLeverageAccount from './ApproveRejectLeverageChange';
import { useApproveRejectActions } from 'src/hooks/ApproveRejectCustomHook/useApproveRejectActions';
import { ACCOUNT_STATUS } from '../../constants';
import { leverageChangeMessages } from '../../messages';
import SuccessModal from '~/components/SuccessModal';
import LeverageHistoryModal from './LeverageHistory/LeverageHistoryModal';
import TableFilters from '~/components/TableFilters';
import Box from '~app/components/Box';
import useFilters from 'v2s-ui-core/app/hooks/useFilters';
import { FILTERS } from '~/config/constants';
const LeverageChangeTable: FC = () => {
  const [viewLeverage, setViewLeverage] = useState(null);
  const {
    recordForApproveReject,
    onApproveRecord,
    onRejectRecord,
    onCancel,
    onConfirmApproveReject,
    onSuccessClose,
    successMessage,
    showSuccessModal,
  } = useApproveRejectActions({
    QUERY_KEYS: QUERY_KEYS.FETCH_LEVERAGE_REQUESTS,
    API_URLS: API_URLS.APPROVE_REJECT_LEVERAGE_CHANGE,
    STATUS: ACCOUNT_STATUS,
    messages: leverageChangeMessages,
  });

  const { filters, applyFilters } = useFilters({
    filterTypes: FILTERS,
    namespace: 'fetchLeverageRequests',
  });

  const handleViewLeverage = (id: number | string) => {
    setViewLeverage(id);
  };

  const memoizedColumns = useMemo(
    () =>
      LeverageChangeTableColumns({
        onApproveRecord,
        onRejectRecord,
        handleViewLeverage,
      }),
    [onApproveRecord, onRejectRecord],
  );
  return (
    <>
      <Box className='tableContainer' width={'100%'}>
        <Box display='flex' mb={'26px'}>
          <TableFilters filters={filters} applyFilters={applyFilters} />
        </Box>
      </Box>
      <Box className='ScrollableTableContainer'>
        <DataGridWithQuery
          queryProps={{
            resource: API_URLS.FETCH_LEVERAGE_REQUESTS,
            queryKeyProp: [QUERY_KEYS.FETCH_LEVERAGE_REQUESTS],
            select: (data: any) => data.data,
          }}
          columns={memoizedColumns as ColumnDef<unknown>[]}
          filters={{ change_type: 'LEVERAGE', ...filters }}
        />
      </Box>
      {recordForApproveReject && (
        <ApproveRejectLeverageAccount
          onCancel={onCancel}
          onConfirmApproveReject={onConfirmApproveReject}
          recordForApproveReject={recordForApproveReject}
        />
      )}

      <SuccessModal
        open={showSuccessModal}
        onClose={onSuccessClose}
        message={successMessage}
      />
      {viewLeverage && (
        <LeverageHistoryModal
          id={viewLeverage}
          onCancel={() => setViewLeverage(null)}
        />
      )}
    </>
  );
};

export default LeverageChangeTable;
