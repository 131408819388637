import { ColumnDef } from 'v2s-ui-core/app/components/DataGridCore';
import { WITHDRAWALS_STATUS, WITHDRAWALS_STATUS_CONFIG } from '../../constants';
import ActionButtons from '~/components/Actions/ActionButtons';
import { WithdrawalStatus } from '../../types';

export const WithdrawalTableColumns = (props: {
  onApproveRecord?: Function;
  onRejectRecord?: Function;
}): ColumnDef<WithdrawalStatus>[] => [
  {
    accessorKey: 'trading_account',
    header: 'Trading Account',
    enableColumnFilter: false,
  },
  {
    accessorKey: 'payment_method',
    header: 'Payment Method',
    enableColumnFilter: false,
  },
  {
    accessorKey: 'additional_reference',
    header: 'Reference',
    enableColumnFilter: false,
  },
  {
    accessorKey: 'amount',
    header: 'Amount',
    enableColumnFilter: false,
  },
  {
    accessorKey: 'currency',
    header: 'Currency',
    enableColumnFilter: false,
  },
  {
    accessorKey: 'requested_date',
    header: 'Requested Date',
    enableColumnFilter: false,
  },
  {
    accessorKey: 'approved_date',
    header: 'Approved Date',
    enableColumnFilter: false,
  },
  {
    accessorKey: 'status',
    header: 'Status',
    enableColumnFilter: false,
  },
  {
    accessorKey: 'actions',
    header: 'Actions',
    enableColumnFilter: false,
    cell: ({ row }) => {
      const status = row.original.status;

      return (
        <ActionButtons
          status={status}
          onApproveRecord={props?.onApproveRecord}
          onRejectRecord={props?.onRejectRecord}
          rowData={row.original}
          showViewButton={false}
          ACCOUNT_STATUS={WITHDRAWALS_STATUS}
          ACCOUNT_STATUS_CONFIG={WITHDRAWALS_STATUS_CONFIG}
        />
      );
    },
  },
];
