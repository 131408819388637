import { ColumnDef } from 'v2s-ui-core/app/components/DataGridCore';
import { TraderRes } from '../../types';
import { Link } from 'react-router-dom';
import APPLICATION_URLS from '~/shell/Routing/config/appsConfig';
import { formatDate } from '~/utils/FormateDate';

export const getTradersTableColumns = (): ColumnDef<TraderRes>[] => [
  {
    header: 'ID',
    enableColumnFilter: true,
    accessorKey: 'user.id',
  },
  {
    header: 'Registration Date',
    enableColumnFilter: true,
    accessorKey: 'created_at',
  },
  {
    header: 'Name',
    enableColumnFilter: true,
    accessorKey: 'user.full_name',
  },
  {
    header: 'Email',
    enableColumnFilter: true,
    accessorKey: 'user.email',
    cell: ({ row }) => (
      <Link state={row.original?.user.id} to={APPLICATION_URLS?.USER_PROFILE}>
        {row.original.user?.email}
      </Link>
    ),
  },
  {
    header: 'Country',
    enableColumnFilter: true,
    accessorKey: 'user.country',
  },
  {
    header: 'Phone',
    enableColumnFilter: true,
    accessorKey: 'user.phone_number',
  },
  {
    header: 'IB',
    enableColumnFilter: true,
    accessorKey: 'user.user_type.type',
  },
  {
    header: 'KYC Status',
    enableColumnFilter: true,
    accessorKey: 'kyc_status',
  },
  {
    header: 'Crm Status',
    enableColumnFilter: true,
    accessorKey: 'crm_status',
  },
  {
    header: 'Referral',
    enableColumnFilter: true,
    accessorKey: 'user.referral_user.full_name',
    cell: ({ row }) =>
      row?.original?.user?.referral_user &&
      row?.original?.user?.referral_user?.full_name,
  },
];
