import { ColumnDef } from 'v2s-ui-core/app/components/DataGridCore';
import { DEPOSIT_STATUS, DEPOSIT_STATUS_CONFIG } from '../../constants';
import { DepositStatus } from '~/modules/Deposits/types';
import ActionButtons from '~/components/Actions/ActionButtons';

export const DepositsTableColumns = (props: {
  onApproveRecord?: Function;
  onRejectRecord?: Function;
}): ColumnDef<DepositStatus>[] => [
  {
    accessorKey: 'trading_account',
    header: 'Trading Account',
    enableColumnFilter: false,
  },
  {
    accessorKey: 'payment_method',
    header: 'Payment Method',
    enableColumnFilter: false,
  },
  {
    accessorKey: 'additional_reference',
    header: 'Reference',
    enableColumnFilter: false,
  },
  {
    accessorKey: 'amount',
    header: 'Amount',
    enableColumnFilter: false,
  },
  {
    accessorKey: 'currency',
    header: 'Currency',
    enableColumnFilter: false,
  },
  {
    accessorKey: 'requested_date',
    header: 'Requested Date',
    enableColumnFilter: false,
  },
  {
    accessorKey: 'approved_date',
    header: 'Approved Date',
    enableColumnFilter: false,
  },
  {
    accessorKey: 'status',
    header: 'Status',
    enableColumnFilter: false,
  },
  {
    accessorKey: 'actions',
    header: 'Actions',
    enableColumnFilter: false,
    cell: ({ cell }) => {
      const { row } = cell;
      const status = row.original.status;

      return (
        <ActionButtons
          status={status}
          onApproveRecord={props?.onApproveRecord}
          onRejectRecord={props?.onRejectRecord}
          rowData={row.original}
          showViewButton={false}
          ACCOUNT_STATUS={DEPOSIT_STATUS}
          ACCOUNT_STATUS_CONFIG={DEPOSIT_STATUS_CONFIG}
        />
      );
    },
  },
];
