import { FC, useMemo, useState } from 'react';
import DataGridWithQuery from 'v2s-ui-core/app/components/DataGridWithQuery';
import { API_URLS } from '../../api/config';
import { QUERY_KEYS } from '../../services/queries/constants';
import { getBankAccountsTableColumns } from './BankAccountsTableColumns';
import ApproveRejectBankAccount from './components/ApproveRejectBankAccount';
import { useApproveRejectActions } from 'src/hooks/ApproveRejectCustomHook/useApproveRejectActions';
import { BANK_ACCOUNT_STATUS } from '../../constants';
import ViewBankAccountForm from './components/ApproveRejectBankAccount/ViewBankAccount/ViewBankAccountForm';
import messages from '../../messages';

const BankAccountsTable: FC<{}> = () => {
  const {
    recordForApproveReject,
    onApproveRecord,
    onRejectRecord,
    onCancel,
    onConfirmApproveReject,
  } = useApproveRejectActions({
    QUERY_KEYS: QUERY_KEYS.FETCH_BANK_ACCOUNTS,
    API_URLS: API_URLS.APPROVE_REJECT_BANK_ACCOUNT,
    STATUS: BANK_ACCOUNT_STATUS,
    user: false,
    messages: messages,
  });

  const [isOpenViewBankAccountForm, setIsOpenViewBankAccountForm] =
    useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);

  const memoizedColumns = useMemo(
    () =>
      getBankAccountsTableColumns({
        onViewRecord: (record: object) => {
          setSelectedRecord(record);
          setIsOpenViewBankAccountForm(true);
        },
        onApproveRecord,
        onRejectRecord,
      }),
    [onApproveRecord, onRejectRecord],
  );

  return (
    <>
      <DataGridWithQuery
        queryProps={{
          resource: API_URLS.FETCH_BANK_ACCOUNTS,
          queryKeyProp: [QUERY_KEYS.FETCH_BANK_ACCOUNTS],
          select: (data: any) => data?.data,
          refetchInterval: 5000,
        }}
        columns={memoizedColumns}
      />
      {recordForApproveReject && (
        <ApproveRejectBankAccount
          onCancel={onCancel}
          recordForApproveReject={recordForApproveReject}
          onConfirmApproveReject={onConfirmApproveReject}
        />
      )}
      {isOpenViewBankAccountForm && (
        <ViewBankAccountForm
          open={isOpenViewBankAccountForm}
          onClose={() => setIsOpenViewBankAccountForm(false)}
          record={selectedRecord}
        />
      )}
    </>
  );
};

export default BankAccountsTable;
