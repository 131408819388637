import { Route, Routes } from 'react-router-dom';
import { SUB_ROUTES_FOR_REQUESTS_MAP } from './constants';

const Requests = () => (
  <Routes>
    {SUB_ROUTES_FOR_REQUESTS_MAP.map((route) => (
      <Route
        key={route.path}
        path={route.path}
        element={<route.element {...route.AppBaseLayoutProps} />}
      />
    ))}
  </Routes>
);

export default Requests;
