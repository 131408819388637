import { ColumnDef } from 'v2s-ui-core/app/components/DataGridCore';
import ActionButtons from '~/components/Actions/ActionButtons';

import {
  BANK_ACCOUNT_STATUS,
  BANK_ACCOUNT_STATUS_CONFIG,
} from '../../constants';

export const getBankAccountsTableColumns = (props: {
  onViewRecord?: Function;
  onApproveRecord?: Function;
  onRejectRecord?: Function;
  showViewButton?: boolean;
}): ColumnDef<unknown>[] => [
  {
    accessorKey: 'id',
    header: 'Case ID',
    enableColumnFilter: true,
  },
  {
    accessorKey: 'created_by.id',
    header: 'User ID',
    enableColumnFilter: true,
  },
  {
    accessorKey: 'created_by.full_name',
    header: 'User Name',
    enableColumnFilter: true,
  },
  {
    accessorKey: 'created_by.email',
    header: 'User Email',
    enableColumnFilter: true,
  },
  {
    accessorKey: 'bank_name',
    header: 'Bank Name',
    enableColumnFilter: true,
  },
  {
    accessorKey: 'bank_account_name',
    header: 'Account Name',
    enableColumnFilter: true,
  },
  {
    accessorKey: 'bank_account_number',
    header: 'Account Number',
    enableColumnFilter: true,
  },
  {
    accessorKey: 'bank_iban',
    header: 'Bank IBAN',
    enableColumnFilter: true,
  },
  {
    accessorKey: 'bank_code',
    header: 'Bank Code',
    enableColumnFilter: true,
  },
  {
    accessorKey: 'bank_address',
    header: 'Bank Address',
    enableColumnFilter: true,
  },
  {
    accessorKey: 'created_at',
    header: 'Created At',
    enableColumnFilter: true,
  },
  {
    accessorKey: 'updated_at',
    header: 'Updated At',
    enableColumnFilter: true,
  },
  {
    accessorKey: 'created_by.email',
    header: 'Created By',
    enableColumnFilter: true,
  },
  {
    accessorKey: 'updated_by.email',
    header: 'Updated By',
    enableColumnFilter: true,
  },
  {
    accessorKey: 'status',
    header: 'Actions',
    cell: (cell) => {
      const { getValue, row } = cell;
      const status = getValue() as string;

      return (
        <ActionButtons
          status={status}
          onViewRecord={props?.onViewRecord}
          onApproveRecord={props?.onApproveRecord}
          onRejectRecord={props?.onRejectRecord}
          rowData={row.original as object}
          showViewButton={true}
          ACCOUNT_STATUS={BANK_ACCOUNT_STATUS}
          ACCOUNT_STATUS_CONFIG={BANK_ACCOUNT_STATUS_CONFIG}
        />
      );
    },
    enableSorting: false,
  },
];
