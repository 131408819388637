import * as yup from 'yup';
import messages from './validationMessage';
import { INPUT_FIELD_REGEX, INPUT_FIELDS_LENGTHS } from '~/config/constants';
// export const getBanAccountSchema = (formatMessage: (msg: any) => string) =>
//   yup.object().required(formatMessage(messages.required));

export const getPasswordSchema = (formatMessage: (msg: any) => string) =>
  yup.string().required(formatMessage(messages.passwordRequired));

export const getOldPasswordSchema = (formatMessage: (msg: any) => string) =>
  yup.string().required(formatMessage(messages.oldPasswordRequired));

export const getEmailSchema = (formatMessage: (msg: any) => string) =>
  yup
    .string()
    .max(
      INPUT_FIELDS_LENGTHS.EMAIL.MAX,
      formatMessage(messages.lastNameTooLong),
    )
    .email(formatMessage(messages.invalidEmail))
    .required(formatMessage(messages.emailRequired))
    .matches(INPUT_FIELD_REGEX.EMAIL, formatMessage(messages.invalidEmail));
