import { useEffect, useState } from 'react';
import { useCrudContext } from 'v2s-ui-core/app/containers/CrudProvider';
import { isEmpty } from 'v2s-ui-core/app/utils';

export const useAddUserModal = () => {
  const [showAddUserModal, setShowAddUserModal] = useState(false);

  const { recordToUpdate, onUpdateRecord } = useCrudContext();

  useEffect(() => {
    if (!isEmpty(recordToUpdate)) {
      setShowAddUserModal(true);
    } else {
      setShowAddUserModal(false);
    }
  }, [onUpdateRecord, recordToUpdate]);

  const onCancel = () => {
    setShowAddUserModal((prev) => !prev);
    setTimeout(() => {
      onUpdateRecord(null);
    }, 300); // Delay to ensure modal close action completes
  };

  return {
    showAddUserModal,
    setShowAddUserModal,
    onCancel,
    isEditMode: !isEmpty(recordToUpdate),
  };
};
