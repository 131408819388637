import { ColumnDef } from 'v2s-ui-core/app/components/DataGridCore';

export const LeverageHistoryTableColumns =
  (props: {}): ColumnDef<unknown>[] => [
    {
      accessorKey: 'id',
      header: 'ID',
      enableColumnFilter: true,
    },
    {
      accessorKey: 'approved_at',
      header: 'Approved Date',
      enableColumnFilter: true,
    },
    {
      accessorKey: 'prev_leverage',
      header: 'Previous Leverage',
      enableColumnFilter: true,
    },
    {
      accessorKey: 'new_leverage',
      header: 'New Leverage',
      enableColumnFilter: true,
    },
  ];
