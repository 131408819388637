import { ColumnDef } from 'v2s-ui-core/app/components/DataGridCore';
import { TradingAccountDataType } from '../types';

export const TradingAccountTableColumn: ColumnDef<TradingAccountDataType>[] = [
  {
    accessorKey: 'credit',
    header: 'Account Number',
    enableColumnFilter: false,
  },
  {
    accessorKey: 'trading_account',
    header: 'Account ID',
    enableColumnFilter: false,
  },
  {
    cell: ({ row }) => row?.original?.user?.id,
    header: 'User ID',
    enableColumnFilter: false,
  },
  {
    accessorKey: 'leverage',
    header: 'Wallet Account',
    enableColumnFilter: false,
  },
  {
    accessorKey: 'currency',
    header: 'Currency',
    enableColumnFilter: false,
  },
  {
    accessorKey: 'balance',
    header: 'Balance',
    enableColumnFilter: false,
  },
  {
    accessorKey: 'status',
    header: 'Status',
    enableColumnFilter: false,
  },
];
