import { FC, useMemo } from 'react';
import DataGridWithQuery from 'v2s-ui-core/app/components/DataGridWithQuery';
import { API_URLS } from '../../api/config';
import { QUERY_KEYS } from '../../services/queries/constants';
import { DepositsTableColumns } from './DepositsTableColumns';
import ApproveRejectDeposit from './ApproveRejectDeposit/index';
import { DEPOSIT_TRASCATION_TYPE, DEPOSIT_STATUS } from '../../constants';
import { useApproveRejectActions } from 'src/hooks/ApproveRejectCustomHook/useApproveRejectActions';
import messages from '../../messages';
import { ColumnDef } from 'v2s-ui-core/app/components/DataGridCore';

const DepositsTable: FC<{}> = () => {
  const {
    recordForApproveReject,
    onApproveRecord,
    onRejectRecord,
    onCancel,
    onConfirmApproveReject,
  } = useApproveRejectActions({
    QUERY_KEYS: QUERY_KEYS.FETCH_DEPOSITS,
    API_URLS: API_URLS.APPROVE_REJECT_DEPOSIT,
    STATUS: DEPOSIT_STATUS,
    user: true,
    messages: messages,
  });

  const memoizedColumns = useMemo(
    () =>
      DepositsTableColumns({
        onApproveRecord,
        onRejectRecord,
      }),
    [],
  );

  return (
    <>
      <DataGridWithQuery
        queryProps={{
          resource: API_URLS.FETCH_DEPOSITS,
          queryKeyProp: [QUERY_KEYS.FETCH_DEPOSITS],
          select: (data: any) => data?.data,
          refetchInterval: 5000,
        }}
        columns={memoizedColumns as ColumnDef<unknown>[]}
        filters={{ transaction_type: DEPOSIT_TRASCATION_TYPE.DEPOSIT }}
      />
      {recordForApproveReject && (
        <ApproveRejectDeposit
          onCancel={onCancel}
          recordForApproveReject={recordForApproveReject}
          onConfirmApproveReject={onConfirmApproveReject}
        />
      )}
    </>
  );
};

export default DepositsTable;
