import { defineMessages } from 'v2s-ui-core/app/Integrations/Localization';

export default defineMessages({
  userManagement: {
    id: 'userManagement',
    defaultMessage: 'User Management',
  },
  // MARK: delete user
  deleteUser: {
    id: 'userManagement.deleteUser',
    defaultMessage: 'Delete user',
  },
  deleteUserConfirmationText: {
    id: 'userManagement.deleteUserConfirmationText',
    defaultMessage: 'Are you sure you want to delete this user?',
  },
  yes: {
    id: 'userManagement.yes',
    defaultMessage: 'Yes',
  },
  cancel: {
    id: 'userManagement.cancel',
    defaultMessage: 'Cancel',
  },
  // MARK: add user
  addUser: {
    id: 'userManagement.addUser',
    defaultMessage: 'Add Users',
  },
  updateUser: {
    id: 'userManagement.updateUser',
    defaultMessage: 'Update Users',
  },
  addUserHelpText: {
    id: 'userManagement.addUserHelpText',
    defaultMessage: 'Fill in the below details to add a new user',
  },
  // MARK: User fields
  // for Form and table headers
  firstName: {
    id: 'userManagement.firstName',
    defaultMessage: 'First name',
  },
  lastName: {
    id: 'userManagement.lastName',
    defaultMessage: 'Last name',
  },
  email: {
    id: 'userManagement.email',
    defaultMessage: 'Email',
  },
  password: {
    id: 'userManagement.password',
    defaultMessage: 'Password',
  },
  reEnterPassword: {
    id: 'userManagement.password',
    defaultMessage: 'Re-enter password',
  },
  enforce2fa: {
    id: 'userManagement.enforce2fa',
    defaultMessage: 'Enforce 2fa',
  },
  selectRole: {
    id: 'userManagement.selectRole',
    defaultMessage: 'Select role',
  },
  add: {
    id: 'userManagement.add',
    defaultMessage: 'Add',
  },
  update: {
    id: 'userManagement.update',
    defaultMessage: 'Update',
  },
  // MARK: validation messages
  firstNameRequired: {
    id: 'userManagement.firstNameRequired',
    defaultMessage: 'First name is required',
  },
  lastNameRequired: {
    id: 'userManagement.lastNameRequired',
    defaultMessage: 'Last name is required',
  },
  emailRequired: {
    id: 'userManagement.emailRequired',
    defaultMessage: 'Email is required',
  },
  passwordRequired: {
    id: 'userManagement.passwordRequired',
    defaultMessage: 'Password is required',
  },
  passwordRequired1: {
    id: 'userManagement.passwordRequired1',
    defaultMessage: 'Please enter password again',
  },
  roleRequired: {
    id: 'userManagement.roleRequired',
    defaultMessage: 'Role is required',
  },
  passwordMatch: {
    id: 'userManagement.passwordMatch',
    defaultMessage: "Password doesn't match",
  },
  invalidEmail: {
    id: 'userManagement.invalidEmail',
    defaultMessage: 'Please enter valid email address',
  },
  // MARK: response messages
  addUserSuccess: {
    id: 'userManagement.addUserSuccess',
    defaultMessage: 'User added successfully!',
  },
  updateUserSuccess: {
    id: 'userManagement.updateUserSuccess',
    defaultMessage: 'User details updated!',
  },
  serverError: {
    id: 'userManagement.serverError',
    defaultMessage: 'An internal server error occurred.',
  },
});
