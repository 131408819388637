import Button from 'v2s-ui-core/app/components/Button';
import Dashboard from './components/DashboardContainer/Dashboard';
import AppBaseLayout from '~/shell/components/Layout/components/AppLayout';

const DashboardSummary = () => (
  <AppBaseLayout
    contentProps={{
      content: <Dashboard />,
    }}
    renderWithDefaultSettings={false}
    headerProps={{
      title: 'My Dashboard',
      actionButtons: (
        <>
          <Button variant='contained'>Download Platform</Button>
        </>
      ),
    }}
  />
);

export default DashboardSummary;
