import { BREADCRUMBS } from '~/constants/index';

export const useBreadCrumb = (pathname: string) => {
  const paths = pathname.split('/').filter((path) => path !== '');
  const breadCrumbs = paths
    .map((path: string) => BREADCRUMBS[path] || null)
    .filter((path) => path !== null)
    .map((title, index) => ({ title, index }));

  return { breadCrumbs };
};
