export const API_ERROR_TYPES = {
  SERVER_ERROR: 'server_error',
  VALIDATION_ERROR: 'validation_error',
} as const;

export const BREADCRUMBS: Record<string, string> = {
  dashboard: 'Dashboard',
  traders: 'Traders',
  deposits: 'Deposits',
  withdrawal: 'withdrawal',
  'user-profile': 'User Profile',
  'bank-accounts': 'Bank Accounts',
  requests: 'Requests',
  'new-trading-account': 'New Trading Account',
  'leverage-change': 'Leverage Change',
  'password-change': 'Password Change',
  credit: 'Credit',
  'credit-in': 'Credit In',
  'credit-out': 'Credit Out',
  'user-management': 'User Management',
  transfers: 'Transfer',
  'ib-management': 'IB Management',
  ibs: 'IBs',
  'ib-network': 'IB Network',
  'ib-profile': 'IB Profile',
};

export const CONTENT_TYPE_PDF = 'application/pdf';
export const CONTENT_TYPES_IMAGE = [
  'image/png',
  'image/jpeg',
  'image/jpg',
] as const;

export type ContentType =
  | typeof CONTENT_TYPE_PDF
  | (typeof CONTENT_TYPES_IMAGE)[number];

export const FILE_EXTENSION_PDF = '.pdf';
export type FileExtension = typeof FILE_EXTENSION_PDF;
