import { defineMessages } from 'v2s-ui-core/app/Integrations/Localization';

export default defineMessages({
  approveModalTitle: {
    id: 'bankAccounts.approveModalTitle',
    defaultMessage: 'Approve Bank Account?',
  },
  rejectModalTitle: {
    id: 'bankAccounts.rejectModalTitle',
    defaultMessage: 'Reject Bank Account?',
  },
  approveModalHelpText: {
    id: 'bankAccounts.approveModalHelpText',
    defaultMessage:
      'Warning: You confirm that you’ve read the document by approving. If not reviewed,',
  },
  rejectModalHelpText: {
    id: 'bankAccounts.rejectModalHelpText',
    defaultMessage:
      'Warning: You confirm that you’ve read the document by rejecting. If not reviewed,',
  },
  bankAccountNumber: {
    id: 'bankAccounts.bankAccountNumber',
    defaultMessage: 'Bank Account Number',
  },
  approved: {
    id: 'bankAccounts.approved',
    defaultMessage: 'Approved',
  },
  rejected: {
    id: 'bankAccounts.rejected',
    defaultMessage: 'Rejected',
  },
  pending: {
    id: 'bankAccounts.pending',
    defaultMessage: 'Pending',
  },
  addRemark: {
    id: 'bankAccounts.addRemark',
    defaultMessage: 'Add Remark',
  },
  approve: {
    id: 'bankAccounts.approve',
    defaultMessage: 'Approve',
  },
  reject: {
    id: 'bankAccounts.reject',
    defaultMessage: 'Reject',
  },
  cancel: {
    id: 'bankAccounts.cancel',
    defaultMessage: 'Cancel',
  },
  remarkRequired: {
    id: 'bankAccounts.remarkRequired',
    defaultMessage: 'Remark is required',
  },
  clickToView: {
    id: 'bankAccounts.clickToView',
    defaultMessage: 'click here to view it.',
  },
  clickToClose: {
    id: 'bankAccounts.clickToClose',
    defaultMessage: 'click here to close it.',
  },
  approveSuccessMessage: {
    id: 'bankAccounts.approveSuccessMessage',
    defaultMessage: 'Bank account approved successfully!',
  },
  rejectSuccessMessage: {
    id: 'bankAccounts.rejectSuccessMessage',
    defaultMessage: 'Bank account rejected successfully!',
  },
  approveFailedMessage: {
    id: 'bankAccounts.approveFailedMessage',
    defaultMessage: 'Bank account approval failed!',
  },
  rejectFailedMessage: {
    id: 'bankAccounts.rejectFailedMessage',
    defaultMessage: 'Bank account rejection failed!',
  },
  viewBankAccount: {
    id: 'bankAccounts.viewBankAccount',
    defaultMessage: 'View Bank Account ',
  },
  bankName: {
    id: 'bankAccounts.bankName',
    defaultMessage: 'Bank Name',
  },
  accountName: {
    id: 'bankAccounts.accountName',
    defaultMessage: 'Account Name',
  },
  accountNumber: {
    id: 'bankAccounts.accountNumber',
    defaultMessage: 'Account Number',
  },
  bankIban: {
    id: 'bankAccounts.bankIban',
    defaultMessage: 'Bank IBAN',
  },
  bankIFSC: {
    id: 'bankAccounts.bankIFSC',
    defaultMessage: 'Bank IFSC',
  },
  bankAddress: {
    id: 'bankAccounts.bankAddress',
    defaultMessage: 'Bank Address',
  },
});
