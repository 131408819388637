import { FC, useMemo } from 'react';
import DataGridWithQuery from 'v2s-ui-core/app/components/DataGridWithQuery';
import { ColumnDef } from 'v2s-ui-core/app/components/DataGridCore';
import { API_URLS } from '../../api/config';
import { QUERY_KEYS } from '../../services/queries/constants';
import { WithdrawalTableColumns } from './WithdrawalTableColumns';
import { useApproveRejectActions } from 'src/hooks/ApproveRejectCustomHook/useApproveRejectActions';
import ApproveRejectWithdrawal from './ApproveRejectWithdrawal';
import { WITHDRAWALS_STATUS } from '../../constants';
import messages from '../../messages';

const WithdrawalTable: FC<{}> = () => {
  const {
    recordForApproveReject,
    onApproveRecord,
    onRejectRecord,
    onCancel,
    onConfirmApproveReject,
  } = useApproveRejectActions({
    QUERY_KEYS: QUERY_KEYS.FETCH_WITHDRAWALS,
    API_URLS: API_URLS.APPROVE_REJECT_WITHDRAWAL,
    STATUS: WITHDRAWALS_STATUS,
    user: true,
    messages: messages,
  });

  const memoizedColumns = useMemo(
    () =>
      WithdrawalTableColumns({
        onApproveRecord,
        onRejectRecord,
      }),
    [onApproveRecord, onRejectRecord],
  );

  return (
    <>
      <DataGridWithQuery
        queryProps={{
          resource: API_URLS.FETCH_WITHDRAWALS,
          queryKeyProp: [QUERY_KEYS.FETCH_WITHDRAWALS],
          select: (data: any) => data.data,
          refetchInterval: 5000,
        }}
        filters={{ transaction_type: 'WITHDRAWAL' }}
        columns={memoizedColumns as ColumnDef<unknown>[]}
      />
      {recordForApproveReject && (
        <ApproveRejectWithdrawal
          onCancel={onCancel}
          recordForApproveReject={recordForApproveReject}
          onConfirmApproveReject={onConfirmApproveReject}
        />
      )}
    </>
  );
};

export default WithdrawalTable;
