import { defineMessages } from '~app/Integrations/Localization';

export default defineMessages({
  userProfile: {
    id: 'userProfile.profile',
    defaultMessage: 'Profile',
  },
  bankAccount: {
    id: 'userProfile.bankAccount',
    defaultMessage: 'Bank Accounts',
  },
  kycDocument: {
    id: 'userProfile.kycDocument',
    defaultMessage: 'KYC Documents',
  },
  tradingAccounts: {
    id: 'userProfile.tradingAccounts',
    defaultMessage: 'Trading Accounts',
  },
  wallets: {
    id: 'userProfile.wallets',
    defaultMessage: 'Wallet',
  },
  transactions: {
    id: 'userProfile.transactions',
    defaultMessage: 'Transactions',
  },
  noData: {
    id: 'userProfile.noData',
    defaultMessage: 'No data available',
  },
  genealogy: {
    id: 'userProfile.genealogy',
    defaultMessage: 'IB Genealogy',
  },
  network: {
    id: 'userProfile.network',
    defaultMessage: 'IB Network',
  },
  statement: {
    id: 'userProfile.statement',
    defaultMessage: 'IB Statement',
  },
});
