import { matchPath } from 'react-router-dom';

const APPLICATIONS = {
  LANDING_PAGE: `LANDING_PAGE`,
  AUTH: 'AUTH',
  LOGIN: 'LOGIN',
  SIGNUP: 'SIGNUP',
  FORGOT_PASSWORD: 'FORGOT_PASSWORD',
  RESET_PASSWORD: 'RESET_PASSWORD',
  VERIFY_OTP: 'VERIFY_OTP',
  BANK_ACCOUNTS: 'BANK_ACCOUNTS',
  DEPOSITS: 'DEPOSITS',
  WITHDRAWAL: 'WITHDRAWAL',
  INTERNAL_TRANSFERS: 'INTERNAL_TRANSFERS',
  CLIENT_IN_OUT: 'CLIENT_IN_OUT',
  REPORTS: 'REPORTS',
  REQUESTS: 'REQUESTS',
  NEW_TRADING_ACCOUNT: 'NEW_TRADING_ACCOUNT',
  LEVERAGE_CHANGE: 'LEVERAGE_CHANGE',
  PASSWORD_CHANGE: 'PASSWORD_CHANGE',
  EMAILING: 'EMAILING',
  NOTIFICATIONS: 'NOTIFICATIONS',
  TRADING_SETTINGS: 'TRADING_SETTINGS',
  IB_MANAGEMENT: 'IB_MANAGEMENT',
  IBS: 'IBS',
  IB_NETWORK: 'IB_NETWORK',
  IB_GENEALOGY: 'IB_GENEALOGY',
  USER_MANAGEMENT: 'USER_MANAGEMENT',
  ROLES_AND_PERMISSIONS: 'ROLES_AND_PERMISSIONS',
  TRADERS: 'TRADERS',
  DASHBOARD: 'DASHBOARD',
  USER_PROFILE: 'USER_PROFILE',
  CREDIT: 'CREDIT',
  CREDIT_IN: 'CREDIT_IN',
  CREDIT_OUT: 'CREDIT_OUT',
  IB_PROFILE: 'IB_PROFILE',
  // GX_INJECT_APPLICATIONS
} as const;

type TApplications = (typeof APPLICATIONS)[keyof typeof APPLICATIONS];

const APPLICATION_URLS: Partial<Record<TApplications, string>> = {
  [APPLICATIONS.LANDING_PAGE]: `/`,
  [APPLICATIONS.AUTH]: '/*',
  [APPLICATIONS.LOGIN]: '/login',
  [APPLICATIONS.SIGNUP]: '/signup',
  [APPLICATIONS.FORGOT_PASSWORD]: '/forgot-password',
  [APPLICATIONS.RESET_PASSWORD]: '/reset-password/:id',
  [APPLICATIONS.VERIFY_OTP]: '/verify-otp',
  [APPLICATIONS.USER_MANAGEMENT]: '/user-management',
  [APPLICATIONS.BANK_ACCOUNTS]: '/bank-accounts',
  [APPLICATIONS.DEPOSITS]: '/deposits',
  [APPLICATIONS.WITHDRAWAL]: '/withdrawal',
  [APPLICATIONS.INTERNAL_TRANSFERS]: '/transfers',
  [APPLICATIONS.CLIENT_IN_OUT]: '/client-in-out',
  [APPLICATIONS.REPORTS]: '/reports',
  [APPLICATIONS.REQUESTS]: '/requests/*',
  [APPLICATIONS.NEW_TRADING_ACCOUNT]: '/new-trading-account',
  [APPLICATIONS.LEVERAGE_CHANGE]: '/leverage-change',
  [APPLICATIONS.PASSWORD_CHANGE]: '/password-change',
  [APPLICATIONS.EMAILING]: '/emailing',
  [APPLICATIONS.NOTIFICATIONS]: '/notifications',
  [APPLICATIONS.TRADING_SETTINGS]: '/trading-settings',
  [APPLICATIONS.ROLES_AND_PERMISSIONS]: '/roles-and-permissions',
  [APPLICATIONS.TRADERS]: '/traders',
  [APPLICATIONS.DASHBOARD]: '/dashboard',
  [APPLICATIONS.USER_PROFILE]: '/user-profile',
  [APPLICATIONS.CREDIT]: '/credit/*',
  [APPLICATIONS.CREDIT_IN]: '/credit-in',
  [APPLICATIONS.CREDIT_OUT]: '/credit-out',
  [APPLICATIONS.IB_MANAGEMENT]: '/ib-management/*',
  [APPLICATIONS.IBS]: '/ibs',
  [APPLICATIONS.IB_GENEALOGY]: '/ib-genealogy',

  [APPLICATIONS.IB_PROFILE]: '/ib-profile',
  // GX_INJECT_APPLICATION_URLS
};

const NON_RESTRICTED_APPLICATIONS: TApplications[] = ['LANDING_PAGE'];

const getApplicationNameFromUrl = (url: string) =>
  Object.keys(APPLICATION_URLS).find((key: keyof typeof APPLICATION_URLS) =>
    matchPath(APPLICATION_URLS[key], url),
  );

export default APPLICATION_URLS;
export {
  getApplicationNameFromUrl,
  NON_RESTRICTED_APPLICATIONS,
  APPLICATIONS,
  TApplications,
};
