import messages from './messages';
import Deposit from './Deposit/index';
import Withdrawal from './Withdrawal/index';

export const TRANSACTION_TABS = [
  {
    id: 1,
    label: messages.deposit,
    component: <Deposit />,
  },
  {
    id: 2,
    label: messages.withdrawal,
    component: <Withdrawal />,
  },
];
