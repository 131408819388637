import WithdrawalTable from './components/WithdrawalTable';
import AppBaseLayout from '~/shell/components/Layout/components/AppLayout';

const Withdrawal = () => (
  <AppBaseLayout
    contentProps={{
      content: <WithdrawalTable />,
    }}
    headerProps={{
      title: 'Withdrawals',
      actionButtons: <></>,
    }}
  />
);

export default Withdrawal;
