import { useQuery } from 'v2s-ui-core/app/Integrations/react-query';
import { QUERY_KEYS } from '../services/queries/constants';
import { useQueryFactory } from '../services/queries/useQueryFactory'; // Adjust import path as needed // import useQueryFactory from '../services/queries/useQueryFactory'; // Adjust import path as needed
// import { UserProfileData } from '../types';
import { useLocation } from 'react-router-dom';

export function useBankAccount() {
  const location = useLocation();

  const queryDetails = useQueryFactory(location.state);

  const { data, isLoading, isError } = useQuery<any>(
    (queryDetails as any)[QUERY_KEYS.FETCH_BANK_ACCOUNT_INFOS](),
  );

  return { data, isLoading, isError };
}
