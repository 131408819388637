import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Button, Grid, TextField } from '@mui/material';
import viewDoc from '~/assets/images/viewDoc.svg';
import { AccountType } from '../types';
import SkeletonWrapper from './SkeletonWrapper';
import { FormattedMessage } from '~app/Integrations/Localization';
import messages from '../messages';
// eslint-disable-next-line complexity
const BankAccountForm = ({
  account,
  setValue,
  isLoading,
}: {
  account: AccountType;
  setValue: (boolean: boolean) => void;
  isLoading: boolean;
}) => (
  <Box>
    <Box mt={'20px'}>
      <Grid container spacing={0} columnSpacing={4}>
        <Grid item xs={12} sm={4}>
          <SkeletonWrapper isLoading={isLoading}>
            <TextField
              size='small'
              autoComplete='off'
              label={<FormattedMessage {...messages.bank_name} />}
              margin='normal'
              name='bank_name'
              disabled
              value={(account && account.bank_name) || ''}
            />
          </SkeletonWrapper>
        </Grid>
        <Grid item xs={12} sm={4}>
          <SkeletonWrapper isLoading={isLoading}>
            <TextField
              size='small'
              autoComplete='off'
              label={<FormattedMessage {...messages.bank_account_name} />}
              margin='normal'
              name='bank_account_name'
              disabled
              value={(account && account?.bank_account_name) || ''}
            />
          </SkeletonWrapper>
        </Grid>
      </Grid>
      {/*  */}
      <Grid
        container
        columnSpacing={4}
        spacing={0}
        mt={isLoading ? '16px' : '0px'}
      >
        <Grid item xs={12} sm={4}>
          <SkeletonWrapper isLoading={isLoading}>
            <TextField
              size='small'
              autoComplete='off'
              label={<FormattedMessage {...messages.bank_account_number} />}
              margin='normal'
              name='bank_account_number'
              disabled
              value={(account && account?.bank_account_number) || ''}
            />
          </SkeletonWrapper>
        </Grid>
        <Grid item xs={12} sm={4}>
          <SkeletonWrapper isLoading={isLoading}>
            <TextField
              size='small'
              autoComplete='off'
              label={<FormattedMessage {...messages.bank_iban} />}
              margin='normal'
              name='bank_iban'
              disabled
              value={(account && account?.bank_iban) || ''}
            />
          </SkeletonWrapper>
        </Grid>
      </Grid>
      {/*  */}
      <Grid
        container
        columnSpacing={4}
        spacing={0}
        mt={isLoading ? '16px' : '0px'}
      >
        <Grid item xs={12} sm={4}>
          <SkeletonWrapper isLoading={isLoading}>
            <TextField
              size='small'
              autoComplete='off'
              label={<FormattedMessage {...messages.bank_ifsc} />}
              margin='normal'
              name='bank_ifsc'
              disabled
              value={(account && account?.bank_code) || ''}
            />
          </SkeletonWrapper>
        </Grid>
        <Grid item xs={12} sm={4}>
          <SkeletonWrapper isLoading={isLoading}>
            <TextField
              size='small'
              autoComplete='off'
              label={<FormattedMessage {...messages.bank_address} />}
              margin='normal'
              name='bank_address'
              disabled
              value={(account && account?.bank_address) || ''}
            />
          </SkeletonWrapper>
        </Grid>
      </Grid>
    </Box>
    {/* documents */}
    <Box marginTop={'32px'}>
      <SkeletonWrapper isLoading={isLoading}>
        <Typography sx={{ fontWeight: 'bold', fontSize: '20px' }}>
          Documents
        </Typography>
      </SkeletonWrapper>
      <Box
        display={'flex'}
        alignItems={'center'}
        gap={'8px'}
        marginTop={'32px'}
      >
        <SkeletonWrapper isLoading={isLoading}>
          <TextField
            type='text'
            name='documents'
            size='small'
            label={<FormattedMessage {...messages.document_name} />}
            placeholder='document name'
            disabled
            value={
              (account &&
                account?.user_document?.document_name +
                  (account?.user_document?.latest_version?.extension
                    ? `${account?.user_document?.latest_version.extension}`
                    : '')) ||
              ''
            }
          />
        </SkeletonWrapper>
        <SkeletonWrapper isLoading={isLoading}>
          <Box width={'auto'}>
            <Button
              onClick={() => setValue(true)}
              variant='outlined'
              sx={{
                textTransform: 'none',
                color: '#768098',
                fontWeight: 'bold',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: '8px',
                height: '40px',
              }}
            >
              <img src={viewDoc} alt='viewDoc' />
              <FormattedMessage {...messages.view} />
            </Button>
          </Box>
        </SkeletonWrapper>
      </Box>
    </Box>
    {/* actions */}
  </Box>
);

export default BankAccountForm;
