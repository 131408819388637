import { ColumnDef } from 'v2s-ui-core/app/components/DataGridCore';
import Typography from '@mui/material/Typography';
import Switch from '@mui/material/Switch';
import Stack from 'v2s-ui-core/app/components/Stack';
import IconButton from 'v2s-ui-core/app/components/IconButton';
import EditIcon from '~/assets/images/edit.svg';
import DeleteIcon from '~/assets/images/delete.svg';
import ViewIcon from '~/assets/images/view.svg';
import Tooltip from '@mui/material/Tooltip';

export const getUserManagementTableColumns = (props: {
  onDeleteRecord: Function;
  onUpdateRecord: Function;
  onViewRecord?: Function;
}): ColumnDef<unknown>[] => [
  {
    accessorKey: 'first_name',
    header: 'First name',
    enableColumnFilter: false,
  },
  {
    accessorKey: 'last_name',
    header: 'Last name',
    enableColumnFilter: false,
  },
  {
    accessorKey: 'email',
    header: 'Email',
    enableColumnFilter: false,
  },
  {
    accessorKey: 'role.name',
    header: 'Role',
    enableColumnFilter: false,
  },
  {
    accessorKey: 'status',
    header: 'Status',
    enableColumnFilter: false,
    cell: ({ cell }) => (
      <Typography variant='body2'>
        {cell.getValue() ? 'Active' : 'Inactive'}
      </Typography>
    ),
  },
  {
    accessorKey: 'is_2fa',
    header: '2FA Status',
    enableColumnFilter: false,
    cell: (cell) => <Switch checked={!!cell.getValue()} />,
    enableSorting: false,
  },
  {
    accessorKey: 'delete',
    header: 'Actions',
    cell: ({ row }: any) => (
      <Stack direction='row' alignItems='center'>
        {/* <Tooltip title='View' disableFocusListener disableTouchListener>
          <IconButton onClick={() => props?.onViewRecord(row.original)}>
            <img src={ViewIcon} alt='view-icon' />
          </IconButton>
        </Tooltip> */}
        <Tooltip title='Edit' disableFocusListener disableTouchListener>
          <IconButton onClick={() => props?.onUpdateRecord(row.original)}>
            <img src={EditIcon} alt='edit-icon' />
          </IconButton>
        </Tooltip>
        <Tooltip title='Delete' disableFocusListener disableTouchListener>
          <IconButton onClick={() => props?.onDeleteRecord(row.original)}>
            <img src={DeleteIcon} alt='delete-icon' />
          </IconButton>
        </Tooltip>
      </Stack>
    ),
    enableSorting: false,
  },
];
