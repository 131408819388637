import AddBoxRoundedIcon from '@mui/icons-material/AddBoxRounded';
import IndeterminateCheckBoxRoundedIcon from '@mui/icons-material/IndeterminateCheckBoxRounded';
import { Box, Typography } from '@mui/material';
import { SimpleTreeView } from '@mui/x-tree-view';
import React, { useEffect } from 'react';
import { FormattedMessage } from 'v2s-ui-core/app/Integrations/Localization';
import { renderTreeItems } from './constant';
import { useFetchData } from '~/hooks/fetchData/useFetchData';
import messages from '../../messages';

const IbGenealogy: React.FC<unknown> = () => {
  const { data: listData } = useFetchData({
    url: `/get-ib-network/${10001}`,
    queryKey: 'fetchIBGenealogy',
  });

  function ExpandIcon(props: any) {
    return <AddBoxRoundedIcon {...props} sx={{ opacity: 0.8 }} />;
  }

  function CollapseIcon(props: any) {
    return (
      <IndeterminateCheckBoxRoundedIcon {...props} sx={{ opacity: 0.8 }} />
    );
  }

  return (
    <Box className='genealogyTable  scrollableDataTable'>
      <SimpleTreeView
        aria-label='customized'
        slots={{
          expandIcon: ExpandIcon,
          collapseIcon: CollapseIcon,
        }}
        sx={{
          minHeight: 270,
          flexGrow: 1,
          maxWidth: 'auto',
          whiteSpace: 'nowrap',
          overflowX: 'auto !important',
          '& .MuiTreeItem-root': {
            // borderTop: '1px solid #e5e5e5',
          },
          '&::-webkit-scrollbar': {
            display: 'none',
          },
        }}
      >
        {!(listData as any)?.data ? (
          <Box
            height={'220px'}
            display={'flex'}
            alignItems={'center'}
            justifyContent={'center'}
            flexDirection={'column'}
          >
            <FormattedMessage {...messages.noData} />
            <Box display={'flex'} flexDirection={'row'}></Box>
          </Box>
        ) : (
          renderTreeItems((listData as any)?.data)
        )}
      </SimpleTreeView>
    </Box>
  );
};

export default IbGenealogy;
