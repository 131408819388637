import { defineMessages } from 'v2s-ui-core/app/Integrations/Localization';

export const tradingAccountMessages = defineMessages({
  approveModalTitle: {
    id: 'newTradingAccount.approveModalTitle',
    defaultMessage: 'Approve Trading Account?',
  },
  rejectModalTitle: {
    id: 'newTradingAccount.rejectModalTitle',
    defaultMessage: 'Do you want to reject trading account?',
  },
  approveModalHelpText: {
    id: 'newTradingAccount.approveModalHelpText',
    defaultMessage: 'Do you want to approve trading account?',
  },
  rejectModalHelpText: {
    id: 'newTradingAccount.rejectModalHelpText',
    defaultMessage: 'Reject trading account ?.',
  },
  approved: {
    id: 'newTradingAccount.approved',
    defaultMessage: 'Approved',
  },
  rejected: {
    id: 'newTradingAccount.rejected',
    defaultMessage: 'Rejected',
  },
  pending: {
    id: 'newTradingAccount.pending',
    defaultMessage: 'Pending',
  },
  addRemark: {
    id: 'newTradingAccount.addRemark',
    defaultMessage: 'Add Remark',
  },
  approve: {
    id: 'newTradingAccount.approve',
    defaultMessage: 'Approve',
  },
  reject: {
    id: 'newTradingAccount.reject',
    defaultMessage: 'Reject',
  },
  cancel: {
    id: 'newTradingAccount.cancel',
    defaultMessage: 'Cancel',
  },
  remarkRequired: {
    id: 'newTradingAccount.remarkRequired',
    defaultMessage: 'Remark is required',
  },
  approveSuccessMessage: {
    id: 'newTradingAccount.approveSuccessMessage',
    defaultMessage: 'Trading account approved successfully!',
  },
  rejectSuccessMessage: {
    id: 'newTradingAccount.rejectSuccessMessage',
    defaultMessage: 'Trading account rejected successfully!',
  },
  approveFailedMessage: {
    id: 'newTradingAccount.approveFailedMessage',
    defaultMessage: 'Trading account approval failed!',
  },
  rejectFailedMessage: {
    id: 'newTradingAccount.rejectFailedMessage',
    defaultMessage: 'Trading account rejection failed!',
  },
});

export const leverageChangeMessages = defineMessages({
  approveModalTitle: {
    id: 'leverageChange.approveModalTitle',
    defaultMessage: 'Approve leverage change?',
  },
  rejectModalTitle: {
    id: 'leverageChange.rejectModalTitle',
    defaultMessage: 'Reject leverage change?',
  },
  addRemark: {
    id: 'leverageChange.addRemark',
    defaultMessage: 'Add Remark',
  },
  approve: {
    id: 'leverageChange.approve',
    defaultMessage: 'Approve',
  },
  reject: {
    id: 'leverageChange.reject',
    defaultMessage: 'Reject',
  },
  cancel: {
    id: 'leverageChange.cancel',
    defaultMessage: 'Cancel',
  },
  approveLevrageModalTitle: {
    id: 'leverageChange.approveLevrageModalTitle',
    defaultMessage: 'Approve leverage change?',
  },
  rejectLevrageModalTitle: {
    id: 'leverageChange.rejectLevrageModalTitle',
    defaultMessage: 'Reject leverage change?',
  },

  approveLevrageModalHelpText: {
    id: 'leverageChange.approveLevrageModalHelpText',
    defaultMessage: 'Do you want to approve leverage change ?',
  },

  rejectLevrageModalHelpText: {
    id: 'leverageChange.rejectLevrageModalHelpText',
    defaultMessage: 'Do you want to reject leverage change ?',
  },

  approveSuccessMessage: {
    id: 'leverageChange.approveSuccessMessage',
    defaultMessage: 'Leverage change approved successfully!',
  },
  rejectSuccessMessage: {
    id: 'leverageChange.rejectSuccessMessage',
    defaultMessage: 'Leverage change rejected successfully!',
  },
  approveFailedMessage: {
    id: 'leverageChange.approveFailedMessage',
    defaultMessage: ' leverage change approval failed!',
  },
  rejectFailedMessage: {
    id: 'leverageChange.rejectFailedMessage',
    defaultMessage: ' leverage change rejection failed!',
  },
});

export const passwordChangeMessages = defineMessages({
  addRemark: {
    id: 'passwordChange.addRemark',
    defaultMessage: 'Add Remark',
  },
  approve: {
    id: 'passwordChange.approve',
    defaultMessage: 'Approve',
  },
  reject: {
    id: 'passwordChange.reject',
    defaultMessage: 'Reject',
  },
  cancel: {
    id: 'passwordChange.cancel',
    defaultMessage: 'Cancel',
  },

  approveModalTitle: {
    id: 'passwordChange.approvePasswordModalTitle',
    defaultMessage: 'Approve password change?',
  },
  rejectModalTitle: {
    id: 'passwordChange.rejectPasswordModalTitle',
    defaultMessage: 'Reject password change?',
  },

  approvePasswordModalHelpText: {
    id: 'passwordChange.approvePasswordModalHelpText',
    defaultMessage: 'Do you want to approve password change ?',
  },

  rejectPasswordModalHelpText: {
    id: 'passwordChange.rejectPasswordModalHelpText',
    defaultMessage: 'Do you want to reject password change ?',
  },

  approveSuccessMessage: {
    id: 'passwordChange.approveSuccessMessage',
    defaultMessage: 'Password change approved successfully!',
  },
  rejectSuccessMessage: {
    id: 'passwordChange.rejectSuccessMessage',
    defaultMessage: 'Password change rejected successfully!',
  },
  approveFailedMessage: {
    id: 'passwordChange.approveFailedMessage',
    defaultMessage: ' password change approval failed!',
  },
  rejectFailedMessage: {
    id: 'passwordChange.rejectFailedMessage',
    defaultMessage: ' password change rejection failed!',
  },
});
