import { useUserAuth } from './AuthProvider';

const RequiresAuth = ({ children }: React.PropsWithChildren) => {
  const { auth } = useUserAuth();

  if (!auth) return null;

  return <>{children}</>;
};

export default RequiresAuth;
