import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Popover from '@mui/material/Popover';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';
import { deepPurple, red } from '@mui/material/colors';
import Box from 'v2s-ui-core/app/components/Box';
import Stack from 'v2s-ui-core/app/components/Stack';
import { useUserMenu } from './useUserMenu';
import { FormattedMessage } from 'v2s-ui-core/app/Integrations/Localization';
import { getUserMenuItemsConfig, MENU_ANCHOR_ORIGIN } from './constants';
import messages from './messages';
import ChangePasswordModal from '~/components/ChangePasswordModal';
import { useState } from 'react';

const UserMenu = () => {
  const {
    anchorEl,
    handleClick,
    handleClose,
    id,
    open,
    onLogoutUser,
    email,
    userInitials,
  } = useUserMenu();
  const [isChangePasswordModal, setIsChangeModal] = useState(false);

  const closeChangePasswordModal = () => {
    setIsChangeModal(false);
  };
  const MENU_CONFIG = getUserMenuItemsConfig({
    onChangePassword: () => {
      setIsChangeModal(true);
    },
    onLogout: onLogoutUser,
    onMyProfileClick: () => {},
  });

  return (
    <>
      <IconButton
        size='large'
        edge='end'
        aria-label='account of current user'
        //   aria-controls={menuId}
        aria-haspopup='true'
        onClick={handleClick}
        color='inherit'
      >
        <Avatar sx={{ bgcolor: deepPurple[500] }}>{userInitials}</Avatar>
        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={MENU_ANCHOR_ORIGIN}
        slotProps={{
          paper: {
            sx: {
              width: '300px',
              padding: 2,
            },
          },
        }}
      >
        <Stack justifyContent='center' alignItems='center'>
          <Avatar sx={{ width: 88, height: 88, bgcolor: deepPurple[500] }}>
            {userInitials}
          </Avatar>
          <Typography mt={2} variant='caption'>
            {email}
          </Typography>
        </Stack>
        <Box
          display='flex'
          alignItems='center'
          p={1}
          mt={2}
          width='100%'
          sx={{
            border: `1px solid ${red[200]}`,
            borderRadius: 1,
          }}
        >
          <LockOpenOutlinedIcon color='error' />
          <Typography color={red[500]} ml={1} flex={1}>
            <FormattedMessage {...messages.twoFAOff} />
          </Typography>
          <Switch />
        </Box>
        <MenuList>
          {MENU_CONFIG.map((item) => (
            <MenuItem key={item.id} onClick={item.action}>
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primaryTypographyProps={{ variant: 'body2' }}>
                {item.menu}
              </ListItemText>
            </MenuItem>
          ))}
        </MenuList>
      </Popover>
      {isChangePasswordModal && (
        <ChangePasswordModal
          openChangePasswordModal={isChangePasswordModal}
          closeChangePasswordModal={closeChangePasswordModal}
        />
      )}
    </>
  );
};

export default UserMenu;
