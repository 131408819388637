import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import { blue } from '@mui/material/colors';
import Button from 'v2s-ui-core/app/components/Button';
import Grid from 'v2s-ui-core/app/components/Grid';
import TextField from 'v2s-ui-core/app/components/Input/TextField';
import { FormattedMessage } from 'v2s-ui-core/app/Integrations/Localization';
import messages from '~/modules/BankAccounts/messages';
import { ViewBankAccountFormProps } from '~/modules/BankAccounts/types';
import { CloseButton } from '~/components/Actions/CloseButton';

const ViewBankAccountForm = ({
  open,
  record,
  onClose,
}: ViewBankAccountFormProps) => (
  <Dialog
    PaperProps={{
      component: 'form',
      sx: {
        minWidth: '636px',
      },
    }}
    open={open}
    onClose={onClose}
  >
    <DialogTitle
      sx={{
        backgroundColor: blue[50],
      }}
    >
      <FormattedMessage {...messages.viewBankAccount} />
      <CloseButton onClose={onClose} />
    </DialogTitle>
    <Grid container spacing={2} p={2}>
      <Grid item xs={6}>
        <TextField
          name='bank_name'
          label={<FormattedMessage {...messages.bankName} />}
          size='small'
          value={record?.bank_name}
          fullWidth
          disabled
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          name='accountName'
          value={record?.bank_account_name}
          label={<FormattedMessage {...messages.accountName} />}
          size='small'
          fullWidth
          disabled
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          name={'bank_account_number'}
          label={<FormattedMessage {...messages.accountNumber} />}
          value={record?.bank_account_number}
          size='small'
          fullWidth
          disabled
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          name={'bank_iban'}
          label={<FormattedMessage {...messages.bankIban} />}
          size='small'
          value={record?.bank_iban}
          fullWidth
          disabled
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          name={'bank_code'}
          label={<FormattedMessage {...messages.bankIFSC} />}
          size='small'
          value={record?.bank_code}
          fullWidth
          disabled
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          name={'bank_address'}
          label={<FormattedMessage {...messages.bankAddress} />}
          value={record?.bank_address}
          size='small'
          fullWidth
          disabled
        />
      </Grid>
    </Grid>
    <DialogActions sx={{ padding: 2 }}>
      <Button onClick={onClose} variant='outlined'>
        <FormattedMessage {...messages.cancel} />
      </Button>
    </DialogActions>
  </Dialog>
);

export default ViewBankAccountForm;
