import React from 'react';
import { useEffect } from 'react';
import Statement from './modules/Statement';
import NetworkPerformance from './modules/NetworkPerformance';
import Box from '~app/components/Box';

const IbStatement = () => {
  const [selectedStatement, setSelectedStatement] = React.useState<string>('');

  //   useEffect(() => {
  //     if (selectedStatement) {
  //       const anchor = document.querySelector<HTMLElement>(
  //         '#network-performance',
  //       );
  //       if (anchor) {
  //         anchor.scrollIntoView({ block: 'center' });
  //       }
  //     }
  //   }, [selectedStatement]);

  return (
    <>
      <Statement
        selectedStatement={selectedStatement}
        setSelectedStatement={setSelectedStatement}
      />
      <Box mt={20} id='network-performance'>
        <NetworkPerformance selectedStatement={selectedStatement} />
      </Box>
    </>
  );
};
export default IbStatement;
