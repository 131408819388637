import AppBaseLayout from '~/shell/components/Layout/components/AppLayout';
import InternalTransfersTable from './components/index';

const BankAccounts = () => (
  <AppBaseLayout
    contentProps={{
      content: <InternalTransfersTable />,
    }}
    headerProps={{
      title: 'Transfers',
      actionButtons: <></>,
    }}
  />
);

export default BankAccounts;
