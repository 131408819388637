import React, { useMemo } from 'react';
import DataGridWithQuery from 'v2s-ui-core/app/components/DataGridWithQuery';
import Box from '~app/components/Box';
import { getStatementTableColumns } from './StatementTableColumn';

const Statement = ({
  selectedStatement,
  setSelectedStatement,
}: {
  selectedStatement: string;
  setSelectedStatement: (value: string) => void;
}) => {
  const memoizedColumns = useMemo(
    () => getStatementTableColumns({ setSelectedStatement }),
    [setSelectedStatement],
  );

  return (
    <>
      <Box className='ScrollableTableContainer'>
        <DataGridWithQuery
          queryProps={{
            resource: '/statement',
            queryKeyProp: ['statement'],
            staticData: [
              {
                name: 'John Doe',
                client_name: 'Client A',
                commission: 150.75,
                lots_open: 3.5,
                lots_closed: 5.0,
                rebate: 25.5,
                profit_share: 200.0,
              },
              {
                name: 'Jane Smith',
                client_name: 'Client B',
                commission: 180.25,
                lots_open: 4.2,
                lots_closed: 6.1,
                rebate: 30.0,
                profit_share: 220.0,
              },
              {
                name: 'Mike Brown',
                client_name: 'Client C',
                commission: 120.5,
                lots_open: 2.8,
                lots_closed: 4.0,
                rebate: 20.75,
                profit_share: 180.0,
              },
              {
                name: 'Sarah Lee',
                client_name: 'Client D',
                commission: 200.0,
                lots_open: 5.0,
                lots_closed: 7.3,
                rebate: 35.0,
                profit_share: 250.0,
              },
              {
                name: 'Chris White',
                client_name: 'Client E',
                commission: 140.9,
                lots_open: 3.1,
                lots_closed: 4.8,
                rebate: 22.5,
                profit_share: 190.0,
              },
            ],
          }}
          columns={memoizedColumns}
          pageCount={10}
        />
      </Box>
    </>
  );
};

export default Statement;
