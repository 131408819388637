import AppBaseLayout from '~/shell/components/Layout/components/AppLayout';
import TradersTable from './components/TradersTable';
import Button from 'v2s-ui-core/app/components/Button';
import { QUERY_KEYS } from './services/queries/constants';
const Traders = () => (
  <AppBaseLayout
    contentProps={{
      content: <TradersTable />,
    }}
    headerProps={{
      title: 'All Traders',
    }}
    QUERY_KEYS={QUERY_KEYS.FETCH_TRADERS}
    ShowRefresh
  />
);

export default Traders;
