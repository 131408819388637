import Collapse from '@mui/material/Collapse';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { SIDEBAR_MENUS } from './constants';
import { Drawer } from './styles';
import { useTheme } from '@mui/material/styles';
import React from 'react';
import useSidebar from './useSidebar';
import ExpandLessOutlinedIcon from '@mui/icons-material/ExpandLessOutlined';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import Tooltip from '@mui/material/Tooltip';

const Sidebar = ({ open }: { open: boolean }) => {
  const theme = useTheme();
  const { onMenuClick, openMenus, setSelectedMenu } = useSidebar();

  return (
    <Drawer
      variant='permanent'
      open={open}
      PaperProps={{
        sx: {
          backgroundColor: theme.palette.primary.main,
          top: 64,
          height: 'calc(100% - 64px)',
        },
      }}
    >
      <List>
        {SIDEBAR_MENUS.map((item) => (
          <React.Fragment key={item.id}>
            <ListItem disablePadding sx={{ display: 'block' }}>
              <Tooltip title={!open ? item.menuName : ''} placement='right'>
                <ListItemButton
                  selected={item.id === setSelectedMenu}
                  onClick={() => onMenuClick(item)}
                  sx={[
                    {
                      minHeight: 48,
                      px: 2.5,
                      '&.Mui-selected': {
                        backgroundColor: theme.palette.primary.dark,
                      },
                      '&.Mui-selected:hover': {
                        backgroundColor: theme.palette.primary.dark,
                      },
                    },
                    open
                      ? {
                          justifyContent: 'initial',
                        }
                      : {
                          justifyContent: 'center',
                        },
                  ]}
                >
                  <ListItemIcon
                    sx={[
                      {
                        minWidth: 0,
                        justifyContent: 'center',
                      },
                      open
                        ? {
                            mr: 4,
                          }
                        : {
                            mr: 'auto',
                          },
                    ]}
                  >
                    <img
                      className='menu-icon'
                      src={item.icon as string}
                      alt={item.altText}
                    />
                  </ListItemIcon>
                  {open && (
                    <ListItemText
                      primary={item.menuName}
                      primaryTypographyProps={{
                        variant: 'body2',
                        component: 'span',
                      }}
                      sx={[
                        {
                          color: theme.palette.grey[300],
                          textWrap: 'wrap',
                        },
                      ]}
                    />
                  )}
                  {open && item.submenus && (
                    <>
                      {openMenus.includes(item.id) ? (
                        <ExpandLessOutlinedIcon sx={{ color: 'white' }} />
                      ) : (
                        <ExpandMoreOutlinedIcon sx={{ color: 'white' }} />
                      )}
                    </>
                  )}
                </ListItemButton>
              </Tooltip>
            </ListItem>
            {item.submenus && (
              <Collapse in={openMenus.includes(item.id)} unmountOnExit>
                <List component='div' disablePadding>
                  {open &&
                    item.submenus.map((sub) => (
                      <ListItemButton
                        selected={sub.id === setSelectedMenu}
                        onClick={() => onMenuClick(sub, item)}
                        key={sub.id}
                        sx={{
                          pl: open ? 4 : 0,
                          justifyContent: open ? 'initial' : 'center',
                          '&.Mui-selected': {
                            backgroundColor: theme.palette.primary.dark,
                          },
                          '&.Mui-selected:hover': {
                            backgroundColor: theme.palette.primary.dark,
                          },
                        }}
                      >
                        <ListItemIcon>
                          <img
                            className='menu-icon'
                            src={sub.icon as string}
                            alt={sub.altText}
                          />
                        </ListItemIcon>
                        <ListItemText
                          primary={sub.menuName}
                          primaryTypographyProps={{
                            variant: 'body2',
                            component: 'span',
                          }}
                          sx={[
                            {
                              color: theme.palette.grey[300],
                              textWrap: 'wrap',
                            },
                          ]}
                        />
                      </ListItemButton>
                    ))}
                </List>
              </Collapse>
            )}
          </React.Fragment>
        ))}
      </List>
    </Drawer>
  );
};

export default Sidebar;
