import { Link } from 'react-router-dom';
import APPLICATION_URLS from '~/shell/Routing/config/appsConfig';
import { ColumnDef } from '~app/components/DataGridCore';

// eslint-disable-next-line arrow-body-style
export const getIBsTableColumns = (): ColumnDef<unknown>[] => {
  return [
    {
      accessorKey: 'full_name',
      header: 'Name',
      enableColumnFilter: true,
    },
    {
      accessorKey: 'email',
      header: 'Email',
      enableColumnFilter: true,
      cell: ({ row }: any) => (
        <Link state={row.original?.id} to={APPLICATION_URLS?.IB_PROFILE}>
          {row?.original?.email}
        </Link>
      ),
    },
    {
      accessorKey: 'address_line1',
      header: 'Address',
      enableColumnFilter: true,
    },
    {
      accessorKey: 'city_town',
      header: 'City/Town',
      enableColumnFilter: true,
    },
    {
      accessorKey: 'zip_postal_code',
      header: 'ZIP/Postal Code',
      enableColumnFilter: true,
    },
    {
      accessorKey: 'state_province',
      header: 'State/Province',
      enableColumnFilter: true,
    },
    {
      accessorKey: 'country',
      header: 'Country',
      enableColumnFilter: true,
    },
    {
      accessorKey: 'phone_number',
      header: 'Phone Number',
      enableColumnFilter: true,
    },
    {
      accessorKey: 'date_of_birth',
      header: 'DOB',
      enableColumnFilter: true,
    },
  ];
};
