import React from 'react';
import IconButton from 'v2s-ui-core/app/components/IconButton';
import Stack from 'v2s-ui-core/app/components/Stack';
import ViewIcon from '~/assets/images/view.svg';
import ApproveIcon from '~/assets/images/CheckFilled.svg';
import RejectIcon from '~/assets/images/CloseFilled.svg';
// import EditIcon from '~/assets/images/edit.svg';
// import DeleteIcon from '~/assets/images/delete.svg';
import Tooltip from '@mui/material/Tooltip';
import Chip from '@mui/material/Chip';
import { FormattedMessage } from 'v2s-ui-core/app/Integrations/Localization';
import { Box } from '@mui/material';

type ActionButtonsProps = {
  status: string;
  onViewRecord?: Function;
  onApproveRecord?: Function;
  onRejectRecord?: Function;
  rowData: object;
  showViewButton?: boolean; // To control whether to show the view button
  ACCOUNT_STATUS: Record<string, string>;
  ACCOUNT_STATUS_CONFIG: Record<string, any>;
  // deleteRecord?: boolean;
  // editRecord?: boolean;
};

const ActionButtons: React.FC<ActionButtonsProps> = ({
  status,
  onViewRecord,
  onApproveRecord,
  onRejectRecord,
  ACCOUNT_STATUS,
  ACCOUNT_STATUS_CONFIG,
  rowData,
  showViewButton, // Default to true, can be set to false when needed
  // deleteRecord,
  // editRecord,
}) => (
  <Stack direction='row' alignItems='center'>
    {showViewButton && (
      <Tooltip title='View' disableFocusListener disableTouchListener>
        <IconButton onClick={() => onViewRecord?.(rowData)}>
          <img src={ViewIcon} alt='view-icon' />
        </IconButton>
      </Tooltip>
    )}

    {/* {editRecord && (
      <Tooltip title='Edit' disableFocusListener disableTouchListener>
        <IconButton>
          <img src={EditIcon} alt='edit-icon' />
        </IconButton>
      </Tooltip>
    )}
    {deleteRecord && (
      <Tooltip title='Delete' disableFocusListener disableTouchListener>
        <IconButton>
          <img src={DeleteIcon} alt='delete-icon' />
        </IconButton>
      </Tooltip>
    )} */}

    {status === ACCOUNT_STATUS.PENDING ? (
      <Box>
        <Tooltip title='Approve' disableFocusListener disableTouchListener>
          <IconButton onClick={() => onApproveRecord?.(rowData)}>
            <img src={ApproveIcon} alt='approve-icon' />
          </IconButton>
        </Tooltip>
        <Tooltip title='Reject' disableFocusListener disableTouchListener>
          <IconButton onClick={() => onRejectRecord?.(rowData)}>
            <img src={RejectIcon} alt='reject-icon' />
          </IconButton>
        </Tooltip>
      </Box>
    ) : (
      <Chip
        label={<FormattedMessage {...ACCOUNT_STATUS_CONFIG[status].label} />}
        color={ACCOUNT_STATUS_CONFIG[status].color}
        sx={{ borderRadius: 0 }}
      />
    )}
  </Stack>
);
export default ActionButtons;
