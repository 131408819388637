/* eslint-disable arrow-body-style */
import React, { useState, useMemo } from 'react';
import { Box, Button } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import { useApproveRejectActions } from '~/hooks/ApproveRejectCustomHook/useApproveRejectActions';
import { EXPIRY_STATUS } from '../contsants';
import { API_URLS } from '../api/config';
import SuccessModal from '~/components/SuccessModal';
import { ExpiryMessage } from '../messages';
import { useIsMobile } from '~/hooks/useIsMobile';

const DocumentExpiry = ({ document }: { document: any }) => {
  const isMobile = useIsMobile();
  const {
    onConfirmApproveReject,
    onSuccessClose,
    successMessage,
    showSuccessModal,
  } = useApproveRejectActions({
    QUERY_KEYS: 'kycExpiry',
    API_URLS: API_URLS.SET_EXPIRY_DATE,
    STATUS: EXPIRY_STATUS,
    messages: ExpiryMessage,
  });

  const expireAt = useMemo(
    () => ({
      POIExpiryDate: null as string | null,
      POAExpiryDate: null as string | null,
    }),
    [],
  );

  const [expiryDates, setExpiryDates] = useState(expireAt);

  const poiId = useMemo(() => {
    return document.document_type === 'POI'
      ? document.document_type_mapping
      : null;
  }, [document]);

  const poaId = useMemo(() => {
    return document.document_type === 'POA'
      ? document.document_type_mapping
      : null;
  }, [document]);

  const formatDate = (date: any) =>
    date ? dayjs(date).format('YYYY-MM-DD') : '';

  const handleExpiry = (type: 'POI' | 'POA') => {
    const id = type === 'POI' ? poiId : poaId;
    const expire_at =
      type === 'POI' ? expiryDates.POIExpiryDate : expiryDates.POAExpiryDate;

    onConfirmApproveReject({
      id: document?.user,
      expire_at,
      document_type_mapping: id,
    });
  };

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Box display={'flex'}>
          {document?.latest_version.status.status_name === 'Approved' &&
            document?.document_side === 'Front' && (
              <>
                {document?.document_type === 'POI' && (
                  <Box display={'flex'} gap={'10px'} alignItems={'center'}>
                    <DatePicker
                      label='POI Expiry'
                      value={
                        document?.expire_at ? dayjs(document?.expire_at) : null
                      }
                      minDate={dayjs(new Date())}
                      onChange={(newDate) =>
                        setExpiryDates((prev) => ({
                          ...prev,
                          POIExpiryDate: formatDate(newDate),
                        }))
                      }
                      format='YYYY-MM-DD'
                      openTo='year'
                      views={['year', 'month', 'day']}
                      disabled={!!document?.expire_at}
                      sx={{
                        width: isMobile ? '200px' : '235px',
                      }}
                      slotProps={{
                        textField: {
                          size: 'small',
                          InputLabelProps: {
                            style: {
                              color: 'gray',
                            },
                          },
                        },
                      }}
                    />
                    {!document?.expire_at && (
                      <Button
                        variant='contained'
                        sx={{ height: '40px', width: '113px' }}
                        onClick={() => handleExpiry('POI')}
                        disabled={!expiryDates.POIExpiryDate}
                      >
                        Add
                      </Button>
                    )}
                  </Box>
                )}

                {document?.document_type === 'POA' && (
                  <Box
                    display={'flex'}
                    gap={'10px'}
                    alignItems={'center'}
                    mt={4}
                  >
                    <DatePicker
                      label='POA Expiry'
                      value={
                        document?.expire_at ? dayjs(document?.expire_at) : null
                      }
                      minDate={dayjs(new Date())}
                      onChange={(newDate) =>
                        setExpiryDates((prev) => ({
                          ...prev,
                          POAExpiryDate: formatDate(newDate),
                        }))
                      }
                      format='YYYY-MM-DD'
                      openTo='year'
                      views={['year', 'month', 'day']}
                      disabled={!!document?.expire_at}
                      sx={{
                        width: isMobile ? `200px` : '235px',
                      }}
                      slotProps={{
                        textField: {
                          size: 'small',
                          InputLabelProps: {
                            style: {
                              color: 'gray',
                            },
                          },
                        },
                      }}
                    />
                    {!document?.expire_at && (
                      <Button
                        variant='contained'
                        sx={{ height: '40px', width: '113px' }}
                        onClick={() => handleExpiry('POA')}
                        disabled={!expiryDates.POAExpiryDate}
                      >
                        Add
                      </Button>
                    )}
                  </Box>
                )}
              </>
            )}
        </Box>
      </LocalizationProvider>
      <SuccessModal
        onClose={onSuccessClose}
        open={showSuccessModal}
        message={successMessage}
      />
    </>
  );
};

export default DocumentExpiry;
