import { useUserAuth } from '../components/Auth/AuthProvider';

import ProtectedRoutes from './components/ProtectedRoutes';
import DefaultRoutes from './components/DefaultRoutes';

const RoutingProvider = () => {
  const { isAuthenticated } = useUserAuth();

  return !isAuthenticated ? (
    <>
      <DefaultRoutes />
    </>
  ) : (
    <ProtectedRoutes />
  );
};

export default RoutingProvider;
