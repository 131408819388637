import { FC, useMemo } from 'react';
import DataGridWithQuery from 'v2s-ui-core/app/components/DataGridWithQuery';
import { DepositsTableColumns } from '~/modules/Deposits/components/DepositsTable/DepositsTableColumns';
import ApproveRejectDeposit from '~/modules/Deposits/components/DepositsTable/ApproveRejectDeposit/index';
import { DEPOSIT_STATUS } from '~/modules/Deposits/constants';
import { useApproveRejectActions } from 'src/hooks/ApproveRejectCustomHook/useApproveRejectActions';
import messages from '~/modules/Deposits/messages';
import { ColumnDef } from 'v2s-ui-core/app/components/DataGridCore';
import { useLocation } from 'react-router-dom';
import SuccessModal from '~/components/SuccessModal';
import { TRANSACTION_TYPE } from '../constants';
import TableFilters from '~/components/TableFilters/index';
import useFilters from '~app/hooks/useFilters';
import { FILTERS } from '~/config/constants';
import Box from '~app/components/Box';
const DepositsTable: FC<{}> = () => {
  const location = useLocation();
  const {
    recordForApproveReject,
    onApproveRecord,
    onRejectRecord,
    onCancel,
    onConfirmApproveReject,
    onSuccessClose,
    successMessage,
    showSuccessModal,
  } = useApproveRejectActions({
    QUERY_KEYS: 'fetchUserDeposits',
    API_URLS: (id: string | number) => `crm/user/transactions/${id}`,
    STATUS: DEPOSIT_STATUS,
    messages: messages,
  });

  const { filters, applyFilters } = useFilters({
    filterTypes: FILTERS,
    namespace: 'fetchUserDeposits',
  });

  const memoizedColumns = useMemo(
    () =>
      DepositsTableColumns({
        onApproveRecord,
        onRejectRecord,
      }),
    [],
  );

  return (
    <>
      <Box className='tableContainer' width={'100%'}>
        <Box display='flex' mb={'26px'}>
          <TableFilters filters={filters} applyFilters={applyFilters} />
        </Box>
      </Box>
      <Box className='ScrollableTableContainer'>
        <DataGridWithQuery
          queryProps={{
            resource: `crm/users/${location.state}/transactions`,
            queryKeyProp: ['fetchUserDeposits'],
            select: (data: any) => data?.data,
          }}
          columns={memoizedColumns as ColumnDef<unknown>[]}
          filters={{ transaction_type: TRANSACTION_TYPE.DEPOSIT, ...filters }}
        />
      </Box>
      {recordForApproveReject && (
        <ApproveRejectDeposit
          onCancel={onCancel}
          recordForApproveReject={recordForApproveReject}
          onConfirmApproveReject={onConfirmApproveReject}
        />
      )}
      <SuccessModal
        open={showSuccessModal}
        onClose={onSuccessClose}
        message={successMessage}
      />
    </>
  );
};

export default DepositsTable;
