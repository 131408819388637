import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Box from 'v2s-ui-core/app/components/Box';
import Stack from 'v2s-ui-core/app/components/Stack';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import TextField from 'v2s-ui-core/app/components/Input/TextField';

export interface AppBaseLayoutProps {
  headerProps: {
    title: string;
    actionButtons?: React.ReactNode;
  };
  contentProps: {
    content: React.ReactNode;
    actionsLeft?: React.ReactNode;
    actionsRight?: React.ReactNode;
  };
  renderWithDefaultSettings?: boolean;
  headerTabs?: React.ReactNode;
}

const AppBaseLayout: React.FC<AppBaseLayoutProps> = ({
  headerProps,
  contentProps,
  renderWithDefaultSettings = true,
  headerTabs,
}) => (
  <Box>
    <Stack
      display='flex'
      justifyContent='space-between'
      alignItems='center'
      flexDirection='row'
      mb={0}
    >
      <Typography variant='h5'>{headerProps.title}</Typography>
    </Stack>
    <Box paddingY={'12px'} mb={0}>
      {headerTabs}
    </Box>
    <Card>
      <CardContent>
        <Box display={'flex'} gap={'12px'} flexDirection={'column'}>
          <Box display={'flex'} gap={'12px'}>
            {renderWithDefaultSettings && (
              <TextField
                name={'search'}
                variant='outlined'
                label={'Search'}
                size='small'
              />
            )}
            {contentProps.actionsLeft && contentProps.actionsLeft}
          </Box>
          <Box>{contentProps.content}</Box>
        </Box>
      </CardContent>
    </Card>
  </Box>
);

export default AppBaseLayout;
