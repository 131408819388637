import {
  useMutation,
  useQueryClient,
} from 'v2s-ui-core/app/Integrations/react-query';
import { patch } from 'v2s-ui-core/app/Integrations/ApiService';
import { useShowToastNotifications } from 'v2s-ui-core/app/containers/ToastNotifications/Provider';

interface EditProps {
  API_URL: (bankId: number | string) => string;
  QUERY_KEY: string;
  messages: any;
}

export const useEdit = ({ API_URL, QUERY_KEY, messages }: EditProps) => {
  const queryClient = useQueryClient();
  const { showToastNotification } = useShowToastNotifications();

  const mutation = useMutation({
    mutationFn: (data: any) => patch(API_URL(data.id), data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY] });
    },
    onError: (error: any) => {
      showToastNotification(
        `${messages.errorMessage}: ${
          error?.data.errors[0]?.detail || 'Unknown error'
        }`,
        'error',
      );
    },
  });

  return mutation;
};
