import Box from '~app/components/Box';
import { Button, Grid, TextField } from '@mui/material';
import { useUserProfileData } from '../hooks/useUserProfileData';
import { FormattedMessage } from 'v2s-ui-core/app/Integrations/Localization';
import messages from '../messages';
const PersonalInformation = () => {
  const { data } = useUserProfileData();

  return (
    <>
      <Box
        paddingY={'10px'}
        paddingX={'10px'}
        display={'flex'}
        flexDirection={'column'}
        gap={'20px'}
      >
        <Grid container spacing={0} columnSpacing={2}>
          <Grid item xs={12} sm={4}>
            <TextField
              required
              size='small'
              autoComplete='off'
              label={<FormattedMessage {...messages.preferred_language} />}
              margin='normal'
              disabled
              value={data?.data?.personal_details?.preferred_language || ''}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              required
              size='small'
              autoComplete='off'
              label={<FormattedMessage {...messages.first_name} />}
              placeholder='first name'
              margin='normal'
              name='first_name'
              disabled
              value={data?.data?.basic_details?.first_name || ''}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              required
              size='small'
              autoComplete='off'
              label={<FormattedMessage {...messages.last_name} />}
              placeholder='last name'
              margin='normal'
              name='last_name'
              disabled
              value={data?.data?.basic_details?.last_name || ''}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              required
              size='small'
              disabled
              autoComplete='off'
              label={<FormattedMessage {...messages.email} />}
              placeholder='Email'
              margin='normal'
              name='email'
              value={data?.data?.basic_details?.email || ''}
            />
          </Grid>
        </Grid>
        <Box>
          <Box>
            <TextField
              required
              size='small'
              autoComplete='off'
              label={<FormattedMessage {...messages.address_line1} />}
              placeholder='Address line 1'
              margin='normal'
              fullWidth
              name='address_line1'
              disabled
              value={data?.data?.personal_details?.address_line1 || ''}
            />
            <TextField
              size='small'
              autoComplete='off'
              label={<FormattedMessage {...messages.address_line2} />}
              placeholder='Address line 2'
              margin='normal'
              fullWidth
              name='address_line2'
              disabled
              value={data?.data?.personal_details?.address_line2 || ''}
            />
          </Box>
          <Grid container spacing={0} columnSpacing={2}>
            <Grid item xs={12} sm={4}>
              <TextField
                required
                size='small'
                autoComplete='off'
                label={<FormattedMessage {...messages.city_town} />}
                placeholder='City/Town'
                margin='normal'
                name='city_town'
                disabled
                value={data?.data?.personal_details?.city_town || ''}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                required
                size='small'
                autoComplete='off'
                label={<FormattedMessage {...messages.state_province} />}
                placeholder='State/Provice'
                margin='normal'
                name='state_province'
                sx={{ marginRight: '48px' }}
                disabled
                value={data?.data?.personal_details?.state_province || ''}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                size='small'
                autoComplete='off'
                label={<FormattedMessage {...messages.country} />}
                margin='normal'
                fullWidth
                disabled
                value={data?.data?.basic_details?.country.countryEn || ''}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                required
                size='small'
                autoComplete='off'
                label={<FormattedMessage {...messages.zip_postal_code} />}
                placeholder='Zip/Postal code'
                margin='normal'
                name='zip_postal_code'
                disabled
                value={data?.data?.personal_details?.zip_postal_code || ''}
              />
            </Grid>
          </Grid>
          {/* </Box> */}
        </Box>

        <Grid container spacing={0} columnSpacing={2}>
          <Grid item xs={12} sm={4}>
            <TextField
              required
              size='small'
              autoComplete='off'
              label={<FormattedMessage {...messages.phone_number} />}
              margin='normal'
              name='phone_number'
              disabled
              value={data?.data?.basic_details?.phone_number || ''}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              required
              size='small'
              autoComplete='off'
              label={<FormattedMessage {...messages.date_of_birth} />}
              placeholder='Date of birth'
              margin='normal'
              name='date_of_birth'
              disabled
              value={data?.data?.personal_details?.date_of_birth || ''}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              required
              size='small'
              autoComplete='off'
              label={<FormattedMessage {...messages.nationality} />}
              placeholder='Nationality'
              margin='normal'
              fullWidth
              name='nationality'
              disabled
              value={data?.data?.personal_details?.nationality || ''}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              required
              size='small'
              autoComplete='off'
              label={<FormattedMessage {...messages.gender} />}
              placeholder='Gender'
              margin='normal'
              name='gender'
              disabled
              value={data?.data?.personal_details?.gender || ''}
            />
          </Grid>
        </Grid>

        <Box display={'flex'} justifyContent={'flex-end'}>
          <Button variant='contained' disabled sx={{ width: '113px' }}>
            Save
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default PersonalInformation;
