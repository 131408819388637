/* eslint-disable max-statements */
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import {
  checkIfUserIsAuthenticated,
  getAuthDataFromStorage,
} from './config/utils';

type TAuthData = {
  id: any;
  access_token: string;
  admin_id: number;
  email: string;
  first_name: string;
  is_password_changed: boolean;
  last_name: string;
  refresh_token: string;
};

type TAuthContext = {
  auth: TAuthData;
  setAuth: React.Dispatch<React.SetStateAction<TAuthData>>;
  setLoadingAuth: React.Dispatch<React.SetStateAction<boolean>>;
  isAuthenticated: boolean;
};

const AuthContext = createContext<TAuthContext>({} as TAuthContext);

const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const [auth, setAuth] = useState<TAuthData | null>(() =>
    getAuthDataFromStorage(),
  );
  const [loadingAuth, setLoadingAuth] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(() =>
    checkIfUserIsAuthenticated(),
  );

  useEffect(() => {
    setIsAuthenticated(checkIfUserIsAuthenticated());
  }, [auth, loadingAuth]);

  const __refetchAuth = useCallback(async () => {
    setLoadingAuth(true);
    //Refetch auth service and set auth state
    setLoadingAuth(false);
  }, []);

  useEffect(() => {
    if (isAuthenticated) __refetchAuth();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return !auth && loadingAuth ? (
    <span>Loading ...</span>
  ) : (
    <AuthContext.Provider
      value={{ auth, setAuth, setLoadingAuth, isAuthenticated }}
    >
      {children}
    </AuthContext.Provider>
  );
};

const useUserAuth = () => useContext(AuthContext);

export { useUserAuth };
export default AuthProvider;
