import * as React from 'react';
import Button from '@mui/material/Button';
import Box from 'v2s-ui-core/app/components/Box';
import Dialog from '@mui/material/Dialog';
// import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useAddUserModal } from './useAddUserModal';
import UserManagementForm from '.';
// import { useTheme } from '@mui/material/styles';
import { blue } from '@mui/material/colors';
import { FormattedMessage } from 'v2s-ui-core/app/Integrations/Localization';
import messages from '../../messages';

const AddUserModal = () => {
  // const theme = useTheme();
  const { setShowAddUserModal, showAddUserModal, onCancel, isEditMode } =
    useAddUserModal();
  return (
    <React.Fragment>
      <Button
        onClick={() => setShowAddUserModal(true)}
        size='large'
        variant='contained'
        type='submit'
      >
        <FormattedMessage {...messages.addUser} />
      </Button>
      <Dialog
        open={showAddUserModal}
        onClose={onCancel}
        PaperProps={{
          component: 'form',
          sx: {
            minWidth: '636px',
          },
        }}
      >
        <DialogTitle sx={{ backgroundColor: blue[50] }}>
          {isEditMode ? (
            <FormattedMessage {...messages.updateUser} />
          ) : (
            <FormattedMessage {...messages.addUser} />
          )}
        </DialogTitle>
        <DialogContent sx={{ width: '636px', padding: 0 }}>
          <DialogContentText ml={2} mt={2}>
            <FormattedMessage {...messages.addUserHelpText} />
          </DialogContentText>
          <Box width='100%'>
            <UserManagementForm onCancel={onCancel} />
          </Box>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};

export default AddUserModal;
