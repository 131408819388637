import { FC, useMemo } from 'react';
import DataGridWithQuery from 'v2s-ui-core/app/components/DataGridWithQuery';
import { useCrudContext } from 'v2s-ui-core/app/containers/CrudProvider';
import { API_URLS } from '../../api/config';
import { QUERY_KEYS } from '../../services/queries/constants';
import { getUserManagementTableColumns } from './UserManagementTableColumns';

const UserManagementTable: FC<{}> = () => {
  const { onDeleteRecord, onUpdateRecord } = useCrudContext();

  const memoizedColumns = useMemo(
    () =>
      getUserManagementTableColumns({
        onDeleteRecord,
        onUpdateRecord,
        onViewRecord: () => {},
      }),
    [onDeleteRecord, onUpdateRecord],
  );

  return (
    <DataGridWithQuery
      queryProps={{
        resource: API_URLS.USER_MANAGEMENT,
        queryKeyProp: [QUERY_KEYS.USER_MANAGEMENT],
        select: (data: any) => data.data,
        refetchInterval: 5000,
      }}
      columns={memoizedColumns}
    />
  );
};

export default UserManagementTable;
