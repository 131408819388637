import * as yup from 'yup';
import messages from '../../messages';

export const getCreditSchema = (formatMessage: Function) =>
  yup.object().shape({
    remark: yup.string().required(formatMessage(messages.remarkRequired)),
    amount: yup
      .number()
      .required(formatMessage(messages.amountRequired))
      .min(10, formatMessage(messages.minimumAmount)),
    trading_account_id: yup
      .object()
      .required(formatMessage(messages.tradingAccountRequired)),
  });
