import APPLICATION_URLS from '~/shell/Routing/config/appsConfig';
import AppBaseLayout, {
  AppBaseLayoutProps,
} from '~/shell/components/Layout/components/AppLayout';
import TradingAccountsTable from './components/TradingAccountsTable';
import LeverageChangeTable from './components/LeverageChangeTable';
import PasswordChangeTable from './components/PasswordChangeTable';
import { tradingAccountMessages } from './messages';

const SUB_ROUTES_FOR_REQUESTS_MAP: {
  path: string;
  element: typeof AppBaseLayout;
  AppBaseLayoutProps: AppBaseLayoutProps;
}[] = [
  {
    path: APPLICATION_URLS.NEW_TRADING_ACCOUNT,
    element: AppBaseLayout,
    AppBaseLayoutProps: {
      headerProps: {
        title: 'New Trading Account',
      },
      contentProps: {
        content: <TradingAccountsTable />,
      },
    },
  },
  {
    path: APPLICATION_URLS.LEVERAGE_CHANGE,
    element: AppBaseLayout,
    AppBaseLayoutProps: {
      headerProps: {
        title: 'Leverage Change',
      },
      contentProps: { content: <LeverageChangeTable /> },
    },
  },
  {
    path: APPLICATION_URLS.PASSWORD_CHANGE,
    element: AppBaseLayout,
    AppBaseLayoutProps: {
      headerProps: {
        title: 'Password Change',
      },
      contentProps: { content: <PasswordChangeTable /> },
    },
  },
];

export { SUB_ROUTES_FOR_REQUESTS_MAP };

// status from api
export const ACCOUNT_STATUS = {
  PENDING: 'PENDING',
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED',
};

export const ACCOUNT_STATUS_CONFIG = {
  [ACCOUNT_STATUS.PENDING]: {
    label: tradingAccountMessages.pending,
    color: 'secondary',
  },
  [ACCOUNT_STATUS.APPROVED]: {
    label: tradingAccountMessages.approved,
    color: 'success',
  },
  [ACCOUNT_STATUS.REJECTED]: {
    label: tradingAccountMessages.rejected,
    color: 'error',
  },
};
