import React, { useState } from 'react';
import { Box } from '@mui/material';
import AppBaseLayout from '~/shell/components/Layout/components/AppLayout';
import Header from './components/UserProfileHeader';
import { USER_PROFILE_TABS } from './constants';

const UserProfile = () => {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  const Component = USER_PROFILE_TABS[activeTab]?.component;
  const activeTabLabel = USER_PROFILE_TABS[activeTab]?.label;

  return (
    <AppBaseLayout
      contentProps={{
        content: <Box>{Component}</Box>,
      }}
      headerProps={{
        title: activeTabLabel?.defaultMessage,
        breadcrumbs: [
          {
            label: activeTabLabel?.defaultMessage,
          },
        ],
      }}
      headerTabs={<Header value={activeTab} onChange={handleTabChange} />}
      renderWithDefaultSettings={false}
    />
  );
};

export default UserProfile;
