import { FormattedMessage } from 'v2s-ui-core/app/Integrations/Localization';
import messages from './messages';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import PasswordOutlinedIcon from '@mui/icons-material/PasswordOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';

export const MENU_ANCHOR_ORIGIN = {
  vertical: 'bottom',
  horizontal: 'left',
} as const;

export const getUserMenuItemsConfig = ({
  onMyProfileClick,
  onChangePassword,
  onLogout,
}: {
  onMyProfileClick: (event: React.MouseEvent<HTMLElement>) => void;
  onChangePassword: (event: React.MouseEvent<HTMLElement>) => void;
  onLogout: (event: React.MouseEvent<HTMLElement>) => void;
}) => [
  {
    id: 'myProfile',
    menu: <FormattedMessage {...messages.myProfile} />,
    icon: <PersonOutlineOutlinedIcon />,
    action: onMyProfileClick,
  },
  {
    id: 'changePassword',
    menu: <FormattedMessage {...messages.changePassword} />,
    icon: <PasswordOutlinedIcon />,
    action: onChangePassword,
  },
  {
    id: 'logout',
    menu: <FormattedMessage {...messages.logout} />,
    icon: <LogoutOutlinedIcon />,
    action: onLogout,
  },
];
