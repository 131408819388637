import * as yup from 'yup';
import messages from '../../messages';
export const MUTATION_KEYS = {
  APPROVE_REJECT_DEPOSIT: 'approveRejectDeposit',
  // GX_INJECT_MUTATION_KEY
} as const;

export const getApproveRejectSchema = (
  formatMessage: Function,
  action: string,
) =>
  yup.object().shape({
    remark: yup.string().required(formatMessage(messages.remarkRequired)),
    trading_amount:
      action === 'APPROVED' &&
      yup
        .number()
        .required(formatMessage(messages.amountRequired))
        .min(10, formatMessage(messages.amountAtLeast)),
  });
