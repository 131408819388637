import UserManagements from './containers/UserManagements';
import AppBaseLayout from '~/shell/components/Layout/components/AppLayout';
import AddUserModal from './components/UserManagementForm/AddUserModal';
import { CrudProvider } from 'v2s-ui-core/app/containers/CrudProvider';
import { QUERY_KEYS } from './services/queries/constants';

const UserManagement = () => (
  <CrudProvider resource='/admin'>
    <AppBaseLayout
      headerProps={{
        title: 'User Management',
        actionButtons: <AddUserModal />,
      }}
      contentProps={{
        content: <UserManagements />,
      }}
      ShowRefresh
      QUERY_KEYS={QUERY_KEYS.USER_MANAGEMENT}
    />
  </CrudProvider>
);

export default UserManagement;
