import { FC, useMemo } from 'react';
import DataGridWithQuery from 'v2s-ui-core/app/components/DataGridWithQuery';
import { DepositsTableColumns } from '~/modules/Deposits/components/DepositsTable/DepositsTableColumns';
import ApproveRejectDeposit from '~/modules/Deposits/components/DepositsTable/ApproveRejectDeposit/index';
import {
  DEPOSIT_TRASCATION_TYPE,
  DEPOSIT_STATUS,
} from '~/modules/Deposits/constants';
import { useApproveRejectActions } from 'src/hooks/ApproveRejectCustomHook/useApproveRejectActions';
import messages from '~/modules/Deposits/messages';
import { ColumnDef } from 'v2s-ui-core/app/components/DataGridCore';
import { useLocation } from 'react-router-dom';

const DepositsTable: FC<{}> = () => {
  const location = useLocation();
  const {
    recordForApproveReject,
    onApproveRecord,
    onRejectRecord,
    onCancel,
    onConfirmApproveReject,
  } = useApproveRejectActions({
    QUERY_KEYS: 'fetchUserDeposits',
    API_URLS: (user: string | number, id: string | number) =>
      `crm/user/${user}/transactions/${id}`,
    STATUS: DEPOSIT_STATUS,
    user: true,
    messages: messages,
  });

  const memoizedColumns = useMemo(
    () =>
      DepositsTableColumns({
        onApproveRecord,
        onRejectRecord,
      }),
    [],
  );

  return (
    <>
      <DataGridWithQuery
        queryProps={{
          resource: `crm/users/${location.state}/transactions`,
          queryKeyProp: ['fetchUserDeposits'],
          select: (data: any) => data?.data,
          refetchInterval: 5000,
        }}
        columns={memoizedColumns as ColumnDef<unknown>[]}
        filters={{ transaction_type: DEPOSIT_TRASCATION_TYPE.DEPOSIT }}
      />
      {recordForApproveReject && (
        <ApproveRejectDeposit
          onCancel={onCancel}
          recordForApproveReject={recordForApproveReject}
          onConfirmApproveReject={onConfirmApproveReject}
        />
      )}
    </>
  );
};

export default DepositsTable;
