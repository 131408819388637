import { useQuery } from 'v2s-ui-core/app/Integrations/react-query';
import { useQueryFactory } from '../services/queries/useQueryFactory';

const useGetGenealogyList = <TData, TError = Error>(params?: any) => {
  const queryDetails = useQueryFactory<TData, TError>();

  const queryResult = useQuery({
    ...queryDetails.fetchGenealogyHistory(params),
  });

  return queryResult;
};

export default useGetGenealogyList;
