import { StyledPublicLayout } from './styles';

type TLayoutProps = React.PropsWithChildren;

const PublicAppbar = () => <></>;

const PublicLayout = (props: TLayoutProps) => (
  <StyledPublicLayout>
    <PublicAppbar />
    {props.children}
  </StyledPublicLayout>
);

export default PublicLayout;
