import { useMemo } from 'react';
import { QueryDetails } from './types';
import { QUERY_KEYS } from './constants';
import ApiServices from '../../api';

export const useQueryFactory = <TData, TError = Error>(id: string | number) => {
  const queryDetails = useMemo<QueryDetails<TData, TError>>(
    () => ({
      [QUERY_KEYS.FETCH_BANK_ACCOUNT_INFOS]: () => ({
        queryKey: [QUERY_KEYS.FETCH_BANK_ACCOUNT_INFOS, id],
        queryFn: () => ApiServices.getBankInfo<TData>(id),
      }),
    }),
    [id],
  );
  return queryDetails;
};
