// Hooks
import { useState } from 'react';
import { useShowToastNotifications } from 'v2s-ui-core/app/containers/ToastNotifications/Provider';
import { useLocaleContext } from 'v2s-ui-core/app/Integrations/Localization';
import {
  useMutation,
  useQueryClient,
} from 'v2s-ui-core/app/Integrations/react-query';
import { patch } from 'v2s-ui-core/app/Integrations/ApiService';

// Interfaces
interface ApproveRejectActionProps {
  QUERY_KEYS: string;
  API_URLS: (user?: string | number, id?: string | number) => string;
  STATUS: Record<string, string>;
  user?: boolean;
  messages: any;
}

interface Payload {
  [key: string]: any;
}

// Custom Hook
export const useApproveRejectActions = ({
  QUERY_KEYS,
  API_URLS,
  STATUS,
  user,
  messages,
}: ApproveRejectActionProps) => {
  // States
  const [recordForApproveReject, setRecordForApproveReject] = useState(null);
  const [error, setError] = useState(null);
  // Hooks
  const { showToastNotification } = useShowToastNotifications();
  const {
    messageFormatter: { formatMessage },
  } = useLocaleContext();
  const queryClient = useQueryClient();

  // const e1 = error?.response?.data.errors.details;

  // Functions
  const approveReject = async <T>(payload: Payload) => {
    const url = user
      ? API_URLS(payload.user, payload.id)
      : API_URLS(payload.id);
    try {
      const response = await patch<T>(url, payload);
      return response.data;
    } catch (error) {
      // console.log('error', error?.data.errors[0].detail);
      setError(error?.data.errors[0].detail);
      throw error;
    }
  };

  const mutationOptions = {
    mutationFn: approveReject,
  };

  const { mutate } = useMutation(mutationOptions);

  const onApproveRecord = (record: object) => {
    setRecordForApproveReject({ record, action: STATUS.APPROVED });
  };

  const onRejectRecord = (record: object) => {
    setRecordForApproveReject({ record, action: STATUS.REJECTED });
  };

  const onCancel = () => {
    setRecordForApproveReject(null);
  };

  const onSuccess = () => {
    const message =
      recordForApproveReject.action === STATUS.APPROVED
        ? messages.approveSuccessMessage
        : messages.rejectSuccessMessage;
    showToastNotification(formatMessage(message), 'success');
    queryClient.invalidateQueries({ queryKey: [QUERY_KEYS] });
    onCancel();
  };

  const onError = (error: any) => {
    const message =
      recordForApproveReject.action === STATUS.APPROVED
        ? messages.approveFailedMessage
        : messages.rejectFailedMessage;
    showToastNotification(
      `${formatMessage(message)}:  ${error?.data.errors[0].detail}`,
      'error',
    );
  };

  const onConfirmApproveReject = (payload: Payload) => {
    mutate(payload, { onSuccess, onError });
  };

  // Returns
  return {
    recordForApproveReject,
    onApproveRecord,
    onRejectRecord,
    onCancel,
    onConfirmApproveReject,
  };
};
