import DepositsTable from './components/DepositsTable';
import AppBaseLayout from '~/shell/components/Layout/components/AppLayout';
import { QUERY_KEYS } from './services/queries/constants';

const Deposits = () => (
  <AppBaseLayout
    contentProps={{
      content: <DepositsTable />,
    }}
    headerProps={{
      title: 'Deposits',
      actionButtons: <></>,
    }}
    QUERY_KEYS={QUERY_KEYS.FETCH_DEPOSITS}
    ShowRefresh
  />
);

export default Deposits;
