import * as yup from 'yup';
import messages from '../../messages';
export const MUTATION_KEYS = {
  APPROVE_REJECT_WITHDRAWAL: 'approveRejectWithdrawal',
  // GX_INJECT_MUTATION_KEY
} as const;

export const getApproveRejectSchema = (
  formatMessage: Function,
  action: string,
) =>
  yup.object().shape({
    remark: yup.string().required(formatMessage(messages.remarkRequired)),
    paymentMethod:
      action === 'APPROVED' &&
      yup.object().required(formatMessage(messages.paymentMethodRequired)),
  });
