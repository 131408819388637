import { FC, useMemo } from 'react';
import DataGridWithQuery from 'v2s-ui-core/app/components/DataGridWithQuery';
import { API_URLS } from '../../api/config';
import { QUERY_KEYS } from '../../services/queries/constants';
import { TradingAccountsTableColumns } from './TradingAccountsTableColumns';
import ApproveRejectTradingAccount from './ApproveRejectTradingAccount'; // Import the component
import { ColumnDef } from 'v2s-ui-core/app/components/DataGridCore';
import { useApproveRejectActions } from '~/hooks/ApproveRejectCustomHook/useApproveRejectActions';
import { ACCOUNT_STATUS } from '../../constants';
import { tradingAccountMessages } from '../../messages';

const TradingAccountsTable: FC<{}> = () => {
  const {
    recordForApproveReject,
    onApproveRecord,
    onRejectRecord,
    onCancel,
    onConfirmApproveReject,
  } = useApproveRejectActions({
    QUERY_KEYS: QUERY_KEYS.FETCH_NEW_TRADING_ACCOUNTS,
    API_URLS: API_URLS.APPROVE_REJECT_TRADING_ACCOUNT,
    STATUS: ACCOUNT_STATUS,
    messages: tradingAccountMessages,
  });

  const handleConfirmApproveReject = () => {
    const payload = {
      crm_status:
        recordForApproveReject?.action === 'APPROVED' ? 'APPROVED' : 'REJECTED',
      id: recordForApproveReject?.record.id,
    };
    onConfirmApproveReject(payload);
  };

  const memoizedColumns = useMemo(
    () =>
      TradingAccountsTableColumns({
        onApproveRecord,
        onRejectRecord,
      }),
    [onApproveRecord, onRejectRecord],
  );
  return (
    <>
      <DataGridWithQuery
        queryProps={{
          resource: API_URLS.FETCH_NEW_TRADING_ACCOUNTS,
          queryKeyProp: [QUERY_KEYS.FETCH_NEW_TRADING_ACCOUNTS],
          select: (data: any) => data.data,
          refetchInterval: 5000,
        }}
        columns={memoizedColumns as ColumnDef<unknown>[]}
      />
      {recordForApproveReject && (
        <ApproveRejectTradingAccount
          onCancel={onCancel}
          recordForApproveReject={recordForApproveReject}
          onConfirmApproveReject={handleConfirmApproveReject}
        />
      )}
    </>
  );
};

export default TradingAccountsTable;
