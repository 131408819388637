import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Box from 'v2s-ui-core/app/components/Box';
import { CrudProvider } from 'v2s-ui-core/app/containers/CrudProvider';
// import UserManagementForm from '../../components/UserManagementForm';
import { FormattedMessage } from 'v2s-ui-core/app/Integrations/Localization';
import ConfirmationDialog from '../../components/ConfirmationDialog';
import AddUserModal from '../../components/UserManagementForm/AddUserModal';
import UserManagementTable from '../../components/UserManagementTable';
import messages from '../../messages';

const UserManagements = () => (
  <CrudProvider resource='/admin'>
    <Box>
      {/* <UserManagementForm /> */}
      <Stack
        display='flex'
        justifyContent='space-between'
        alignItems='center'
        flexDirection='row'
        mb={4}
      >
        <Typography variant='h5'>
          <FormattedMessage {...messages.userManagement} />
        </Typography>
        <AddUserModal />
      </Stack>
      <Card>
        <CardContent>
          <UserManagementTable />
        </CardContent>
      </Card>

      <ConfirmationDialog />
    </Box>
  </CrudProvider>
);

export default UserManagements;
