import AddBoxRoundedIcon from '@mui/icons-material/AddBoxRounded';
import IndeterminateCheckBoxRoundedIcon from '@mui/icons-material/IndeterminateCheckBoxRounded';
import { Typography } from '@mui/material';
import { SimpleTreeView } from '@mui/x-tree-view/SimpleTreeView';
import React, { useEffect, useMemo, useState } from 'react';
import { FormattedMessage } from 'v2s-ui-core/app/Integrations/Localization';
import Box from '~app/components/Box';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import AddBoxIcon from '@mui/icons-material/AddBox';
import messages from '~/modules/IbProfile/messages';
import { renderTreeItems } from '../../constants';
import useGetGenealogyList from '../../hooks/useGetGenealogyList';
import useGetGenealogyHistory from '../../hooks/useGetGenealogyHistory';
import useFilters from 'v2s-ui-core/app/hooks/useFilters';
import TableFilters from '~/components/TableFilters';
import { FILTERS } from '~/config/constants';

function ExpandIcon(props: React.PropsWithoutRef<typeof AddBoxRoundedIcon>) {
  return (
    <AddBoxRoundedIcon {...props} sx={{ color: '#08345D', opacity: 0.8 }} />
  );
}

function CollapseIcon(
  props: React.PropsWithoutRef<typeof IndeterminateCheckBoxRoundedIcon>,
) {
  return (
    <IndeterminateCheckBoxRoundedIcon
      {...props}
      sx={{ color: '#08345D', opacity: 0.8 }}
    />
  );
}

const IBGenealogy = () => {
  const [expandedIds, setExpandedIds] = useState<number[]>([]);
  const [ibUserId, setIbUserId] = useState(null);
  const [ibDataList, setIbDataList] = useState<any>({});
  const { filters, applyFilters } = useFilters({
    filterTypes: FILTERS,
    namespace: 'fetchIBGenealogy',
  });
  const { data: listData, refetch: refetchList } =
    useGetGenealogyList<any>(filters);
  const { data: ibData, refetch } = useGetGenealogyHistory<any>(ibUserId);

  useEffect(() => {
    if (ibUserId !== null) {
      refetch();
      setIbDataList((prev: any) => ({ ...prev, [ibUserId]: ibData }));
    }
  }, [ibUserId, refetch, ibData]);

  useEffect(() => {
    refetchList();
  }, [filters]);

  const handleExpand = useMemo(
    () => (id: number) => {
      if (expandedIds.includes(id)) {
        setExpandedIds((prev) => prev.filter((item) => item !== id));
        setIbUserId(null);
      } else {
        setExpandedIds((prev) => [...prev, id]);
        setIbUserId(id);
      }
    },
    [expandedIds],
  );

  return (
    <>
      <Box className='tableContainer' width={'100%'}>
        <Box display='flex' mb={'26px'}>
          <TableFilters
            includeSearchFilter={false}
            filters={filters}
            applyFilters={applyFilters}
          />
        </Box>
      </Box>
      <Box sx={{ minHeight: 352, minWidth: 250 }}>
        {listData?.data.map((item: any) => (
          <React.Fragment key={item.id}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%',
              }}
            >
              <Box
                onClick={() => handleExpand(item?.id)}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1,
                  transition: 'all 300ms',
                  pl: 1,
                  py: 2,
                  borderRadius: '4px',
                  width: '100%',
                  height: '30px',
                  cursor: 'pointer',
                  ':hover': {
                    backgroundColor: '#f5f5f5',
                  },
                  '&::-webkit-scrollbar': {
                    display: 'none',
                  },
                  maxWidth: 'auto',
                  whiteSpace: 'nowrap',
                  overflowX: 'auto !important',
                }}
              >
                {expandedIds.includes(item?.id) ? (
                  <IndeterminateCheckBoxIcon
                    sx={{
                      color: '#08345D',
                      opacity: 0.8,
                      width: 20,
                      height: 20,
                    }}
                  />
                ) : (
                  <AddBoxIcon
                    sx={{
                      color: '#08345D',
                      opacity: 0.8,
                      width: 20,
                      height: 20,
                    }}
                  />
                )}
                <Typography variant='body1'>{item?.full_name}</Typography>
                <Typography variant='body1'>{item?.email}</Typography>
                <Typography variant='body1'>{item?.phone_number}</Typography>
                <Typography variant='body1'>
                  Account_Type:{item?.userType}
                </Typography>
                <Typography variant='body1'>Level:{item?.level}</Typography>
              </Box>
            </Box>
            {expandedIds.includes(item?.id) && (
              <SimpleTreeView
                aria-label='customized'
                defaultExpandedItems={['10052']}
                slots={{
                  expandIcon: ExpandIcon,
                  collapseIcon: CollapseIcon,
                }}
                sx={{
                  maxWidth: 'auto',
                  pl: 4,
                  whiteSpace: 'nowrap',
                  overflowX: 'auto !important',
                  '& .MuiTreeItem-root': {
                    // borderTop: '1px solid #e5e5e5',
                  },
                  '&::-webkit-scrollbar': {
                    display: 'none',
                  },
                }}
              >
                {!(ibData as any)?.data ? (
                  <Box
                    height={'220px'}
                    display={'flex'}
                    alignItems={'center'}
                    justifyContent={'center'}
                    flexDirection={'column'}
                  >
                    <Typography variant='h6'>
                      <FormattedMessage {...messages.noData} />
                    </Typography>
                  </Box>
                ) : (
                  renderTreeItems((ibDataList as any)?.[item?.id]?.data)
                )}
              </SimpleTreeView>
            )}
          </React.Fragment>
        ))}
      </Box>
    </>
  );
};

export default IBGenealogy;
