import { useEffect } from 'react';
import TextField from 'v2s-ui-core/app/components/Input/TextField';
import Button from 'v2s-ui-core/app/components/Button';
import Grid from 'v2s-ui-core/app/components/Grid';
import { isEmpty } from 'v2s-ui-core/app/utils';
import { useCrudContext } from 'v2s-ui-core/app/containers/CrudProvider';
import AsyncDropdown from 'v2s-ui-core/app/components/AsyncDropdown';
import useUserManagementForm from './useUserManagementForm';
import { USER_MANAGEMENT_FORM_KEYS } from './constants';
import Stack from 'v2s-ui-core/app/components/Stack';
import { FormattedMessage } from 'v2s-ui-core/app/Integrations/Localization';
import messages from '../../messages';

type UserManagementFormProps = {
  onCancel: () => void;
};

const UserManagementForm = ({ onCancel }: UserManagementFormProps) => {
  const { handleChange, handleSubmit, setValues, values, errors, isEditMode } =
    useUserManagementForm({
      onCancel,
    });

  const { recordToUpdate } = useCrudContext();

  useEffect(() => {
    if (!isEmpty(recordToUpdate)) {
      setValues(recordToUpdate);
    }
  }, [recordToUpdate, setValues]);

  return (
    <Grid container spacing={2} p={2}>
      <Grid item xs={6} md={6} sm={6} lg={6} xl={6}>
        <TextField
          name={USER_MANAGEMENT_FORM_KEYS.FIRST_NAME}
          variant='outlined'
          label={<FormattedMessage {...messages.firstName} />}
          onChange={handleChange}
          // @ts-ignore
          value={values?.[USER_MANAGEMENT_FORM_KEYS.FIRST_NAME]}
          fullWidth
          size='small'
          required
          // @ts-ignore
          error={Boolean(errors[USER_MANAGEMENT_FORM_KEYS.FIRST_NAME])}
          // @ts-ignore
          helperText={errors[USER_MANAGEMENT_FORM_KEYS.FIRST_NAME]}
        />
      </Grid>
      <Grid item xs={6} md={6} sm={6} lg={6} xl={6}>
        <TextField
          name={USER_MANAGEMENT_FORM_KEYS.LAST_NAME}
          variant='outlined'
          label={<FormattedMessage {...messages.lastName} />}
          onChange={handleChange}
          // @ts-ignore
          value={values?.[USER_MANAGEMENT_FORM_KEYS.LAST_NAME]}
          fullWidth
          size='small'
          required
          // @ts-ignore
          error={Boolean(errors[USER_MANAGEMENT_FORM_KEYS.LAST_NAME])}
          // @ts-ignore
          helperText={errors[USER_MANAGEMENT_FORM_KEYS.LAST_NAME]}
        />
      </Grid>
      <Grid item xs={6} md={6} sm={6} lg={6} xl={6}>
        <TextField
          name={USER_MANAGEMENT_FORM_KEYS.EMAIL}
          variant='outlined'
          label={<FormattedMessage {...messages.email} />}
          onChange={handleChange}
          // @ts-ignore
          value={values?.[USER_MANAGEMENT_FORM_KEYS.EMAIL]}
          fullWidth
          size='small'
          required
          // @ts-ignore
          error={Boolean(errors[USER_MANAGEMENT_FORM_KEYS.EMAIL])}
          // @ts-ignore
          helperText={errors[USER_MANAGEMENT_FORM_KEYS.EMAIL]}
          disabled={isEditMode}
        />
      </Grid>
      <Grid item xs={6} md={6} sm={6} lg={6} xl={6}>
        <AsyncDropdown
          // @ts-ignore
          value={values?.[USER_MANAGEMENT_FORM_KEYS.ROLE] || null}
          onChange={(e, value) =>
            handleChange({
              target: {
                name: USER_MANAGEMENT_FORM_KEYS.ROLE,
                value,
              },
            })
          }
          queryProps={{
            resource: '/roles',
            queryKeyProp: ['GET_ROLES'],
            select: (data: any) => data.data,
          }}
          InputProps={{
            label: <FormattedMessage {...messages.selectRole} />,
            required: true,
            name: USER_MANAGEMENT_FORM_KEYS.ROLE,
            // @ts-ignore
            error: Boolean(errors[USER_MANAGEMENT_FORM_KEYS.ROLE]),
            // @ts-ignore
            helperText: errors[USER_MANAGEMENT_FORM_KEYS.ROLE],
          }}
          getOptionLabel={(option) => option.name}
          getOptionKey={(option) => option.id}
          componentName={USER_MANAGEMENT_FORM_KEYS.ROLE}
          isOptionEqualToValue={(option, value) => option.id === value.id}
        />
      </Grid>
      <Grid item xs={6} md={6} sm={6} lg={6} xl={6}>
        <TextField
          name={USER_MANAGEMENT_FORM_KEYS.PASSWORD1}
          variant='outlined'
          label={<FormattedMessage {...messages.password} />}
          onChange={handleChange}
          // @ts-ignore
          value={values?.[USER_MANAGEMENT_FORM_KEYS.PASSWORD1]}
          fullWidth
          size='small'
          required
          type='password'
          autoComplete='new-password'
          disabled={isEditMode}
          // @ts-ignore
          error={Boolean(errors[USER_MANAGEMENT_FORM_KEYS.PASSWORD1])}
          // @ts-ignore
          helperText={errors[USER_MANAGEMENT_FORM_KEYS.PASSWORD1]}
        />
      </Grid>
      <Grid item xs={6} md={6} sm={6} lg={6} xl={6}>
        <TextField
          name={USER_MANAGEMENT_FORM_KEYS.PASSWORD2}
          variant='outlined'
          label={<FormattedMessage {...messages.reEnterPassword} />}
          onChange={handleChange}
          // @ts-ignore
          value={values?.[USER_MANAGEMENT_FORM_KEYS.PASSWORD2]}
          fullWidth
          size='small'
          required
          type='password'
          autoComplete='new-password'
          disabled={isEditMode}
          // @ts-ignore
          error={Boolean(errors[USER_MANAGEMENT_FORM_KEYS.PASSWORD2])}
          // @ts-ignore
          helperText={errors[USER_MANAGEMENT_FORM_KEYS.PASSWORD2]}
        />
      </Grid>
      {/* <Grid item xs={6} md={6} sm={6} lg={6} xl={6}>
        <FormControl sx={{ width: '100%' }} size='small' required>
          <InputLabel id='Enforce 2fa'>
            {<FormattedMessage {...messages.enforce2fa} />}
          </InputLabel>
          <Select
            name={USER_MANAGEMENT_FORM_KEYS.IS_2FA}
            variant='outlined'
            label={<FormattedMessage {...messages.enforce2fa} />}
            onChange={handleChange}
            // @ts-ignore
            value={values?.[USER_MANAGEMENT_FORM_KEYS.IS_2FA]}
            fullWidth
            size='small'
          >
            <MenuItem value='yes'>Yes</MenuItem>
            <MenuItem value='no'>No</MenuItem>
          </Select>
        </FormControl>
      </Grid> */}

      <Grid item width='100%'>
        <Stack spacing={2} pt={2} direction='row' justifyContent='flex-end'>
          <Button
            size='large'
            variant='contained'
            onClick={() => handleSubmit()}
          >
            {isEmpty(recordToUpdate) ? (
              <FormattedMessage {...messages.add} />
            ) : (
              <FormattedMessage {...messages.update} />
            )}
          </Button>
          <Button size='large' variant='outlined' onClick={onCancel}>
            <FormattedMessage {...messages.cancel} />
          </Button>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default UserManagementForm;
