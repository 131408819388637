import { get } from 'v2s-ui-core/app/Integrations/ApiService';
import { API_URLS } from './config';

const ApiServices = {
  getGenealogyList: async <T>(params?: any) => {
    try {
      const response = await get<T>(API_URLS.GET_GENEALOGY_MASTER_HISTORY, {
        params,
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  getGenealogyHistory: async <T>(id: any, params?: any) => {
    try {
      const response = await get<T>(API_URLS.GET_GENEALOGY_HISTORY(id), {
        params,
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  },
};

export default ApiServices;
