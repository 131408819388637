import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import { blue } from '@mui/material/colors';
import messages from '../messages';
import { FormattedMessage } from 'v2s-ui-core/app/Integrations/Localization';
import Grid from 'v2s-ui-core/app/components/Grid';
import TextField from 'v2s-ui-core/app/components/Input/TextField';
import Button from 'v2s-ui-core/app/components/Button';
import { CloseButton } from '~/components/Actions/CloseButton';
import { ShowStatus } from '~/components/Status/ShowStatus';
import { useApproveRejectKyc } from '../hooks/useApproveRejectKycAccount';
import { KYC_STATUS_MESSAGE } from '../contsants';

const ApproveRejectKyc = ({
  onCancel,
  recordForApproveReject,
  onConfirmApproveReject,
  onApproveRecord,
  onRejectRecord,
}: any) => {
  const {
    formik: { handleSubmit, handleChange, values, errors },
    setStatus,
  } = useApproveRejectKyc({
    onConfirmApproveReject,
    recordForApproveReject,
  });

  const handleApprove = () => {
    setStatus('approve');
    onApproveRecord(
      recordForApproveReject?.record,
      KYC_STATUS_MESSAGE.APPROVED,
    );
    handleSubmit();
  };

  const handleReject = () => {
    setStatus('reject');
    onRejectRecord(recordForApproveReject?.record, KYC_STATUS_MESSAGE.REJECTED);
    handleSubmit();
  };

  return (
    <Dialog
      open={Boolean(recordForApproveReject)}
      onClose={onCancel}
      PaperProps={{
        component: 'form',
        sx: {
          minWidth: '455px',
        },
      }}
    >
      <DialogTitle sx={{ backgroundColor: blue[50] }}>
        <FormattedMessage {...messages.kycModalTitle} />
        <CloseButton onClose={onCancel} />
      </DialogTitle>

      <DialogContent>
        <Grid container spacing={2} pt={4}>
          <Grid item xs={12} md={6}>
            <TextField
              name='document_name'
              variant='outlined'
              label={<FormattedMessage {...messages.document_name} />}
              value={recordForApproveReject?.record?.document_name || ''}
              fullWidth
              size='small'
              disabled
            />
            <TextField
              name='remark'
              variant='outlined'
              label={<FormattedMessage {...messages.addRemark} />}
              onChange={handleChange}
              value={values.remark}
              size='small'
              required
              error={Boolean(errors.remark)}
              helperText={errors.remark}
              fullWidth
              sx={{ mt: 2 }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <ShowStatus
              status={recordForApproveReject?.record?.latest_version.status.status_name.toUpperCase()}
            />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions sx={{ padding: 3 }}>
        <Button variant='contained' onClick={handleApprove}>
          <FormattedMessage {...messages.approve} />
        </Button>
        <Button variant='contained' onClick={handleReject}>
          <FormattedMessage {...messages.reject} />
        </Button>
        <Button variant='outlined' onClick={onCancel}>
          <FormattedMessage {...messages.cancel} />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ApproveRejectKyc;
