import {
  CONTENT_TYPE_PDF,
  CONTENT_TYPES_IMAGE,
  FILE_EXTENSION_PDF,
} from '~/constants/index';

export const isDocumentPdf = (
  contentType: string,
  extension: string,
): boolean =>
  [contentType, extension].includes(CONTENT_TYPE_PDF) ||
  [extension].includes(FILE_EXTENSION_PDF);

export const isDocumentImage = (contentType: string): boolean =>
  CONTENT_TYPES_IMAGE.includes(
    contentType as (typeof CONTENT_TYPES_IMAGE)[number],
  );
