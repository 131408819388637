import React from 'react';
import Skeleton from '@mui/material/Skeleton';
import { Box } from '@mui/material';

const SkeletonWrapper = ({
  isLoading,
  children,
  width = '344px',
  height = 40,
}: {
  isLoading: boolean;
  children: React.ReactNode;
  width?: number | string;
  height?: number | string;
}) => (
  <Box>
    {isLoading ? (
      <Skeleton variant='rectangular' width={width} height={height} />
    ) : (
      children
    )}
  </Box>
);

export default SkeletonWrapper;
