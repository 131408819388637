import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Button, Grid, TextField, IconButton } from '@mui/material';
import viewDoc from '~/assets/images/viewDoc.svg';
import { AccountType } from '../types';
import SkeletonWrapper from './SkeletonWrapper';
import { FormattedMessage } from '~app/Integrations/Localization';
import messages from '../messages';
import edit from '~/assets/images/edit.svg';
import { useBankInfoForm } from '../hook/useBankInfoForm';
import CloseIcon from '@mui/icons-material/Close';
import FileUpload from '~/hooks/FileUpload';

// eslint-disable-next-line complexity
const BankAccountForm = ({
  account,
  setValue,
  isLoading,
  isEdit,
  setIsEdit,
}: {
  account: AccountType;
  setValue: (boolean: boolean) => void;
  isLoading: boolean;
  isEdit: boolean;
  setIsEdit: (boolean: boolean) => void;
}) => {
  const {
    formik: {
      errors,
      values,
      setFieldValue,
      touched,
      handleBlur,
      handleChange,
      handleSubmit,
    },
  } = useBankInfoForm({
    account,
    isEdit,
    setIsEdit,
  });

  return (
    <Box>
      {account?.status === 'REJECTED' && (
        <IconButton sx={{ float: 'right' }}>
          {isEdit ? (
            <CloseIcon onClick={() => setIsEdit(!isEdit)} />
          ) : (
            <img onClick={() => setIsEdit(true)} src={edit} alt='edit' />
          )}
        </IconButton>
      )}

      <Box mt={'20px'}>
        <Grid container spacing={0} columnSpacing={4}>
          <Grid item xs={12} sm={4}>
            <SkeletonWrapper isLoading={isLoading}>
              <TextField
                size='small'
                autoComplete='off'
                label={<FormattedMessage {...messages.bank_name} />}
                margin='normal'
                name='bank_name'
                disabled={!isEdit}
                value={values.bank_name || ''}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </SkeletonWrapper>
          </Grid>
          <Grid item xs={12} sm={4}>
            <SkeletonWrapper isLoading={isLoading}>
              <TextField
                size='small'
                autoComplete='off'
                label={<FormattedMessage {...messages.bank_account_name} />}
                margin='normal'
                name='bank_account_name'
                disabled={!isEdit}
                value={values.bank_account_name || ''}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </SkeletonWrapper>
          </Grid>
        </Grid>
        <Grid
          container
          columnSpacing={4}
          spacing={0}
          mt={isLoading ? '16px' : '0px'}
        >
          <Grid item xs={12} sm={4}>
            <SkeletonWrapper isLoading={isLoading}>
              <TextField
                size='small'
                autoComplete='off'
                label={<FormattedMessage {...messages.bank_account_number} />}
                margin='normal'
                name='bank_account_number'
                disabled={!isEdit}
                value={values.bank_account_number || ''}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </SkeletonWrapper>
          </Grid>
          <Grid item xs={12} sm={4}>
            <SkeletonWrapper isLoading={isLoading}>
              <TextField
                size='small'
                autoComplete='off'
                label={<FormattedMessage {...messages.bank_iban} />}
                margin='normal'
                name='bank_iban'
                disabled={!isEdit}
                value={values.bank_iban || ''}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </SkeletonWrapper>
          </Grid>
        </Grid>
        <Grid
          container
          columnSpacing={4}
          spacing={0}
          mt={isLoading ? '16px' : '0px'}
        >
          <Grid item xs={12} sm={4}>
            <SkeletonWrapper isLoading={isLoading}>
              <TextField
                size='small'
                autoComplete='off'
                label={<FormattedMessage {...messages.bank_ifsc} />}
                margin='normal'
                name='bank_ifsc'
                disabled={!isEdit}
                value={values.bank_ifsc || ''}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </SkeletonWrapper>
          </Grid>
          <Grid item xs={12} sm={4}>
            <SkeletonWrapper isLoading={isLoading}>
              <TextField
                size='small'
                autoComplete='off'
                label={<FormattedMessage {...messages.bank_address} />}
                margin='normal'
                name='bank_address'
                disabled={!isEdit}
                value={values.bank_address || ''}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </SkeletonWrapper>
          </Grid>
        </Grid>
      </Box>
      <Box marginTop={'32px'}>
        <SkeletonWrapper isLoading={isLoading}>
          <Typography sx={{ fontWeight: 'bold', fontSize: '20px' }}>
            Documents
          </Typography>
        </SkeletonWrapper>
        <Box
          display={'flex'}
          alignItems={'center'}
          gap={'8px'}
          marginTop={'32px'}
        >
          <SkeletonWrapper isLoading={isLoading}>
            <TextField
              type='text'
              name='documents'
              size='small'
              label={<FormattedMessage {...messages.document_name} />}
              placeholder='document name'
              disabled={!isEdit}
              value={
                (account &&
                  account?.user_document?.document_name +
                    (account?.user_document?.latest_version?.extension
                      ? `${account?.user_document?.latest_version.extension}`
                      : '')) ||
                ''
              }
            />
          </SkeletonWrapper>
          {isEdit && (
            <SkeletonWrapper isLoading={isLoading}>
              {/* <Box width={'auto'}>
                <Button
                  component='label'
                  variant='outlined'
                  sx={{
                    textTransform: 'none',
                    color: '#768098',
                    fontWeight: 'bold',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: '8px',
                    height: '40px',
                  }}
                >
                  <input type='file' hidden />
                  Upload
                </Button>
              </Box> */}
              <FileUpload
                // apiUrl='https://xeone.beta.v2stech.com/api/users/upload'

                onUploadSuccess={(documentId: string) => {
                  if (documentId) {
                    setFieldValue('document_id', documentId);
                  }
                }}
                documentMappingId={
                  account?.user_document?.document_type_mapping
                }
                disabled={!isEdit}
              />
            </SkeletonWrapper>
          )}
          {!isEdit && (
            <SkeletonWrapper isLoading={isLoading}>
              <Box width={'auto'}>
                <Button
                  onClick={() => setValue(true)}
                  variant='outlined'
                  sx={{
                    textTransform: 'none',
                    color: '#768098',
                    fontWeight: 'bold',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: '8px',
                    height: '40px',
                  }}
                >
                  <img src={viewDoc} alt='viewDoc' />
                  <FormattedMessage {...messages.view} />
                </Button>
              </Box>
            </SkeletonWrapper>
          )}
        </Box>
      </Box>
      {isEdit && (
        <Button
          onClick={() => {
            handleSubmit();
          }}
          sx={{ my: '32px', width: '100px' }}
          variant='contained'
        >
          Save
        </Button>
      )}
    </Box>
  );
};

export default BankAccountForm;
