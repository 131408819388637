import { ColumnDef } from 'v2s-ui-core/app/components/DataGridCore';

export const getIbNetworkTableColumns = (props: {}): ColumnDef<unknown>[] => [
  {
    accessorKey: 'full_name',
    header: 'Client Name',
    enableColumnFilter: true,
  },
  {
    accessorKey: 'email',
    header: 'Email',
    enableColumnFilter: true,
  },
  {
    accessorKey: 'phone_number',
    header: 'Phone',
    enableColumnFilter: true,
  },
  {
    accessorKey: 'user_type.type',
    header: 'Account Type',
    enableColumnFilter: true,
  },
  {
    accessorKey: 'id',
    header: 'IB ID',
    enableColumnFilter: true,
  },
  {
    accessorKey: 'referral_user.full_name',
    header: 'IB Name',
    enableColumnFilter: true,
  },
  // {
  //   accessorKey: 'bd_name',
  //   header: 'BD Name',
  //   enableColumnFilter: true,
  // },
  {
    accessorKey: 'level',
    header: 'Level',
    enableColumnFilter: true,
  },
];
