import ApprovedExpand from '~/assets/images/ApprovedExpand.svg';
import PendingExpand from '~/assets/images/PendingExpand.svg';
import RejectedExpand from '~/assets/images/RejectedExpand.svg';
import { Status } from './types';

export const statusIcons: Record<Status, string> = {
  APPROVED: ApprovedExpand,
  PENDING: PendingExpand,
  REJECTED: RejectedExpand,
};

export const statusColors: Record<Status, string> = {
  APPROVED: '#388E3C',
  PENDING: '#1E88EC',
  REJECTED: '#C1292A',
};
