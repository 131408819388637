import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import Grid from '@mui/material/Grid';
import TextField from 'v2s-ui-core/app/components/Input/TextField';
import Button from 'v2s-ui-core/app/components/Button';
import { API_URLS, QUERY_KEYS } from '../../api/config';
import AsyncDropdown from '~app/components/AsyncDropdown';
import { useCreditForm } from '../../hooks/useCreditForm';
import { blue } from '@mui/material/colors';
import { FormattedMessage } from '~app/Integrations/Localization';
import messages from '../../messages';
import { CloseButton } from '~/components/Actions/CloseButton';
import { useIsMobile } from '~/hooks/useIsMobile';
export interface CreditModalProps {
  onCancel: () => void;
  creditRecord: any;
  onConfirmCredit: any;
}

export const CreditModal: React.FC<CreditModalProps> = ({
  onCancel,
  creditRecord,
  onConfirmCredit,
}) => {
  const {
    formik: { handleSubmit, values, handleChange, setFieldValue, errors },
  } = useCreditForm({
    onConfirmCredit,
    creditRecord,
  });

  const isMobile = useIsMobile();

  return (
    <Dialog
      open={true}
      onClose={onCancel}
      PaperProps={{
        component: 'form',
        sx: {
          width: isMobile ? '100vw' : '636px',
          margin: isMobile ? 1 : 0,
        },
      }}
    >
      <DialogTitle sx={{ backgroundColor: blue[50] }}>
        {creditRecord?.action === 'CREDIT_IN' ? 'Credit In' : 'Credit Out'}
        <CloseButton onClose={onCancel} />
      </DialogTitle>

      <DialogContent sx={{ mt: 2 }}>
        <DialogContentText>
          <FormattedMessage {...messages.modalHelperText} />{' '}
          {creditRecord?.action === 'CREDIT_IN' ? 'Credit In' : 'Credit Out'}
        </DialogContentText>
        <Grid container spacing={2} mt={'2px'} direction='column'>
          <Grid item>
            <AsyncDropdown
              InputProps={{
                label: <FormattedMessage {...messages.tradingAccount} />,
                error: Boolean(errors.trading_account_id),
                name: 'trading_account_id',
                helperText: errors.trading_account_id?.toString(),
              }}
              queryProps={{
                resource: API_URLS.FETCH_TRADERS,
                queryKeyProp: [QUERY_KEYS.FETCH_TRADERS],
                select: (data: any) => data.data,
              }}
              value={(values.trading_account_id as any) || null}
              onChange={(e, value) => {
                setFieldValue('trading_account_id', value || '');
                // setFieldValue('user_id', value?.user?.id || ''); // Update user_id
              }}
              getOptionLabel={(option) => option?.id || ''}
              getOptionKey={(option) => option.id}
            />
          </Grid>
          <Grid item>
            <TextField
              name='amount'
              type='number'
              label={<FormattedMessage {...messages.amount} />}
              value={values.amount}
              onChange={handleChange}
              fullWidth
              size='small'
              error={Boolean(errors.amount)}
              helperText={errors.amount}
            />
          </Grid>
          <Grid item>
            <TextField
              name='remark'
              label={<FormattedMessage {...messages.remark} />}
              value={values.remark}
              onChange={handleChange}
              fullWidth
              size='small'
              error={Boolean(errors.remark)}
              helperText={errors.remark}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={{ pb: 2, pr: 3 }}>
        <Button
          onClick={() => {
            handleSubmit();
          }}
          variant='contained'
        >
          <FormattedMessage {...messages.credit} />
        </Button>
        <Button variant='outlined' onClick={onCancel}>
          <FormattedMessage {...messages.cancel} />
        </Button>
      </DialogActions>
    </Dialog>
  );
};
