import { ThemeProvider } from '@mui/material';
import React from 'react';
import { theme } from './theme';

interface IGlobalThemeProvider {
  children: React.ReactNode;
}
const GlobalThemeProvider = ({ children }: IGlobalThemeProvider) => (
  <ThemeProvider theme={theme}>{children}</ThemeProvider>
);

export default GlobalThemeProvider;
