import { Route, Routes, Navigate } from 'react-router-dom';

import AsyncRenderer from 'v2s-ui-core/app/components/AsyncRenderer';

import RequiresAuth from '../../components/Auth/RequiresAuth';
import APPLICATION_URLS from '../config/appsConfig';
import { ProtectedLayout } from '../../components/Layout';
import { PROTECTED_ROUTES } from '../config/routesConfig';
import PageNotFound from './PageNotFound';

const ProtectedRoutes = () => (
  <RequiresAuth>
    <ProtectedLayout>
      <AsyncRenderer fallback={<span>Loading...</span>}>
        <Routes>
          {PROTECTED_ROUTES.map((_route) => (
            <Route
              path={_route.path}
              element={_route.element}
              key={_route.path}
            />
          ))}
          <Route
            path={APPLICATION_URLS.LANDING_PAGE}
            element={<Navigate to={APPLICATION_URLS.USER_MANAGEMENT} />}
          />
          <Route path='/login' element={<Navigate to={'/user-management'} />} />
          <Route path='*' element={<PageNotFound />} />
        </Routes>
      </AsyncRenderer>
    </ProtectedLayout>
  </RequiresAuth>
);

export default ProtectedRoutes;
