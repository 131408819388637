import { defineMessages } from '~app/Integrations/Localization';

export default defineMessages({
  credit: {
    id: 'credit.credit',
    defaultMessage: 'Credit',
  },
  creditIn: {
    id: 'credit.creditIn',
    defaultMessage: 'Credit In',
  },
  creditOut: {
    id: 'credit.creditOut',
    defaultMessage: 'Credit Out',
  },
  creditSuccess: {
    id: 'credit.creditInSuccess',
    defaultMessage: ' Amount Credited SuccessFully',
  },

  creditFailed: {
    id: 'credit.creditFailed',
    defaultMessage: 'Credit Failed',
  },
  remark: {
    id: 'credit.remark',
    defaultMessage: 'Remark',
  },
  amount: {
    id: 'credit.amount',
    defaultMessage: 'Amount',
  },
  tradingAccount: {
    id: 'credit.tradingAccount',
    defaultMessage: 'Select Trading Account',
  },
  cancel: {
    id: 'credit.cancel',
    defaultMessage: 'Cancel',
  },
  remarkRequired: {
    id: 'credit.remarkRequired',
    defaultMessage: 'Remark is required',
  },
  amountRequired: {
    id: 'credit.amountRequired',
    defaultMessage: 'Amount is required',
  },
  minimumAmount: {
    id: 'credit.minimumAmount',
    defaultMessage: 'Amount should be greater than 10$',
  },
  tradingAccountRequired: {
    id: 'credit.tradingAccountRequired',
    defaultMessage: 'Trading Account is required',
  },
  modalHelperText: {
    id: 'credit.modalHelperText',
    defaultMessage: ' Fill in the below detail to ',
  },
});
