import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';

const PageNotFound = () => {
  const navigate = useNavigate();
  const theme = useTheme();

  return (
    <Container
      fixed
      sx={{
        backgroundColor: theme.palette.background.default,
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 1,
      }}
    >
      <Box>
        <Typography variant='h1' gutterBottom>
          404
        </Typography>
        <Typography variant='h5' gutterBottom>
          Oops! The page you're looking for doesn't exist.
        </Typography>
        <Button
          variant='contained'
          onClick={() => navigate('/user-management')}
        >
          Go to Home
        </Button>
      </Box>
    </Container>
  );
};

export default PageNotFound;
