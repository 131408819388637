import React from 'react';
import { Box, Grid, Button, TextField, Typography } from '@mui/material';
import { ShowStatus } from '~/components/Status/ShowStatus';
import viewDoc from '~/assets/images/viewDoc.svg';
import { FormattedMessage } from '~app/Integrations/Localization';
import messages from '../messages';
import ViewKycDocModal from './ViewKycDoc';
import useToggle from '~app/hooks/useToggle';
import DocumentExpiry from './DocumentExpiry';
import FileUpload from '~/hooks/FileUpload';
import { useIsMobile } from '~/hooks/useIsMobile';

// eslint-disable-next-line arrow-body-style
const KycForm = ({ data, onApprovRejectRecord }: any) => {
  const { setValue, value } = useToggle();
  const isMobile = useIsMobile();

  return (
    <Box mt='32px'>
      <Grid
        rowSpacing={4}
        container
        display={'flex'}
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        {data?.data.map((document: any) => (
          <>
            <Grid key={document.id} item display='flex' alignItems='center'>
              <TextField
                label={`${document.document_name} (${document.document_side})`}
                variant='outlined'
                size='small'
                disabled
                value={
                  document.document_name + document.latest_version.extension
                }
                sx={{
                  width: isMobile ? '120px' : '220px',
                }}
              />
              <Button
                variant='outlined'
                sx={{
                  textTransform: 'none',
                  color: '#768098',
                  fontWeight: 'bold',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: '8px',
                  height: '40px',
                  mx: 1,
                }}
                onClick={() => setValue(document)}
              >
                <img src={viewDoc} alt='viewDoc' />
                {!isMobile && <FormattedMessage {...messages.view} />}
              </Button>

              {document?.latest_version.status.status_name === 'Pending' ? (
                <Button
                  onClick={() => onApprovRejectRecord(document)}
                  sx={{ height: '40px', width: '113px' }}
                  variant='contained'
                  color='primary'
                  disabled={false}
                >
                  <FormattedMessage {...messages.actions} />
                </Button>
              ) : (
                <ShowStatus
                  status={document?.latest_version.status.status_name.toUpperCase()}
                />
              )}
              {(document?.latest_version.status.status_name === 'Rejected' ||
                document?.latest_version.file) && (
                <FileUpload
                  onUploadSuccess={(documentId: string) => {
                    console.log(documentId);
                  }}
                  documentMappingId={document?.document_type_mapping}
                  userId={document?.id}
                />
              )}
            </Grid>
          </>
        ))}
      </Grid>
      <Box mt={4}>
        <Typography sx={{ fontSize: '20px', fontWeight: '500', my: 2 }}>
          Document Expiry
        </Typography>
        {data?.data.map((document: any) => (
          <DocumentExpiry key={document.id} document={document} />
        ))}
      </Box>

      <ViewKycDocModal setValue={setValue} value={value} />
    </Box>
  );
};

export default KycForm;
