import PersonIcon from '@mui/icons-material/Person';
import { ListItemIcon, Typography } from '@mui/material';
import { TreeItem, treeItemClasses } from '@mui/x-tree-view/TreeItem';
import { alpha, styled } from 'v2s-ui-core/app/styles';
import APPLICATION_URLS from '~/shell/Routing/config/appsConfig';
import AppBaseLayout, {
  AppBaseLayoutProps,
} from '~/shell/components/Layout/components/AppLayout';
import IBGenealogy from './modules/IBGenealogy';
import IBs from './modules/IBs';
import TraderIcon from '~/assets/images/TraderIcon.svg';

const SUB_ROUTES_FOR_IB_MANAGEMENT_MAP: {
  path: string;
  element: typeof AppBaseLayout;
  AppBaseLayoutProps: AppBaseLayoutProps;
}[] = [
  {
    path: APPLICATION_URLS.IBS,
    element: AppBaseLayout,
    AppBaseLayoutProps: {
      headerProps: {
        title: 'IBs',
      },
      contentProps: {
        content: <IBs />,
      },
    },
  },

  {
    path: APPLICATION_URLS.IB_GENEALOGY,
    element: AppBaseLayout,
    AppBaseLayoutProps: {
      headerProps: {
        title: 'IB Genealogy',
      },
      contentProps: {
        content: <IBGenealogy />,
      },
    },
  },
];
export { SUB_ROUTES_FOR_IB_MANAGEMENT_MAP };

const CustomTreeItem = styled(TreeItem)(({ theme }) => ({
  [`& .${treeItemClasses.content}`]: {
    padding: theme.spacing(0.5, 1),
    margin: theme.spacing(0.2, 0),
  },
  [`& .${treeItemClasses.iconContainer}`]: {
    '& .close': {
      opacity: 0.3,
    },
  },
  [`& .${treeItemClasses.groupTransition}`]: {
    marginLeft: 15,
    paddingLeft: 18,
    borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`,
  },
}));
function EndIcon(props: any) {
  return (
    <ListItemIcon
      sx={{
        minWidth: 0,
        pr: 1,
      }}
    >
      {/* <PersonIcon /> */}
      <img src={TraderIcon} alt='trader-icon' />
    </ListItemIcon>
  );
}

export const renderTreeItems = (node?: any) => (
  <CustomTreeItem
    key={node?.id}
    itemId={node?.id?.toString()}
    label={
      <TreeLabelView
        firstName={node?.first_name}
        lastName={node?.last_name}
        userType={node?.user_type}
        phone_number={node?.phone_number}
        email={node?.email}
        level={node?.level}
      />
    }
  >
    {node?.direct_trader?.length > 0 &&
      node.direct_trader?.map((subNode: any) =>
        renderTradingTreeItems(subNode),
      )}

    {node?.sub_ibs?.length > 0 &&
      node.sub_ibs?.map((subNode: any) => renderTreeItems(subNode))}
  </CustomTreeItem>
);

const renderTradingTreeItems = (node: any) => {
  if (!node) return null;

  return (
    <CustomTreeItem
      key={node?.id}
      itemId={node?.id?.toString()}
      label={
        <TreeLabelView
          firstName={node?.first_name}
          lastName={node?.last_name}
          userType={node?.user_type}
          phone_number={node?.phone_number}
          email={node?.email}
          level={node?.level}
          isTradingIcon
        />
      }
    >
      {node?.direct_trader?.map((subNode: any) => (
        <CustomTreeItem
          key={subNode?.id}
          itemId={subNode?.id?.toString()}
          label={
            <TreeLabelView
              firstName={subNode?.first_name}
              lastName={subNode?.last_name}
              userType={subNode?.user_type}
              phone_number={subNode?.phone_number}
              email={subNode?.email}
              level={subNode?.level}
              isTradingIcon
            />
          }
        />
      ))}
    </CustomTreeItem>
  );
};

const TreeLabelView = (props: any) => (
  <Typography display={'flex'} alignItems={'center'}>
    {props?.isTradingIcon && <EndIcon data={props} />}
    {`${props?.firstName} ${props?.lastName},${props?.email},${props?.phone_number}, Account Type:${props?.userType}, Level:${props?.level}`}
  </Typography>
);
