import {
  Dialog,
  DialogTitle,
  Typography,
  DialogContent,
  DialogContentText,
  Grid,
  TextField,
  Button,
  Box,
} from '@mui/material';
import { blue } from '@mui/material/colors';
import { FormattedMessage } from '~app/Integrations/Localization';
import messages from '../messages';
import { CloseButton } from '~/components/Actions/CloseButton';

const ViewKycDocModal = ({
  setValue,
  value,
}: {
  setValue: (boolean: boolean) => void;
  value: any;
}) => (
  <Dialog
    open={value}
    PaperProps={{
      component: 'form',
      sx: {
        minWidth: '698px',
      },
    }}
  >
    <DialogTitle
      sx={{
        backgroundColor: blue[50],
        display: 'flex',

        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <Typography>
        <FormattedMessage {...messages.kycDetails} />
      </Typography>

      <CloseButton onClose={() => setValue(false)} />
    </DialogTitle>
    <DialogContent sx={{ marginTop: '16px' }}>
      <DialogContentText
        sx={{ borderBottom: '1px solid #e0e0e0', paddingBottom: '5px' }}
      >
        <Typography>
          <FormattedMessage {...messages.documentDetails} />
        </Typography>
      </DialogContentText>
      <Grid container spacing={2} mt={'16px'}>
        <Grid item xs={12} sm={6}>
          <TextField
            sx={{
              width: '292px',
            }}
            size='small'
            label={<FormattedMessage {...messages.document_name} />}
            disabled
            value={value?.document_name || ''}
          />
        </Grid>

        <Grid item xs={2} sm={2}>
          <img
            alt='document'
            width={'310px'}
            height={'160px'}
            src={(value && value?.latest_version?.original_doc_location) || ''}
          />
        </Grid>
      </Grid>
      <Box display={'flex'} justifyContent={'flex-end'} mt={'24px'}>
        <Button
          onClick={() => setValue(false)}
          sx={{
            width: '100px',
          }}
          variant='outlined'
        >
          <FormattedMessage {...messages.close} />
        </Button>
      </Box>
    </DialogContent>
  </Dialog>
);

export default ViewKycDocModal;
