import ProfileTabs from './modules/Profile/ProfileTabs';
import BankAccounts from './modules/BankAccount/index';
import KycDocs from './modules/Kyc';
import TradingAccount from './modules/TradingAccount';
import messages from './messages';
import Wallet from './modules/Wallet/index';
import TransactionTabs from './modules/Transactions/TransactionTabs';

const QUERY_KEYS = {
  FETCH_USER_PROFILE: 'fetchUserProfile',
  FETCH_BANK_ACCOUNT_INFOS: 'fetchBankAccountInfos',
  FETCH_KYC: 'kycDocuments',
  FETCH_TRADING_ACCOUNTS: 'fetchTradingAccounts',
  FETCH_WALLET: 'fetchWalletDetails',
  FETCH_DEPOSIT: 'fetchUserDeposits',
  FETCH_WITHDRAWAL: 'fetchUserWithdrawals',
};

export const USER_PROFILE_TABS = [
  {
    profile: 1,
    label: messages.userProfile,
    component: <ProfileTabs />,
  },
  {
    bank_Account: 2,
    label: messages.bankAccount,
    component: <BankAccounts />,
  },
  {
    Kyc_Document: 3,
    label: messages.kycDocument,
    component: <KycDocs />,
  },
  {
    Trading_Accounts: 4,
    label: messages.tradingAccounts,
    component: <TradingAccount />,
  },
  {
    Wallets: 5,
    label: messages.wallets,
    component: <Wallet />,
    key: QUERY_KEYS.FETCH_WALLET,
  },
  {
    Transactions: 6,
    label: messages.transactions,
    component: <TransactionTabs />,
  },
];

export const TABS_CONFIG = {
  initialPosition: 0,
};

export const MODES = [
  {
    id: 5,
    name: 'APPROVE',
  },
  {
    id: 3,
    name: 'CREATE',
  },
  {
    id: 4,
    name: 'DELETE',
  },
  {
    id: 2,
    name: 'EDIT',
  },
  {
    id: 8,
    name: 'EXPORT',
  },
  {
    id: 6,
    name: 'REJECT',
  },
  {
    id: 7,
    name: 'UPLOAD',
  },
  {
    id: 1,
    name: 'VIEW',
  },
];
