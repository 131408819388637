import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import { styled } from '@mui/material';

export const StyledAppBar = styled(AppBar)`
  .appbar-logo {
    width: 96px;
    height: 23px;
  }
`;
