import { ColumnDef } from 'v2s-ui-core/app/components/DataGridCore';
import ActionButtons from '~/components/Actions/ActionButtons';
import { ACCOUNT_STATUS, ACCOUNT_STATUS_CONFIG } from '../../constants';
import { AccountStatus } from '../../types';
export const LeverageChangeTableColumns = (props: {
  onApproveRecord?: Function;
  onRejectRecord?: Function;
}): ColumnDef<AccountStatus>[] => [
  {
    cell: ({ row }) => row?.original?.trading_account?.login,
    header: 'Trading Account',
    enableColumnFilter: false,
  },
  {
    cell: ({ row }) => row?.original?.trading_account?.user?.full_name,
    header: 'Trader Name',
    enableColumnFilter: false,
  },
  {
    accessorFn: (row) => row.trading_account?.leverage,
    header: 'Leverage',
    enableColumnFilter: false,
  },
  {
    accessorKey: 'status',
    header: 'Status',
    enableColumnFilter: false,
  },
  {
    accessorKey: 'actions',
    header: 'Actions',
    enableColumnFilter: false,

    cell: ({ cell }) => {
      const { row } = cell;
      const status = row.original.status;

      return (
        <ActionButtons
          status={status}
          onApproveRecord={props?.onApproveRecord}
          onRejectRecord={props?.onRejectRecord}
          rowData={row.original}
          ACCOUNT_STATUS={ACCOUNT_STATUS}
          ACCOUNT_STATUS_CONFIG={ACCOUNT_STATUS_CONFIG}
        />
      );
    },
  },
];
