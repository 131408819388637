import { useMemo } from 'react';
import { QueryDetails } from './types';
import { QUERY_KEYS } from './constants';
import ApiServices from '../../api';

export const useQueryFactory = <TData, TError = Error>() => {
  const queryDetails = useMemo<QueryDetails<TData, TError>>(
    () => ({
      [QUERY_KEYS.FETCH_DASHBOARD]: () => ({
        queryKey: [QUERY_KEYS.FETCH_DASHBOARD],
        queryFn: () => ApiServices.getSummary<TData>(),
      }),
      // GX_INJECT_QUERY_CONFIG
    }),
    [],
  );
  return queryDetails;
};
