import { FC, useMemo } from 'react';
import DataGridWithQuery from 'v2s-ui-core/app/components/DataGridWithQuery';
import { ColumnDef } from 'v2s-ui-core/app/components/DataGridCore';
import { WithdrawalTableColumns } from '~/modules/Withdrawal/components/WithdrawalTable/WithdrawalTableColumns';
import { useApproveRejectActions } from 'src/hooks/ApproveRejectCustomHook/useApproveRejectActions';
import ApproveRejectWithdrawal from '~/modules/Withdrawal/components/WithdrawalTable/ApproveRejectWithdrawal';
import { WITHDRAWALS_STATUS } from '~/modules/Withdrawal/constants';
import messages from '~/modules/Withdrawal/messages';
import { useLocation } from 'react-router-dom';

const WithdrawalTable: FC<{}> = () => {
  const location = useLocation();
  const {
    recordForApproveReject,
    onApproveRecord,
    onRejectRecord,
    onCancel,
    onConfirmApproveReject,
  } = useApproveRejectActions({
    QUERY_KEYS: 'fetchUserWithdrawals',
    API_URLS: (user: string | number, id: string | number) =>
      `crm/user/${user}/transactions/${id}`,
    STATUS: WITHDRAWALS_STATUS,
    user: true,
    messages: messages,
  });

  const memoizedColumns = useMemo(
    () =>
      WithdrawalTableColumns({
        onApproveRecord,
        onRejectRecord,
      }),
    [onApproveRecord, onRejectRecord],
  );

  return (
    <>
      <DataGridWithQuery
        queryProps={{
          resource: `crm/users/${location.state}/transactions`,
          queryKeyProp: ['fetchUserWithdrawals'],
          select: (data: any) => data.data,
          refetchInterval: 5000,
        }}
        filters={{ transaction_type: 'WITHDRAWAL' }}
        columns={memoizedColumns as ColumnDef<unknown>[]}
      />
      {recordForApproveReject && (
        <ApproveRejectWithdrawal
          onCancel={onCancel}
          recordForApproveReject={recordForApproveReject}
          onConfirmApproveReject={onConfirmApproveReject}
        />
      )}
    </>
  );
};

export default WithdrawalTable;
