import { useState } from 'react';
import { useShowToastNotifications } from 'v2s-ui-core/app/containers/ToastNotifications/Provider';
import { useLocaleContext } from 'v2s-ui-core/app/Integrations/Localization';
import {
  useMutation,
  useQueryClient,
} from 'v2s-ui-core/app/Integrations/react-query';
import { patch } from 'v2s-ui-core/app/Integrations/ApiService';

// Interfaces
interface ApproveRejectActionProps {
  QUERY_KEYS: string;
  API_URLS: (user?: string | number, id?: string | number) => string;
  STATUS: Record<string, string>;
  user?: boolean;
  messages?: any;
}

interface Payload {
  [key: string]: any;
}

// Custom Hook
// eslint-disable-next-line max-statements
export const useCreditActions = ({
  QUERY_KEYS,
  API_URLS,
  STATUS,
  user,
  messages,
}: ApproveRejectActionProps) => {
  // States
  const [creditRecord, setCreditRecord] = useState(null);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');

  // Hooks
  const { showToastNotification } = useShowToastNotifications();
  const {
    messageFormatter: { formatMessage },
  } = useLocaleContext();
  const queryClient = useQueryClient();

  // Functions
  const approveReject = async <T>(payload: Payload) => {
    const url = API_URLS(payload.user_id, payload.trading_account_id);
    try {
      const response = await patch<T>(url, payload);
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  const mutationOptions = {
    mutationFn: approveReject,
  };

  const { mutate } = useMutation(mutationOptions);

  const onCreditIn = (credit_type: string) => {
    setCreditRecord({ credit_type, action: STATUS?.CREDIT_IN });
  };

  const onCreditOut = (credit_type: string) => {
    setCreditRecord({ credit_type, action: STATUS.CREDIT_OUT });
  };

  const onCancel = () => {
    setCreditRecord(null);
  };

  const onSuccess = () => {
    setSuccessMessage(formatMessage(messages.creditSuccess));
    setShowSuccessModal(true);
    queryClient.invalidateQueries({ queryKey: [QUERY_KEYS] });
    onCancel();
  };

  const onError = (error: any) => {
    showToastNotification(
      `${formatMessage(messages.creditFailed)}:  ${
        error?.data.errors[0].detail
      }`,
      'error',
    );
  };

  const onConfirmCredit = (payload: Payload) => {
    mutate(payload, { onSuccess, onError });
  };

  const onSuccessClose = () => {
    setShowSuccessModal(false);
    setSuccessMessage('');
  };

  // Returns
  return {
    creditRecord,
    onCreditIn,
    onCreditOut,
    onCancel,
    onConfirmCredit,
    onSuccessClose,
    successMessage,
    showSuccessModal,
  };
};
