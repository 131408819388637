import React, { useMemo } from 'react';
import WalletDetails from './components/WalletDetails';
import { WalletHistoryColumn } from './components/WalletHistoryColumn';
import DataGridWithQuery from 'v2s-ui-core/app/components/DataGridWithQuery';
import { Box, Typography } from '@mui/material';
import { API_URLS } from './api/config';
import { ColumnDef } from '~app/components/DataGridCore';
import { useLocation } from 'react-router-dom';
import { FormattedMessage } from 'v2s-ui-core/app/Integrations/Localization';
import messages from './messages';
const Wallet = () => {
  const location = useLocation();
  const memoizedColumns = useMemo(() => WalletHistoryColumn(), []);

  return (
    <>
      <WalletDetails />
      <Box mt={'64px'}>
        <Typography fontSize={'20px'} pb={'54px'}>
          <FormattedMessage {...messages.walletHistory} />
        </Typography>
        <DataGridWithQuery
          queryProps={{
            resource: API_URLS.FETCH_WALLET_DETAILS(location.state),
            select: (data: any) => data.data?.transaction_histories,
            queryKeyProp: ['fetchWalletDetails'],
            refetchInterval: 5000,
          }}
          columns={memoizedColumns as ColumnDef<unknown>[]}
        />
      </Box>
    </>
  );
};

export default Wallet;
