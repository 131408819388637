import { defineMessages } from 'v2s-ui-core/app/Integrations/Localization';

export default defineMessages({
  withdrawal: {
    id: 'withdrawal',
    defaultMessage: 'Withdrawal',
  },
  approveModalTitle: {
    id: 'withdrawal.approveModalTitle',
    defaultMessage: 'Approve Withdrawal?',
  },
  rejectModalTitle: {
    id: 'withdrawal.rejectModalTitle',
    defaultMessage: 'Reject Withdrawal?',
  },
  approveModalHelpText: {
    id: 'withdrawal.approveModalHelpText',
    defaultMessage: 'Please provide reasoning for approving the withdrawal.*',
  },
  rejectModalHelpText: {
    id: 'withdrawal.rejectModalHelpText',
    defaultMessage: 'Please provide reasoning for rejecting the withdrawal.*',
  },
  approved: {
    id: 'withdrawal.approved',
    defaultMessage: 'Approved',
  },
  rejected: {
    id: 'withdrawal.rejected',
    defaultMessage: 'Rejected',
  },
  pending: {
    id: 'withdrawal.pending',
    defaultMessage: 'Pending',
  },
  addRemark: {
    id: 'withdrawal.addRemark',
    defaultMessage: 'Add Remark',
  },
  approve: {
    id: 'withdrawal.approve',
    defaultMessage: 'Approve',
  },
  reject: {
    id: 'withdrawal.reject',
    defaultMessage: 'Reject',
  },
  cancel: {
    id: 'withdrawal.cancel',
    defaultMessage: 'Cancel',
  },
  remarkRequired: {
    id: 'withdrawal.remarkRequired',
    defaultMessage: 'Remark is required',
  },
  approveSuccessMessage: {
    id: 'withdrawal.approveSuccessMessage',
    defaultMessage: 'Withdrawal approved successfully!',
  },
  rejectSuccessMessage: {
    id: 'withdrawal.rejectSuccessMessage',
    defaultMessage: 'Withdrawal rejected successfully!',
  },
  approveFailedMessage: {
    id: 'withdrawal.approveFailedMessage',
    defaultMessage: 'Withdrawal approval failed!',
  },
  rejectFailedMessage: {
    id: 'withdrawal.rejectFailedMessage',
    defaultMessage: 'Withdrawal rejection failed!',
  },
  paymentMethodRequired: {
    id: 'withdrawal.paymentMethodRequired',
    defaultMessage: 'Payment method is required',
  },
  choosePaymentMethod: {
    id: 'withdrawal.choosePaymentMethod',
    defaultMessage: 'Choose payment method',
  },
});
