import DepositsTable from './components/DepositsTable';
import AppBaseLayout from '~/shell/components/Layout/components/AppLayout';

const Deposits = () => (
  <AppBaseLayout
    contentProps={{
      content: <DepositsTable />,
    }}
    headerProps={{
      title: 'Deposits',
      actionButtons: <></>,
    }}
  />
);

export default Deposits;
