// Hooks
import { useState } from 'react';
import { useShowToastNotifications } from 'v2s-ui-core/app/containers/ToastNotifications/Provider';
import { useLocaleContext } from 'v2s-ui-core/app/Integrations/Localization';
import {
  useMutation,
  useQueryClient,
} from 'v2s-ui-core/app/Integrations/react-query';
import { post } from 'v2s-ui-core/app/Integrations/ApiService';

// Interfaces
interface ApproveRejectActionProps {
  QUERY_KEYS: string;
  API_URLS: (
    user?: string | number,
    id?: string | number,
    status?: string,
  ) => string;
  STATUS?: Record<string, string>;
  user?: boolean;
  messages: any;
}

interface Payload {
  [key: string]: any;
}

// eslint-disable-next-line max-statements
export const useKycApproveRejectActions = ({
  QUERY_KEYS,
  API_URLS,
  STATUS,
  user,
  messages,
}: ApproveRejectActionProps) => {
  // States
  const [recordForApproveReject, setRecordForApproveReject] = useState(null);

  // Hooks
  const { showToastNotification } = useShowToastNotifications();
  const {
    messageFormatter: { formatMessage },
  } = useLocaleContext();
  const queryClient = useQueryClient();

  // Functions
  const approveReject = async <T>(payload: Payload) => {
    const url = API_URLS(payload.id, payload.status);
    try {
      const response = await post<T>(url, payload);
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  const mutationOptions = {
    mutationFn: approveReject,
  };

  const { mutate } = useMutation(mutationOptions);

  const onApprovRejectRecord = (record: object) => {
    setRecordForApproveReject({ record });
  };

  const onApproveRecord = (record: object) => {
    setRecordForApproveReject({ record, action: STATUS.APPROVED });
  };

  const onRejectRecord = (record: object) => {
    setRecordForApproveReject({ record, action: STATUS.REJECTED });
  };

  const onCancel = () => {
    setRecordForApproveReject(null);
  };

  const onSuccess = () => {
    const message =
      recordForApproveReject.action === STATUS.APPROVED
        ? messages.approveSuccessMessage
        : messages.rejectSuccessMessage;
    showToastNotification(formatMessage(message), 'success');
    queryClient.invalidateQueries({ queryKey: [QUERY_KEYS] });
    onCancel();
  };

  const onError = () => {
    const message =
      recordForApproveReject.action === STATUS.APPROVED
        ? messages.approveFailedMessage
        : messages.rejectFailedMessage;
    showToastNotification(formatMessage(message), 'error');
  };

  const onConfirmApproveReject = (payload: Payload) => {
    mutate(payload, { onSuccess, onError });
  };

  // Returns
  return {
    recordForApproveReject,
    onApproveRecord,
    onRejectRecord,
    onCancel,
    onConfirmApproveReject,
    onSuccess,
    onApprovRejectRecord,
  };
};
