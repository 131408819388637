import { BrowserRouter } from 'react-router-dom';

import ErrorBoundary from 'v2s-ui-core/app/containers/ErrorBoundary';

import GlobalProviders from './GlobalProviders';

import ToastNotificationsProvider from 'v2s-ui-core/app/containers/ToastNotifications/Provider';

const EntryPoint = ({ children }: React.PropsWithChildren) => (
  <BrowserRouter>
    <GlobalProviders>
      <ToastNotificationsProvider autoHideDuration={5000}>
        <ErrorBoundary>{children}</ErrorBoundary>
      </ToastNotificationsProvider>
    </GlobalProviders>
  </BrowserRouter>
);
export default EntryPoint;
