import PersonalInformation from './PersonalInformation';
import FinancialInformation from './FinancialInformation';

import messages from './messages';

export const PROFILE_TABS = [
  {
    id: 1,
    label: messages.Personal_Information,
    component: <PersonalInformation />,
  },
  {
    id: 2,
    label: messages.Financial_Information,
    component: <FinancialInformation />,
  },
];

export const TABS_CONFIG = {
  initialPosition: 0,
};
