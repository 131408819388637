export const API_URLS = {
  FETCH_IBS: '/ib',
  // GX_INJECT_API_URL
  GET_GENEALOGY_HISTORY: (id: number) => `/get-ib-network/${id}`,
  GET_GENEALOGY_MASTER_HISTORY: `/ib?referral=0`,
};

export const QUERY_KEYS = {
  FETCH_IBS: 'fetchIBs',
  // GX_INJECT_QUERY_KEY
  FETCH_GENEALOGY_HISTORY: 'fetchGenealogyHistory',
};
