/* eslint-disable arrow-body-style */
import React from 'react';
import { Box, Typography } from '@mui/material';
import { useFetchData } from '~/hooks/fetchData/useFetchData';
import ApproveRejectKyc from './components/ApproveRejectModal';
import { useKycApproveRejectActions } from './hooks/useApproveRejectActions';
// import messages from './messages';
import { KYC_STATUS_MESSAGE } from './contsants';
import { useLocation } from 'react-router-dom';
import { API_URLS, QUERY_KEYS } from './api/config';
import KycForm from './components/KycForm';
import { FormattedMessage } from '~app/Integrations/Localization';
import messages from '../Kyc/messages';
import { KycDoc } from './types';
import CircularProgress from '@mui/material/CircularProgress';
import SuccessModal from '~/components/SuccessModal';
import { useIsMobile } from '~/hooks/useIsMobile';
const KycDocs = () => {
  const location = useLocation();
  const { data, isLoading } = useFetchData<KycDoc>({
    url: `/users/${location.state}/documents`,
    queryKey: `${QUERY_KEYS.FETCH_KYC}-${location.state}`,
  });

  const {
    recordForApproveReject,
    onApproveRecord,
    onApprovRejectRecord,
    onRejectRecord,
    onCancel,
    onConfirmApproveReject,
    onSuccess,
    showSuccessModal,
    successMessage,
    onSuccessClose,
  } = useKycApproveRejectActions({
    QUERY_KEYS: `${QUERY_KEYS.FETCH_KYC}-${location.state}`,
    API_URLS: API_URLS.APPROVE_REJECT_KYC,
    STATUS: KYC_STATUS_MESSAGE,
    user: false,
    messages: messages,
  });

  const isMobile = useIsMobile();

  if (isLoading) {
    return <CircularProgress size='3rem' />;
  }
  if (data?.data.length === 0) {
    return (
      <Typography sx={{ fontSize: '24px', fontWeight: '400' }}>
        No documents found
      </Typography>
    );
  }

  return (
    <Box mx={{ xs: '0px', sm: '20px' }}>
      <Typography sx={{ fontSize: '24px', fontWeight: '400' }}>
        <FormattedMessage {...messages.kycModalTitle} />
      </Typography>
      <KycForm data={data} onApprovRejectRecord={onApprovRejectRecord} />
      <ApproveRejectKyc
        recordForApproveReject={recordForApproveReject}
        onApproveRecord={onApproveRecord}
        onRejectRecord={onRejectRecord}
        onCancel={onCancel}
        onConfirmApproveReject={onConfirmApproveReject}
        onSuccess={onSuccess}
        onApprovRejectRecord={onApprovRejectRecord}
      />
      <SuccessModal
        open={showSuccessModal}
        onClose={onSuccessClose}
        message={successMessage}
      />
    </Box>
  );
};

export default KycDocs;
